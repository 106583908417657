import styles from "./Select.module.scss";
import Form from "react-bootstrap/Form";
import cn from "classnames";
import { useMemo } from "react";

/* 
Компонент позволяющий выбрать ответ из выпадающего списка вариантов

В данном компоненте реализован рендеринг по условию, который возвращает нужный select в зависимости от условий.

1. Если передано значение isFormSelect = true, тогда возвращается селект подключенный к библиотке react-hook-form которая позволяет легко управлять выводом ошибок и отправкой данных на сервер

2. Если isFormSelect = false выводится обычный select, не подключенный к react-hook-form

Пропсы:

className - передача дополнительных стилей из компонента где вызывается селект (строка)

children - сюда можно передавать теги options для обычного селекта

variant - выбор определенной стилизации инпута (строка)

size - выбор определенного, наиболее часто используемого размера поля (строка)

placeholder - передача плейсхолдера в инпут (строка)

isFormSelect - отрендерить поле, подключенное к react-hook-form (boolean)

register - объект, для регистрации поля в react-hook-form, значение для передачи можно взять из хука useForm(), который предоставляет библиотека,

placeholder - передача плейсхолдера в инпут (строка)

value - перменная для хранения значения селекта (обычный селект) (строка)

setValue - функция для управления значением селекта, помогает сделать обычный селект управляемым

name - имя под которым поле регистрируется в react-hook-form (строка)

options - массив строк, которые подставляются в options для селекта в react-hook-form, например ['html', 'css', 'js']

errors - объект с ошибками, предоставляемый библиоткекой react-hook-form (можно взять там же где и register). Если объект содержит ключ, совпадающий по названию с name, то для инпута с этим name выводится ошибка

labelClassName - передача дополнительных стилей для названия селекта из компонента где вызывается селект (строка)

...props - позволяет использовать остальные атрибуты, доступные для селекта

*/

export const Select = ({
	className,
	children,
	variant,
	size,
	placeholder,
	value,
	setValue,
	register,
	name,
	options,
	errors,
	label,
	isFormSelect = false,
  labelClassName,
	...props
}) => {
	const selectVariantRender = useMemo(() => {
		if (!isFormSelect) {
			return (
				<Form.Select
					value={value}
					onChange={(e) => setValue(e.target.value)}
					className={cn(styles.select, className, {
						[styles.green]: variant === "green",
						[styles.bordered]: variant === "bordered",
						[styles.transparent]: variant === "transparent",
						[styles.borderedGreen]: variant === "bordered-green",
						[styles.whiteBg]: variant === "white-bg",
						[styles.short]: size === "short",
						[styles.full]: size === "full",
					})}
				>
					{placeholder && (
						<option
							disabled
							selected="true"
							value=""
							className={styles.disabled}
						>
							{placeholder}
						</option>
					)}
					{children}
				</Form.Select>
			);
		} else {
			return (
				<label className={cn(styles.selectWrapper, labelClassName)}>
					{label}
					<div className={className}>
						<select
							{...register(name)}
							{...props}
							className={cn(styles.select, {
								[styles.green]: variant === "green",
								[styles.bordered]: variant === "bordered",
								[styles.transparent]: variant === "transparent",
								[styles.borderedGreen]:
									variant === "bordered-green",
								[styles.whiteBg]: variant === "white-bg",
								[styles.short]: size === "short",
								[styles.full]: size === "full",
							})}
						>
							{placeholder && (
								<option
									selected={true}
									value=""
									className={styles.disabled}
								>
									{placeholder}
								</option>
							)}

							{options?.map((item, index) => (
								<option
									key={item?.value || item?.node?.id || index}
									value={item?.value || item?.node?.title || item?.consumerUuid || String(item)}
								>
									{item?.title || item?.node?.title || item?.connector || String(item)}
								</option>
							))}
						</select>
					</div>
					{errors && errors[name] && (
						<p className={styles.error}>{errors[name].message}</p>
					)}
				</label>
			);
		}
	}, [
		isFormSelect,
		children,
		errors,
		label,
		name,
		options,
		placeholder,
		value,
		variant,
		size,
		className,
		props,
		register,
		setValue,
	]);

	return <>{selectVariantRender}</>;
};
