import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./GlobalSearch.module.scss";
import { Input } from "../../Input";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_REPRESENTATIVE } from "../../../services/queries/queries/companies/searchRepresentative";
import { useDebouncedCallback } from "use-debounce";
import { SearchVariantsList } from "../../Lists";
import { ALL_COMPANIES } from "../../../services/queries/queries/companies/allCompaniesList";
export const GlobalSearch = ({
  placeholder,
  isCompanySearch = false,
  targetArr,
  setTargetArr,
  setRepresentativesInfo
}) => {
  const [query, setQuery] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchRepresentative, { data }] = useLazyQuery(SEARCH_REPRESENTATIVE, {
    fetchPolicy: "no-cache",
  });
  const [searchRepresentativeCompany, { data: companyData }] = useLazyQuery(
    ALL_COMPANIES,
    {
      fetchPolicy: "no-cache",
    }
  );

  const debounce = useDebouncedCallback((query) => {
    if (query && !isCompanySearch) {
      searchRepresentative({
        variables: {
          search: query,
        },
      });
    }

    if (query && isCompanySearch) {
      searchRepresentativeCompany({
        variables: {
          search: query,
        },
      });
    }
  }, 500);

  const handleClick = useCallback(() => {
    if(setRepresentativesInfo){
      setRepresentativesInfo(companyData.allLegalPersons.edges)
    }
  }, [companyData, setRepresentativesInfo])

  const searchVariantListRender = useMemo(() => {
    if (isCompanySearch) {
      return (
        <SearchVariantsList
          companyData={searchData}
          results={targetArr}
          setSearchResults={setTargetArr}
          setQuery={setQuery}
          handleClick={handleClick}
        />
      );
    } else {
      return (
        <SearchVariantsList
          physicalData={searchData}
          results={targetArr}
          setSearchResults={setTargetArr}
          setQuery={setQuery}
        />
      );
    }
  }, [isCompanySearch, searchData, targetArr, setTargetArr, handleClick]);

  useEffect(() => {
    debounce(query);
  }, [query, debounce]);

  useEffect(() => {
    if (data && data.allPhysicalPersons && data.allPhysicalPersons.edges) {
      setSearchData(data.allPhysicalPersons.edges);
      
    }

    if (
      companyData &&
      companyData.allLegalPersons &&
      companyData.allLegalPersons.edges
    ) {
      setSearchData(companyData.allLegalPersons.edges);

      // if(setRepresentativesInfo){
      //   setRepresentativesInfo(companyData.allLegalPersons.edges)
      // }
    }
  }, [data, companyData, setRepresentativesInfo]);

  return (
    <div className={styles.searchWrapper}>
      <Input
        variant="white-bg"
        placeholder={placeholder}
        className={styles.globalSearch}
        value={query}
        setValue={setQuery}
        type="search"
      />

      {searchData && searchData.length > 0 && query.trim() !== "" && (
        <>{searchVariantListRender}</>
      )}
    </div>
  );
};
