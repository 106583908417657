import React, { useEffect, useMemo, useState } from "react";
import styles from "../CreateUserForm/CreateUserForm.module.scss";
import { Button } from "../../../Button";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  CONFIRMED_STATE_QUERY,
  CREATE_SIGNATURE,
  DECODE_BASE64_MUTATION,
  GET_TARIFFS,
  REQUIRED_DOCUMENTS_QUERY,
  SIGNATURE_STATUS_QUERY,
  UPLOAD_DOCUMENT_MUTATION,
} from "../../../../services/queries/mutations/eds/signature";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Select } from "../../../Select";
import { Loader } from "./UI/Loader";
import { FileField } from "../../../FileField/";

/* 

Компонент формы подписей (MyDss и IDPoint)

Пропсы: 

formStep - Этап для IDPoint

tabIndex - Переключатель IDP или MyDss

setTabIndex - Смена переключателя IDP или MyDss

SetFormStep - Переход этапа для IDP

id - Айди юзера

item - Полный список элементов юзера

*/
const SignatureForm = ({
  formStep,
  tabIndex,
  setTabIndex,
  setFormStep,
  id,
  item,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({
    mode: "onChange",
    defaultValues: item,
  });
  const [formStep1, setFormStep1] = useState(0);
  const [tariffType, setTariffType] = useState();
  const [optionsMap, setOptionsMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [tariffUUID, setTariffUUID] = useState(null);
  const [tariffDay, setTariffDay] = useState(365);
  const [error, setErrorDocument] = useState(null);
  const [idPointResUuid, setIdPointResUuid] = useState("");
  const [myDssResUuid, setMyDssResUuid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [createdAtMyDss, setCreatedAtMyDss] = useState(null);
  const [deletedAtMyDss, setDeletedAtMyDss] = useState(null);

  // получение uuid для mydss или idpoint
  useEffect(() => {
    if (item?.node?.signatures) {
      const idPointSignature = item.node.signatures.find(
        (signature) => signature.connector === "ID_POINT"
      );
      setMyDssResUuid(item.node.signatureRelease?.consumerUuid);

      if (idPointSignature) {
        setIdPointResUuid(idPointSignature.consumerUuid);
      }
    }
  }, [item]);

  useEffect(() => {
    item?.node?.signaturesCurrent[0]?.createdAt && setCreatedAtMyDss(item?.node?.signaturesCurrent[0]?.createdAt);
    item?.node?.signaturesCurrent[0]?.expireDate && setDeletedAtMyDss(item?.node?.signaturesCurrent[0]?.expireDate);
  }, [item])

  const {
    data: tariffsData,
    loading: tariffsLoading,
    error: errorLoading,
  } = useQuery(GET_TARIFFS);

  // Проверка загружен ли паспорт для перехода этапа
  const checkPassportFileSize = (documents) => {
    const passportDocument = documents.find(
      (doc) => doc.documentName === "Паспорт"
    );
    if (!passportDocument) {
      return false;
    }
    const fileSize = passportDocument.files[0].fileSize;
    return fileSize > 1;
  };

  const [createSignature] = useMutation(CREATE_SIGNATURE);
  const [createDocumentFile] = useMutation(DECODE_BASE64_MUTATION);

  const [getSignatureStatus] = useLazyQuery(SIGNATURE_STATUS_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [uploadDocument] = useMutation(UPLOAD_DOCUMENT_MUTATION);
  const [getConfirmedState] = useLazyQuery(CONFIRMED_STATE_QUERY, {
    fetchPolicy: "no-cache",
  });

  const [getRequiredDocument] = useLazyQuery(REQUIRED_DOCUMENTS_QUERY, {
    fetchPolicy: "no-cache",
    skip: !idPointResUuid,
  });

  const options = tariffsData?.tariffs?.map((data) => data.name);

  const updateStatus = async () => {
    try {
      if (idPointResUuid) {
        const resIdPoint = await getSignatureStatus({
          variables: { consumerUuid: idPointResUuid },
        });

        if (resIdPoint.data?.signatureStatus?.state === "WAIT-PKCS10") {
          setFormStep(1);
        } else if (
          resIdPoint.data?.signatureStatus?.state ===
          "WAIT-CONSUMER-IDENTIFICATION"
        ) {
          setFormStep(2);
        } else if (
          resIdPoint.data?.signatureStatus?.state === "WAIT-SCAN-UPLOAD"
        ) {
          setFormStep(3);
        } else if (resIdPoint.data?.signatureStatus?.state === "COMPLETE") {
          setFormStep(4);
        }

        console.log("IDPOINT status:", resIdPoint.data?.signatureStatus?.state);
      }

      if (myDssResUuid) {
        const resMyDss = await getSignatureStatus({
          variables: { consumerUuid: myDssResUuid },
        });

        const reqData = await getRequiredDocument({
          variables: { consumerUuid: myDssResUuid },
        });
        const documents = reqData.data.requiredDocuments || [];
        console.log("MYDSS status:", resMyDss.data?.signatureStatus?.state);

        if (resMyDss.data) {
          if (
            resMyDss.data?.signatureStatus?.state === "START" ||
            resMyDss.data?.signatureStatus?.state === "WAIT-PKCS10"
          ) {
            setFormStep1(1);
          }
          if (
            resMyDss.data?.signatureStatus?.state === "WAIT-PKCS10" &&
            checkPassportFileSize(documents)
          ) {
            setFormStep1(2);
          }
          if (
            resMyDss.data?.signatureStatus?.state ===
            "WAIT-CONSUMER-IDENTIFICATION"
          ) {
            setFormStep1(3);
          }
          if (resMyDss.data?.signatureStatus?.state === "VALIDATION") {
            setFormStep1(4);
          }
          if (resMyDss.data?.signatureStatus?.state === "COMPLETE") {
            setFormStep1(5);
            setCreatedAtMyDss(resMyDss.data?.signatureStatus?.createdAt);
            setDeletedAtMyDss(resMyDss.data?.signatureStatus?.expireDate);
          }
        }
      }
      if (item.node.signaturesCurrent) {
        item.node.signaturesCurrent.forEach((sign) => {
          if (sign.connector === "DIGITAL_DEAL" && sign.state === "COMPLETE") {
            setFormStep1(5);
          }
        });
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Объединяем оба вызова в один useEffect
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateStatus();
    }, 15000);

    // Первоначальный вызов сразу при монтировании компонента
    updateStatus();

    return () => clearInterval(intervalId);
  }, [idPointResUuid, myDssResUuid]);

  useEffect(() => {
    if (tariffsData?.tariffs) {
      const types = tariffsData.tariffs.map((item) => [
        item.tariffId,
        item.name,
      ]);

      setTariffType(types);

      const map = types.reduce((acc, [uuid, name]) => {
        acc[name] = uuid;
        return acc;
      }, {});
      setOptionsMap(map);
    }
  }, [tariffsData]);

  const handleSelectChange = (e) => {
    const selectedName = e.target.value;
    const selectedUUID = optionsMap[selectedName];
    selectedUUID === "8d9ea681-3cdb-4aa5-9ba6-7cb91f5e120a" && setTariffDay(14);
    setTariffUUID(selectedUUID);
  };

  const formatISODate = (isoDateString) => {
    const date = new Date(isoDateString);
    if (!date) {
      return "Отсутсвует";
    } else {
      return `${String(date.getDate()).padStart(2, "0")}.${String(
        date.getMonth() + 1
      ).padStart(2, "0")}.${date.getFullYear()} ${String(
        date.getHours()
      ).padStart(2, "0")}:${String(date.getMinutes()).padStart(
        2,
        "0"
      )}:${String(date.getSeconds()).padStart(2, "0")}`;
    }
  };

  useEffect(() => {
    if (item?.node?.signatureRelease) {
      updateStatus();
    }
  }, [item]);
  const handleDownloadDocument = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorDocument(null);

    try {
      const reqData = await getRequiredDocument({
        variables: { consumerUuid: idPointResUuid },
      });
      const documents = reqData.data.requiredDocuments;
      const documentId = documents[0].documentId;

      const res = await createDocumentFile({
        variables: {
          consumerUuid: idPointResUuid,
          documentId: documentId,
        },
      });

      const { success, fileContent } = res.data.generateDocumentTemplate;

      if (success) {
        const firstName = item?.node?.firstName || "";
        const lastName = item?.node?.lastName || "";
        const patronymic = item?.node?.patronymic || "";
        // Декодируем base64 строку в массив байтов
        const byteCharacters = atob(fileContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Создаем Blob из массива байтов
        const blob = new Blob([byteArray], {
          type: "application/pdf",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `IDP-${lastName} ${firstName} ${patronymic}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        setErrorDocument("Failed to generate document.");
      }
    } catch (err) {
      setErrorDocument(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadDocumentMyDss = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorDocument(null);

    try {
      const reqData = await getRequiredDocument({
        variables: { consumerUuid: myDssResUuid },
      });
      const documents = reqData.data.requiredDocuments;
      const documentId = documents[0].documentId;

      const res = await createDocumentFile({
        variables: {
          consumerUuid: myDssResUuid,
          documentId: documentId,
        },
      });

      const { success, fileContent } = res.data.generateDocumentTemplate;

      if (success) {
        const firstName = item?.node?.firstName || "";
        const lastName = item?.node?.lastName || "";
        const patronymic = item?.node?.patronymic || "";
        // Декодируем base64 строку в массив байтов
        const byteCharacters = atob(fileContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Создаем Blob из массива байтов
        const blob = new Blob([byteArray], {
          type: "application/pdf",
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `MyDss-${lastName} ${firstName} ${patronymic}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        setErrorDocument("Failed to generate document.");
      }
    } catch (err) {
      setErrorDocument(err.message);
    } finally {
      setLoading(false);
    }
  };

  const sendFiles = async (formData) => {
    try {
      const reqData = await getRequiredDocument({
        variables: { consumerUuid: idPointResUuid },
      });
      const { documentId } = reqData.data.requiredDocuments[0];

      const response = await uploadDocument({
        variables: {
          consumerUuid: idPointResUuid,
          documentId: documentId,
          file: formData.passportScan[0],
        },
      });

      if (response.data.signatureUploadDocument.success) {
        await getConfirmedState({
          variables: { consumerUuid: idPointResUuid },
        }).then((res) => {
          res.data.confirmedState.state === "WAIT-SCAN-UPLOAD" &&
            setFormStep(3);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const sendStatements = async (formData) => {
    try {
      const reqData = await getRequiredDocument({
        variables: { consumerUuid: idPointResUuid },
      });
      const { photo, sertificate } = formData;
      const documents = reqData.data.requiredDocuments;

      if (documents.length > 0) {
        let allSuccess = true;

        const uploadPromises = documents.map((document, index) => {
          const documentId = document.documentId;
          const file = index === 0 ? photo[0] : sertificate[0];
          return uploadDocument({
            variables: {
              consumerUuid: idPointResUuid,
              documentId: documentId,
              file: file,
            },
          });
        });

        const responses = await Promise.all(uploadPromises);

        responses.forEach((response) => {
          if (!response.data.signatureUploadDocument.success) {
            allSuccess = false;
          }
        });

        if (allSuccess) {
          await getConfirmedState({
            variables: { consumerUuid: idPointResUuid },
          }).then((res) => {
            res.data.confirmedState.state === "COMPLETE" && setFormStep(4);
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const sendFilesMydss = async (formData) => {
    setIsLoading(true);
    try {
      const reqData = await getRequiredDocument({
        variables: { consumerUuid: myDssResUuid },
      });
      const { documentId } = reqData.data.requiredDocuments[0];

      const response = await uploadDocument({
        variables: {
          consumerUuid: myDssResUuid,
          documentId: documentId,
          file: formData.passportScan[0],
        },
      });
      if (response.data) {
        await getConfirmedState({
          variables: { consumerUuid: myDssResUuid },
        })
          .then((res) => {
            // res.data.confirmedState.state === "WAIT-SCAN-UPLOAD" &&
            setFormStep(2);
            setIsLoading(false);
          })
          .catch((err) => console.log(err, "pass"));
      }
    } catch (err) {
      console.error(err.message);
      setIsLoading(false);
    } finally {
      updateStatus();
    }
  };

  const sendStatementsMydss = async (formData) => {
    setIsLoading(true);
    try {
      const reqData = await getRequiredDocument({
        variables: { consumerUuid: myDssResUuid },
      });
      const { photo, sertificate } = formData;
      let documents = reqData.data.requiredDocuments;

      // Убираем элемент, где documentName === "Паспорт"
      documents = documents.filter(
        (document) => document.documentName !== "Паспорт"
      );

      if (documents.length > 0) {
        let allSuccess = true;

        const uploadPromises = documents.map((document, index) => {
          const documentId = document.documentId;
          const file = index === 0 ? photo[0] : sertificate[0];
          return uploadDocument({
            variables: {
              consumerUuid: myDssResUuid,
              documentId: documentId,
              file: file,
            },
          });
        });

        const responses = await Promise.all(uploadPromises);

        responses.forEach((response) => {
          if (!response.data.signatureUploadDocument.success) {
            allSuccess = false;
          }
        });

        if (allSuccess) {
          await getConfirmedState({
            variables: { consumerUuid: myDssResUuid },
          }).then((res) => {
            setIsLoading(false);
          });
        }
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const edsFieldsVariantRender = useMemo(() => {
    if (tariffsLoading) return <p>Загрузка тарифов...</p>;
    if (errorLoading) return <p>Ошибка: {errorLoading}</p>;
    if (formStep1 === 0 && tabIndex === 0) {
      return (
        <div className={styles.edsCreateWrapper}>
          <Select
            isFormSelect={true}
            name="selectRate"
            label="Тариф"
            register={register}
            onClick={handleSelectChange}
            options={options}
            variant="bordered-green"
            size="full"
            placeholder={"Выберите тариф"}
          />
          <Button
            type="button"
            variant="green"
            className={styles.edsBtn}
            onClick={() => {
              createSignature({
                variables: {
                  input: {
                    personUuid: item?.node?.uuid,
                    connector: "DIGITAL_DEAL",
                    tariff: tariffUUID,
                    expireDay: tariffDay,
                  },
                },
              }).then((res) => {
                setMyDssResUuid(
                  res.data.createSignature.signature.consumerUuid
                );
                setFormStep1(1);
              });
            }}
          >
            Выпустить
          </Button>
        </div>
      );
    }
    if (formStep1 === 1 && tabIndex === 0) {
      return (
        // <div className={styles.edsCreateWrapper}>
        //   <span className={styles.edsParentSpan}>Статус выпуска подписи</span>
        //   Для продолжения установите приложение "Цифровая сделка" на ваше
        //   мобильное устройство. Пройдите все этапы регистрации и возвращайтесь
        //   <div className={styles.edsStatusBlock}>
        //     <Button
        //       type="button"
        //       variant="green"
        //       className={styles.edsBtn}
        //       onClick={() => {
        //         getConfirmedState({
        //           variables: { consumerUuid: myDssResUuid },
        //         }).then(() => {
        //           updateStatus();
        //           sendFilesMydss();
        //         });
        //       }}
        //     >
        //       Готово
        //     </Button>
        //   </div>
        // </div>
        <div className={styles.edsCreateWrapper}>
          <div className={styles.edsFilesWrapper}>
            <span>Скан паспорта</span>
            <FileField
              register={register}
              name="passportScan"
              className={styles.file}
              setValue={setValue}
              onChange={(e) => console.log(e.target)}
            />
          </div>
          <Button
            type="button"
            variant="green"
            className={styles.edsBtn}
            onClick={handleSubmit(sendFilesMydss)}
            disabled={isLoading}
          >
            {isLoading ? "Загрузка..." : "Отправить"}
          </Button>
        </div>
      );
    }

    if (formStep1 === 2 && tabIndex === 0) {
      return (
        <div className={styles.edsCreateWrapper}>
          <span className={styles.edsParentSpan}>
            Подтверждение в приложении
          </span>
          Начните выпускать сертификат в приложении
          <div className={styles.edsStatusBlock}>
            <span>Обновление статуса</span>
            <Loader />
          </div>
        </div>
      );
    }
    if (formStep1 === 3 && tabIndex === 0) {
      return (
        <div className={styles.edsCreateWrapper}>
          <div>
            <Button
              onClick={(e) => handleDownloadDocumentMyDss(e)}
              disabled={loading}
              className={styles.downloadDocumentButton}
            >
              {loading ? "Загрузка..." : "Скачать заявление"}
            </Button>
          </div>
          <div className={styles.edsFilesWrapper}>
            <span className={styles.edsPhoto}>Фотография заявления</span>
            <FileField
              register={register}
              name="sertificate"
              className={styles.file}
              setValue={setValue}
            />
          </div>
          <div className={styles.edsFilesWrapper}>
            <span className={styles.edsPhoto}>Фотография с заявлением</span>
            <FileField
              register={register}
              name="photo"
              className={styles.file}
              setValue={setValue}
            />
          </div>
          <Button
            type="button"
            variant="green"
            className={styles.edsBtn}
            onClick={handleSubmit(sendStatementsMydss)}
            disabled={isLoading}
          >
            {isLoading ? "Загрузка..." : "Отправить"}
          </Button>
          {/* <Button
            type="button"
            variant="green"
            className={styles.edsBtn}
            onClick={() => {
              const reqData = getConfirmedState({
                variables: { consumerUuid: idPointResUuid },
              });
              console.log(reqData);
            }}
          >
            Подтвердить
          </Button> */}
        </div>
      );
    }
    if (formStep1 === 4 && tabIndex === 0) {
      return (
        <div className={styles.edsCreateWrapper}>
          <span className={styles.edsParentSpan}>Статус выпуска подписи</span>
          Происходит проверка документов. Нужно подождать некоторое время.
          <div className={styles.edsStatusBlock}>
            <span
              onClick={() => {
                getRequiredDocument({
                  variables: { consumerUuid: myDssResUuid },
                }).then((res) => console.log(res));
              }}
            >
              Обновление статуса
            </span>
            <Loader />
          </div>
        </div>
      );
    }
    if (formStep1 === 5 && tabIndex === 0) {
      return (
        <div className={styles.idpSuccess}>
          <h3 className={styles.idpTitle}>Выпуск подписи MyDss</h3>
          <span className={styles.sucessSpan}>Подпись выпущена</span>
          <div className={styles.idpInfo}>
            <div className={styles.idpInfoBlock}>
              <span>Дата создания: </span>
              <span>
                {createdAtMyDss && createdAtMyDss}
              </span>
            </div>
            <div className={styles.idpInfoBlock}>
              <span>Дата окончания: </span>
              <span>
                {deletedAtMyDss && deletedAtMyDss}
              </span>
            </div>
          </div>
          <Button
            type="button"
            variant="green"
            className={styles.releaseBtn}
            onClick={() => setFormStep1(0)}
          >
            Перевыпустить подпись
          </Button>
        </div>
      );
    }
    if (formStep === 0 && tabIndex === 1) {
      return (
        <Button
          type="button"
          variant="green"
          className={styles.releaseBtn}
          onClick={() => {
            createSignature({
              variables: {
                input: {
                  personUuid: item?.node?.uuid,
                  connector: "ID_POINT",
                },
              },
            }).then((res) => {
              setIdPointResUuid(
                res.data.createSignature.signature.consumerUuid
              );
              setFormStep(1);
            });
          }}
        >
          Выпустить подпись
        </Button>
      );
    }

    if (formStep === 1 && tabIndex === 1) {
      return (
        <div className={styles.edsCreateWrapper}>
          <span className={styles.edsParentSpan}>Статус выпуска подписи</span>
          Для продолжения установите приложение ID на ваше мобильное устройство
          (ссылка направлена в СМС на ваш номер телефона). Пройдите все этапы
          регистрации и возвращайтесь
          <div className={styles.edsStatusBlock}>
            <span>Обновление статуса</span>
            <Loader />
          </div>
        </div>
      );
    }

    if (formStep === 2 && tabIndex === 1) {
      return (
        <div className={styles.edsCreateWrapper}>
          <div className={styles.edsFilesWrapper}>
            <span>Скан паспорта</span>
            <FileField
              register={register}
              name="passportScan"
              className={styles.file}
              setValue={setValue}
              onChange={(e) => console.log(e.target)}
            />
          </div>
          <Button
            type="button"
            variant="green"
            className={styles.edsBtn}
            onClick={handleSubmit(sendFiles)}
          >
            Отправить
          </Button>
        </div>
      );
    }

    if (formStep === 3 && tabIndex === 1) {
      return (
        <div className={styles.edsCreateWrapper}>
          <div>
            <Button
              onClick={(e) => handleDownloadDocument(e)}
              disabled={loading}
              className={styles.downloadDocumentButton}
            >
              {loading ? "Загрузка..." : "Скачать заявление"}
            </Button>
          </div>
          <div className={styles.edsFilesWrapper}>
            <span>Сертификат КЭП ФЛ</span>
            <FileField
              register={register}
              name="sertificate"
              className={styles.file}
              setValue={setValue}
            />
          </div>
          <div className={styles.edsFilesWrapper}>
            <span className={styles.edsPhoto}>Фотография с бланком</span>
            <FileField
              register={register}
              name="photo"
              className={styles.file}
              setValue={setValue}
            />
          </div>
          <Button
            type="button"
            variant="green"
            className={styles.edsBtn}
            onClick={handleSubmit(sendStatements)}
          >
            Отправить
          </Button>
        </div>
      );
    }
    if (formStep === 4 && tabIndex === 1) {
      return (
        <div className={styles.idpSuccess}>
          <h3 className={styles.idpTitle}>Выпуск ЭЦП</h3>
          <span className={styles.sucessSpan}>Подпись выпущена</span>
          <div className={styles.idpInfo}>
            {item?.node?.signatures.map((item) => {
              if (item.connector === "ID_POINT") {
                return (
                  <>
                    <div className={styles.idpInfoBlock}>
                      <span>Дата создания: </span>
                      <span>
                        {formatISODate(item.createdAt) || "Отсутствует"}
                      </span>
                    </div>
                    <div className={styles.idpInfoBlock}>
                      <span>Дата окончания: </span>
                      <span>
                        {formatISODate(item.expireDate) || "Отсутствует"}
                      </span>
                    </div>
                  </>
                );
              }
            })}
          </div>
          <Button
            type="button"
            variant="green"
            className={styles.releaseBtn}
            onClick={(e) => {
              e.preventDefault();
              createSignature({
                variables: {
                  input: {
                    personUuid: item?.node?.uuid,
                    connector: "ID_POINT",
                  },
                },
              }).then((res) => {
                setIdPointResUuid(
                  res.data.createSignature.signature.consumerUuid
                );
                setFormStep(1);
              });
            }}
          >
            Перевыпустить подпись
          </Button>
        </div>
      );
    }
    return null;
  }, [
    formStep,
    register,
    tabIndex,
    errors,
    setValue,
    tariffsLoading,
    errorLoading,
    tariffUUID,
    formStep1,
    myDssResUuid,
    idPointResUuid,
  ]);

  return (
    <div>
      <div className={styles.edsStatus}>
        {id ? (
          <>
            <h3 className={styles.edsTitle}>Создать ЭЦП</h3>
            <Tabs
              onSelect={(index) => {
                setTabIndex(index);
                updateStatus();
              }}
            >
              <TabList className={styles.tabList}>
                <Tab className={styles.edsTabs}>myDss</Tab>
                <Tab className={styles.edsTabs}>IDPoint</Tab>
              </TabList>

              <TabPanel>
                <form className={styles.edsForm}>{edsFieldsVariantRender}</form>
              </TabPanel>
              <TabPanel>
                <form className={styles.edsForm}>{edsFieldsVariantRender}</form>
              </TabPanel>
            </Tabs>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default SignatureForm;
