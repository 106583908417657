import { createAsyncThunk } from "@reduxjs/toolkit";
import { conctractSliceActions } from "../slice/contractSlice";

export const clientDocmentOverWrite = createAsyncThunk(
  'contractSlice/clientDocmentOverWrite',
  async({body, clientUuid, documentUuid}, thunkApi) => {
    const confirmUrl = process.env.REACT_APP_CLIENT_DOCUMENTS
    const fullUrl = confirmUrl + `physical-persons/${clientUuid}/documents/${documentUuid}/overwrite/`
    try {
      const formData = new FormData();
      formData.append('description', body.description);
      const response = await fetch(fullUrl, {
        method: 'POST',
        body: formData
      })

      if(!response.ok){
        throw new Error('Не удалось отправить запрос')
      }

      const data = response.json()

      thunkApi.dispatch(conctractSliceActions.changeDocumentStatus(data))

    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  }
)