import styles from './CertificatesList.module.scss'
import {Select} from '../../../Select'
import {Button} from '../../../Button'
import { useState, useEffect, useCallback } from 'react'

export const CertificatesList = ({value, setValue, cryptopro, setPlugin}) => {
  const [certificates, setCertificates] = useState([])

  const getCertificatesList = useCallback(() => {
    cryptopro.listCertificates().then((data) => {
      setCertificates(data);
      setValue(data[0].id);
    });
  }, [cryptopro]);

  useEffect(() => {
    cryptopro.init().then(function (info) {
      console.log("Initialized", info);
      setPlugin(info)
    });
  }, [])

  useEffect(() => {
    getCertificatesList();
  }, []);

  return(
    <div className={styles.selectWrapper}>
      <Select value={value} setValue={setValue}>
      {certificates.map((certificate, index) => (
        <option key={index} value={certificate.id}>{certificate.name}</option>
      ))}
    </Select>
    <Button type='button' variant='outline-green' onClick={getCertificatesList}>Обновить список сертификатов</Button>
    </div>
  )
}