export const formattedDateFunc = (dateArg, isShortFormat) => {
  const date = new Date(dateArg);

  const options = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formattedDate = date.toLocaleDateString("ru-RU", options);
  const formattedTime = date.toLocaleTimeString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });

  if (isShortFormat) {
    const finalFormattedDate = `${formattedTime}`;
    return finalFormattedDate;
  } else {
    const finalFormattedDate = `${
      formattedDate.split(" г.")[0]
    } ${formattedTime}`;

    return finalFormattedDate;
  }
};
