import { gql } from "@apollo/client";

export const CREATE_REPRESENTED_COMPANY = gql`
  mutation createRepresentedCompany(
  $representativeId: UUID!
  $representedId: UUID!
  $representingId: UUID!
){
  createLegalPersonRepresentedByPhysicalPerson(
    representedId: $representedId
    representativeId: $representativeId
    representingId: $representingId
  ){
    legalPersonRepresented{
      id
    }
  }
}
`