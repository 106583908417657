import React, { useRef, useState } from "react";
import styles from "./DeclarationBlock.module.scss";
import { SignaturePopup } from "../DocumentsBlock/ui/SignaturePopup";
import { CryptoToken } from "../../../../../CryptoToken";
import { TokenKey } from "../../../../../../assets/icons/TokenKey";
import { downloadFile } from "../../../../../PhysicalDocumentsUpload/ui/PhysicalDocumentsUpload";
import { downloadSigFile } from "../../../../../../helpers/downloadSigFile";

export const DeclarationBlock = ({
  declaration,
  applicationId,
  participants,
  applicationInfo
}) => {
  //declaration - все данные по заявлениям
  const accessToken = localStorage.getItem("accessToken"); // необходим для скачивания файла
  const toastRefs = useRef([]);
  const [checkPluginStatus, setCheckPluginStatus] = useState(false);

  const addToRefs = (el) => {
    if (el && !toastRefs.current.includes(el)) {
      toastRefs.current.push(el);
    }
  };

  const downloadExistFiles = (token, url, name) => {
    fetch(url, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Непредвиденная ошибка");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const title = /[^/]*$/.exec(url)[0];
        a.href = url;
        a.download = name ? name : title;
        a.target = "_blank";
        a.rel = "noreferrer";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Ошибка скачивания:", error);
      });
  };

  return (
    <div className={styles.declarationContainer}>
      <div className={styles.declarationText}>Заявление</div>
      <div className={styles.rightBlock}>
        <div className={styles.oneLine}>
          <div
            className={styles.declarationRightText}
            onClick={() => downloadExistFiles(accessToken, declaration?.pdfUrl)}
          >
            Посмотреть
          </div>
          {declaration?.sigFile !== (null || "") && (
            <span
              className={styles.declarationRightText}
              onClick={() =>
                downloadSigFile(
                  declaration?.presignedSigUrl,
                  declaration?.file?.split(".")[0]?.split("/")[2] +
                    "." +
                    declaration?.file?.split(".")[1]
                )
              }
            >
              Скачать подпись
            </span>
          )}
        </div>
        {declaration?.documentsSignatures?.map((item, index) => (
          <div className={styles.centralBMenu} key={index}>
            <div className={styles.centralLeftText}>
              {item?.person?.firstName +
                " " +
                item?.person?.lastName +
                " " +
                item?.person?.patronymic}
            </div>
            <div className={styles.dashed}></div>
            <div className={styles.statementSignWrapper}>
              {item.state !== "COMPLETE"  && (
                  <abbr
                    ref={addToRefs}
                    title={
                      checkPluginStatus
                        ? "Подписать токеном"
                        : "Плагин КриптоПро не найден"
                    }
                    className={styles.tokenKey}
                  >
                    <CryptoToken
                      showEdsBtn={false}
                      tokenBtnContent={
                        <TokenKey
                          className={
                            !checkPluginStatus ? styles.negativeSvg : ""
                          }
                        />
                      }
                      tokenBtnClassName={styles.tokenBtn}
                      toastClassName={styles.statementToast}
                      toastRef={toastRefs.current[index]}
                      file={declaration?.presignedUrl}
                      fileName={declaration?.title}
                      applicationId={applicationId}
                      applicationInfo={applicationInfo}
                      documentId={item?.id}
                      setCheckPluginStatus={setCheckPluginStatus}
                    />
                  </abbr>
                )}

              {item?.state === "START" && (
                <div className={styles.centralRightText}>ждем подпись</div>
              )}
              {item?.state === "WAITING" && (
                <div className={styles.centralRightText}>ждем подпись</div>
              )}
              {item?.state === "WAITING_SEND" && (
                <div className={styles.centralRightText}>ждем подпись</div>
              )}
              {item?.state === "REJECTED" && (
                <div className={styles.centralRightRedText}>
                  техпакет не подписан
                </div>
              )}
              {item?.state === "ERROR" && (
                <div className={styles.centralRightRedText}>
                  ошибка подписания
                </div>
              )}
              {item?.state == "COMPLETE" && (
                <div className={styles.centralRightText}>
                  <span className={styles.greenRightText}>Подписано</span>{" "}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};