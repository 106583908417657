import { gql } from "@apollo/client";

export const CREATE_SIGNATURE = gql`
  mutation CreateSignature($input: CreateSignatureInput!) {
    createSignature(input: $input) {
      signature {
        id
        consumerUuid
        isActive
        state
        type
      }
    }
  }
`;
export const SIGNATURE_STATUS_QUERY = gql`
  query SignatureStatus($consumerUuid: UUID!) {
    signatureStatus(consumerUuid: $consumerUuid) {
      consumerUuid
      type
      state
      connector
      createdAt
      updatedAt
    }
  }
`;

export const UPLOAD_DOCUMENT_MUTATION = gql`
  mutation ($consumerUuid: UUID!, $documentId: Int!, $file: Upload!) {
    signatureUploadDocument(
      consumerUuid: $consumerUuid
      documentId: $documentId
      file: $file
    ) {
      success
      message
      document
    }
  }
`;

export const REQUIRED_DOCUMENTS_QUERY = gql`
  query ($consumerUuid: UUID!) {
    requiredDocuments(consumerUuid: $consumerUuid) {
      documentId
      documentName
      type
      files {
        fileExtension
        fileId
        fileName
        fileSize
        hasSignatureFile
        mime
      }
    }
  }
`;

export const CONFIRMED_STATE_QUERY = gql`
  query ($consumerUuid: UUID!) {
    confirmedState(consumerUuid: $consumerUuid) {
      consumerUuid
      type
      state
      connector
      createdAt
      updatedAt
    }
  }
`;

export const DECODE_BASE64_MUTATION = gql`
  mutation generateDocumentTemplate($consumerUuid: UUID!, $documentId: Int!) {
    generateDocumentTemplate(
      consumerUuid: $consumerUuid
      documentId: $documentId
    ) {
      success
      message
      fileContent
    }
  }
`;

export const GET_TARIFFS = gql`
  query GetTariffs {
    tariffs {
      certificationAuthorityCode
      cost
      digitalSignatureKindCode
      name
      tariffId
      tariffTypeCode
    }
  }
`;
