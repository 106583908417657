import React, { useEffect, useState } from "react";
import authStyles from "../AuthForm/AuthForm.module.scss";
import styles from "./PasswordRecoveryForm.module.scss";
import { Input } from "../../../Input/ui/Input";
import { Button } from "../../../Button/ui/Button";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { PASSWORD_RECOVERY } from "../../../../services/queries/queries/login";
import { toast } from "react-toastify";

export const PasswordRecoveryForm = ({ setRestorePassword, notClient }) => {
  const [passwordRecoveryTextStatus, setPasswordRecoveryTextStatus] =
    useState("");
  const regEmail = /\S+@\S+\.\S+/;
  const regPhone = /^\+?[8] [-\(]?\d{3}\) ?-?\d{3}-?\d{2}-?\d{2}$/;
  const [isPasswordSended, setIsPasswordSended] = useState(false);
  const [mutateFunction, { data, loading, error }] = useMutation(
    PASSWORD_RECOVERY,
    {
      onError(err) {
        toast.error(err?.message);
      },
    }
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchedPhone = watch("phone");

  const onSubmit = (formData) => {
    mutateFunction({
      variables: {
        email: formData.email,
        phone: formData.phone,
      },
    }).then((res) => {
      if (res.errors?.message?.length === 0 || !res.errors) {
        setIsPasswordSended(true);
      }
    });
  };
  useEffect(() => {
    console.log(isPasswordSended);
    isPasswordSended &&
      toast(
        "Ссылка на форму восстановления пароля отправленна Вам на электронную почту."
      );
  }, [isPasswordSended]);
  return (
    <form className={styles.restorePassword} onSubmit={handleSubmit(onSubmit)}>
      <h3 className={styles.title}>Восставновление пароля</h3>
      {notClient ? (
        <Input
          id="login"
          name="login"
          type="login"
          label="Логин"
          errors={errors}
          isFormField={true}
          errorVariant="absolute"
          className={authStyles.input}
          register={register}
          registerObj={{
            onChange: () => {
              clearErrors(`login`);
            },
          }}
        />
      ) : (
        <>
          <p className={styles.info}>
            Введите почту или телефон, для которых нужно восстановить пароль
          </p>
          <Input
            id="phone"
            name="phone"
            type="tel"
            mask="+9(999) 999-99-99"
            label="Телефон"
            errorVariant="absolute"
            errors={errors}
            isFormField={true}
            errorClassName={styles.error}
            register={register}
            className={authStyles.input}
            registerObj={{
              required: "Укажите номер телефона",
            }}
          />

          <Input
            id="email"
            name="email"
            type="email"
            label="Почта"
            errors={errors}
            isFormField={true}
            errorVariant="absolute"
            className={authStyles.input}
            register={register}
            registerObj={{
              pattern: {
                value: regEmail,
                message: `Укажите корректный e-mail`,
              },
              onChange: () => {
                clearErrors(`email`);
              },
            }}
          />
        </>
      )}

      {passwordRecoveryTextStatus && (
        <span className={styles.passwordRecoveryTextStatus}>
          {passwordRecoveryTextStatus}
        </span>
      )}
      <div className={styles.buttonWrapper}>
        <Button className={authStyles.submitBtn} variant="green" type="submit">
          Восстановить пароль
        </Button>

        <Button
          className={styles.loginButton}
          variant="transparent"
          onClick={() => setRestorePassword(false)}
        >
          {notClient ? <span>Вход</span> : <span>Вход / регистрация</span>}
        </Button>
      </div>
    </form>
  );
};
