import { gql } from "@apollo/client";

export const PHYSICALPERSONSLIST = gql`
  query PhysycalPersonsList($search: String, $first: Int, $offset: Int) {
    allPhysicalPersons(search: $search, first: $first, offset: $offset) {
      edges {
        node {
          id
          uuid
          firstName
          lastName
          patronymic
          gender
          dateOfBirth
          placeOfBirth
          signatures {
            connector
            consumerUuid
            createdAt
            dateRelease
            expireDate
            id
            isActive
            state
            type
            updatedAt
          }
          signaturesCurrent {
            connector
            consumerUuid
            createdAt
            expireDate
            isActive
            state
            type
          }
          signaturesActive {
            state
            type
            isActive
            createdAt
            consumerUuid
            expireDate
            connector
          }
          signatureRelease {
            connector
            consumerUuid
            createdAt
            dateRelease
            expireDate
            id
            isActive
            state
            type
            updatedAt
          }

          citizenship
          snils
          inn
          registrationAddress {
            value
          }
          document {
            code
            series
            number
            issuedBy
            dateIssue
            type
          }
          user {
            email
            phone
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_ADDRESS_PHYSICAL = gql`
  query GetAddressPhysical($id: ID!) {
    physicalPerson(id: $id) {
      registrationAddress {
        value
        structure
        streetType
        regionType
        street
        regionKladrId
        region
        oktmo
        okato
        locality
        kladr
        index
        id
        house
        fias
        district
        country
        city
        building
        block
        apartment
      }
    }
  }
`;
