import { useCallback } from "react";


const useFormatDocumentDate = () => {
	const formatDocumentDate = useCallback((date) => {
		return new Date(date).toLocaleDateString("ru-RU", {
			day: "numeric",
			month: "long",
			year: "numeric",
		});
	}, []);

	return { formatDocumentDate };
};

export default useFormatDocumentDate;
