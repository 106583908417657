import styles from './InfoList.module.scss'
import cn from 'classnames'

/* 
Стилизованная оболочка для отображения элементов списков

Пропсы:

children - содержимое компонента

className - дополнительные стили, которые могут быть переданы из компонента, где вызывается InfoList

...props - возможность использовать более редкие аогументы, которые принимает элемент li

*/

export const InfoList = ({children, className, ...props}) => {
  return(
    <li className={cn(styles.infoList, className)} {...props}>{children}</li>
  )
}