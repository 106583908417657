import { useForm } from "react-hook-form";
import { FormTemplate } from "../FormTemplate/FormTemplate";
import styles from "./CreateExtractForm.module.scss";
import { Input } from "../../../Input";
import { useState } from "react";
import { Checkbox } from "../../../Checkbox";
import { Button } from "../../../Button";

export const CreateExtractForm = ({
  extractsData,
  setShowModal,
  hideModal,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
  });

  const [query, setQuery] = useState("");

  return (
    <FormTemplate
      dataArr={extractsData}
      handleSubmit={handleSubmit}
      reset={reset}
      setShowModal={setShowModal}
      hideModal={hideModal}
    >
      <div>
        <h3 className={styles.formTitle}>Новая выписка из ЕГРН</h3>
        <div className={styles.searchBox}>
          <label htmlFor="='extractQuery'" className={styles.searchLabel}>
            Кадастровый номер или адрес
          </label>
          <Input
            id="extractQuery"
            name="extractQuery"
            value={query}
            setValue={setQuery}
            variant="bordered-green"
            size="full"
          />
          <span className={styles.searchResults}></span>
          <div className={styles.extrasVariantsBox}>
          <span className={styles.variantsLabel}>Выписка</span>
          <div className={styles.extrasVariants}>
            <Checkbox
              register={register}
              name="test"
              className={styles.checkbox}
              label="Выписка из Единого государственного реестра недвижимости об объекте недвижимости, за 1 единицу в рублях"
            />
            <Checkbox
              register={register}
              name="test"
              className={styles.checkbox}
              label="Выписка из Единого государственного реестра недвижимости о зарегистрированных договорах участия в долевом строительстве, за 1 единицу в рублях"
            />

            <Checkbox
              register={register}
              name="test"
              className={styles.checkbox}
              label="Выписка из Единого государственного реестра недвижимости об основных характеристиках и зарегистрированных правах на объект недвижимости"
            />
            <Checkbox
              register={register}
              name="test"
              className={styles.checkbox}
              label="Выписка из Единого государственного реестра недвижимости о переходе прав на объект недвижимости"
            />
            
          </div>
          </div>
          
        </div>
        <Button type='submit' variant='green'>Заказать выписку</Button>
      </div>
    </FormTemplate>
  );
};
