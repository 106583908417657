import { gql } from "@apollo/client";

export const UPDATE_APPLICATION_PARTICIPANT = gql`
	mutation updateApplicationParticipant(
		$participantUuid: UUID!
		$participantData: UpdateParticipantInput!
	) {
		updateParticipant(
			participantData: $participantData
			participantUuid: $participantUuid
		) {
			participant {
				id
			}
		}
	}
`;
