import { useState, useEffect } from "react";
import { formatDate } from "../../../../helpers/formatDate";
import { translateStatus } from "../../../../helpers/translateStatus";
import styles from "./StatementStatuses.module.scss";
import { downloadExistFiles } from "../../../../components/Forms/ui/ApplicationForm/ApplicationForm";

export const StatementStatuses = ({ requestHistory, application, kuvd }) => {
  const accessToken = localStorage.getItem("accessToken");

  return (
    <div className={styles.statusesWrapper}>
      {/* {requestHistory?.length > 0 && (
        <div className={styles.applicationStatusBlock}>
          <div>
            <h1 className={styles.application_headerText}>Заявление</h1>
          </div>
          <div className={styles.applicationStatusBlock}>
            {requestHistory?.map((item, index) => (
              <div key={index}>
                {formatDate(item.createdAt)} -{" "}
                {item?.statusDescription !== null
                  ? item?.statusDescription
                  : translateStatus(item.status)}
              </div>
            ))}
          </div>
        </div>
      )} */}
      {requestHistory?.length > 0 && (
        <div className={styles.applicationStatusBlock}>
          <div>
            <h3 className={styles.application_headerText}>Статус заявления</h3>
            {requestHistory.map((item) => (
              <div>
                {formatDate(item.createdAt)} -{" "}
                {item?.statusDescription !== null
                  ? item?.statusDescription
                  : translateStatus(item.status, kuvd)}
                {item?.statusDescription === "Принято от заявителя" &&
                  ` Присвоен ${kuvd}`}
                {item?.statusDescription === "Получено от РР" && (
                  <span
                    className={styles.link}
                    onClick={() => {
                      downloadExistFiles(
                        accessToken,
                        `https://api.unireg.redfoxlab.ru/application/${application?.uuid}/download-zip-send/`
                      );
                    }}
                  >
                    {`скачать пакет`}
                  </span>
                )}
                {item?.status === "SEND_TO" && (
                  <span
                    onClick={() => {
                      downloadExistFiles(
                        accessToken,
                        `https://api.unireg.redfoxlab.ru/application/${application?.uuid}/download-zip-send/`
                      );
                    }}
                    className={styles.link}
                  >
                    {`скачать пакет`}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
