import styles from "./Container.module.scss";
// import { useQuery } from "@apollo/client";
// import {  useSelector } from "react-redux";
// import { selectToken } from "../../../redux/slices/user";
// import { USER } from "../../../services/queries";
// import useRefreshToken from "../../../hooks/useRefreshToken";
export const Container = ({ children }) => {
	// const { refreshTokenHandle} = useRefreshToken();
	// const token = useSelector(selectToken);

	// const { loading, error, data } = useQuery(USER, {
	// 	onError(err) {
	// 		if (err?.message === "Signature has expired") {
	// 			refreshTokenHandle();
	// 		}
	// 	},
	// 	context: {
	// 		headers: {
	// 			Authorization: `JWT ${token}`,
	// 		},
	// 	},
	// });

	return <div className={styles.container}>{children}</div>;
};
