import styles from "./Duty.module.scss";
import { Button } from "../../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { DocumentViewer } from "../../../components/DocumentViewer/ui/DocumentViewer";

export const Duty = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {fileUrl} = location.state || null


  return (
    <div className={styles.dutyContent}>
      <Button
        className={styles.dutyBtn}
        onClick={() => navigate(`/client-cabinet/`)}
      >
        <span>{"<"}</span>
        Кабинет
      </Button>
      <h3 className={styles.dutyContentTitle}>Пошлина</h3>
      <DocumentViewer fileUrl={fileUrl} />
    </div>
  );
};
