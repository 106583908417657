import { gql } from "@apollo/client";

export const UPDATE_COMPANY = gql`
  mutation UpdateCompanyInfo(
		$inputAddress: AddressInput
		$inputLegal: CreateLegalPersonInput
		$uuid: UUID!
  ) {
    updateLegalPerson(
      inputAddress:  $inputAddress
			inputLegal: $inputLegal
		  uuid:$uuid
    ) {
      legalPerson {
        uuid
      }
    }
  }
`;
