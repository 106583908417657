import { useContext, useState, useEffect } from "react";
import { AppealsList } from "../../../components/Lists";
import styles from "./Support.module.scss";
import { Context } from "../../../App";
import { useLocation } from "react-router-dom";
import { ModalWindow } from "../../../components/ModalWindow";
import { CreateAppealForm } from "../../../components/Forms";
import { useQuery } from "@apollo/client";
import { ALL_TICKETS_LIST } from "../../../services/queries/queries/support/allTicketsList";
import { Preloader } from "../../../components/Preloader";
import { toast } from "react-toastify";
import { Pagination } from "../../../components/Pagination/ui/Pagination";

export const Support = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const first = 10; // Количество объектов на странице
  const offset = currentPage * first;
  const { data, loading, error } = useQuery(ALL_TICKETS_LIST, {
    variables: { offset: offset, first: first },
  });

  const { showModal, setShowModal } = useContext(Context);
  const location = useLocation();
  const currentPath = location.pathname;
  const [tickets, setTickets] = useState([]);

  const totalObjects = data ? data.allTickets.totalCount : 0;
  const totalPages = Math.ceil(totalObjects / first);

  useEffect(() => {
    if (data && data.allTickets) {
      setTickets(data.allTickets.edges);
      if (data.allTickets.edges.length <= 0) {
        toast.error("Обращения не найдены");
      }
    }
  }, [data]);

  return (
    <>
      <h2 className={styles.supportTitle}>Обращения в поддержку</h2>
      {loading ? (
        <Preloader />
      ) : error && tickets.length === 0 ? (
        toast.error("Обращения не найдены")
      ) : (
        <AppealsList appealsData={tickets} />
      )}

      <Pagination
        page={currentPage}
        setPage={setCurrentPage}
        totalPages={[...Array(totalPages).keys()]}
      />
      {currentPath === "/support/" && showModal && (
        <ModalWindow show={showModal} setShowModal={setShowModal} size="xl">
          <CreateAppealForm setShowModal={setShowModal} />
        </ModalWindow>
      )}
    </>
  );
};
