import { gql } from "@apollo/client";

  export const UPDATE_SIGNATURE_ACTIVE = gql`
    mutation UpdateSignatureActive(
        $consumerUuid: UUID!
    ){
    updateSignatureActive(
      consumerUuid: $consumerUuid
    ){
      signature {
        connector
        consumerUuid
      }
    }
  }
  `
