import { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./RepresentativesList.module.scss";
import { RepresentativeBox } from "../../RepresentativeBox";
import { useForm } from "react-hook-form";
import cn from "classnames";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { Button } from "../../Button";
import { AddFile } from "../../../assets/icons/AddFile";
import { Select } from "../../Select";
import { FileField } from "../../FileField";
import { FormCalendar } from "../../FormCalendar";
import { DELETE_REPRESENTATIVES } from "../../../services/queries/mutations/companies/deleteRepresentatives";
import { ALL_COMPANIES } from "../../../services/queries/queries/companies/allCompaniesList";
import { COMPANY_INFO } from "../../../services/queries/queries/companies/companyInfo";
import useFormatDocumentDate from "../../../hooks/useFormatDocumentDate";
import { useLegalAuthorityDocumentTypes } from "../../../hooks/useLegalAuthorityDocumentTypes";
import {
	setRepresentatives,
	setRepresentative,
} from "../../../redux/slices/legalPerson";

const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;

export const RepresentativesList = ({
	data = [],
	item,
	companyData = [],
	physicalData = [],
	setData,
	id,
	uuid,
	onCreateDocument,
}) => {
	const dispatch = useDispatch();
	const [isFull, setIsFull] = useState(false);
	const { formatDocumentDate } = useFormatDocumentDate();
	const [deleteRepresentative] = useMutation(DELETE_REPRESENTATIVES, {
		refetchQueries: [
			{ query: ALL_COMPANIES },
			{ query: COMPANY_INFO, variables: { id }, skip: !id },
		],
		skip: !uuid,
	});

	useEffect(() => {
		dispatch(setRepresentatives(data));
	}, [data]);

	const { documentTypes } = useLegalAuthorityDocumentTypes();
	const {
		register,
		setValue,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		defaultValues: {
			documentType: item?.legalAuthorityRecord?.type?.uuid,
			typeperiodEnd: item?.legalAuthorityRecord?.durationStop,
			typePeriodStart: item?.legalAuthorityRecord?.durationStart,
		},
	});

	const watchedDocumentFile = watch("documentFile");
	const typePeriodStart = watch("typePeriodStart");
	const typeperiodEnd = watch("typeperiodEnd");
	const signatureFile = watch("signatureFile");
	const documentType = watch("documentType");

	useEffect(() => {
		if (watchedDocumentFile && documentType) {
			dispatch(
				setRepresentative({
					uuid: item?.uuid || item?.physicalPerson?.uuid,
					data: {
						legalAuthorityRecord: {
							file: watchedDocumentFile[0],
							signatureFile:
								signatureFile?.length > 0 && signatureFile[0],
							typeperiodEnd:
								typeperiodEnd &&
								typeperiodEnd?.replace(pattern, "$3-$2-$1"),
							typePeriodStart:
								typeperiodEnd &&
								typePeriodStart?.replace(pattern, "$3-$2-$1"),
							documentType: documentType,
						},
					},
				})
			);
		}
	}, [
		watchedDocumentFile,
		typePeriodStart,
		typeperiodEnd,
		signatureFile,
		documentType,
		dispatch,
		item?.uuid,
		item?.physicalPerson?.uuid,
	]);

	const onSubmitHandler = useCallback(
		async (formData, event) => {
			event.preventDefault();
			onCreateDocument(uuid);
			setIsFull(false);
		},
		[onCreateDocument, uuid]
	);

	const handleRemove = useCallback(
		(data) => {
			const deletedUuid = item.uuid || item.physicalPerson?.uuid;
			const filteredData = data.filter(
				(representative) =>
					(representative.uuid ||
						representative.physicalPerson?.uuid) !== deletedUuid
			);

			setData([...filteredData]);

			deleteRepresentative({
				variables: {
					uuid,
					representatives: [deletedUuid],
				},
			});
		},
		[item, setData, deleteRepresentative, uuid]
	);

	const firstName = item?.firstName || item?.physicalPerson?.firstName;
	const lastName = item?.lastName || item?.physicalPerson?.lastName;
	const patronymic = item?.patronymic || item?.physicalPerson?.patronymic;
	const snils = item?.snils || item?.physicalPerson?.snils;

	const listVariantRender = useMemo(() => {
		if (data?.length > 0) {
			return (
				<RepresentativeBox
					isFull={isFull}
					setIsFull={setIsFull}
					handleRemove={() => handleRemove(data)}
				>
					<div onClick={() => setIsFull(!isFull)}>
						<span>
							{firstName + " " + lastName + " " + patronymic}
						</span>
						<div className={styles.representativeContacts}>
							<span className={styles.name}>
								{item?.physicalPerson?.email}
							</span>
							<span className={styles.phone}>
								{item?.physicalPerson?.phoneNumber}
							</span>
						</div>
						<span className={styles.snils}>
							СНИЛС &nbsp;
							<span>{snils}</span>
						</span>

						<div
							className={cn(styles.documentTypeInfo, {
								[styles.fullTypeInfo]: isFull,
							})}
						>
							{item?.legalAuthorityRecord ? (
								<span>
									{item?.legalAuthorityRecord?.type?.name}
									{item?.legalAuthorityRecord
										?.durationStop && (
										<>
											{" "}
											до{" "}
											{formatDocumentDate(
												item?.legalAuthorityRecord
													?.durationStop
											)}
										</>
									)}
								</span>
							) : (
								<span>Документ не загружен</span>
							)}
						</div>
					</div>

					{isFull && (
						<form
							className={styles.representativeForm}
							onSubmit={handleSubmit(onSubmitHandler)}
						>
							<Select
								isFormSelect={true}
								options={documentTypes}
								name="documentType"
								label="Тип документа"
								placeholder="Выберите тип документа"
								className={styles.select}
								variant="bordered-green"
								register={register}
								registerObj={{
									required: "Поле обязательно для заполнения",
								}}
							/>

							<div className={styles.periods}>
								<label>Период действия</label>
								<div className={styles.periodsWrapper}>
									<FormCalendar
										name="typePeriodStart"
										width={160}
										register={register}
										setValue={setValue}
										// registerObj={{
										// 	required:
										// 		"Поле обязательно для заполнения",
										// }}
									/>{" "}
									-{" "}
									<FormCalendar
										name="typeperiodEnd"
										width={161}
										register={register}
										setValue={setValue}
										// registerObj={{
										// 	required:
										// 		"Поле обязательно для заполнения",
										// }}
									/>
								</div>
							</div>
							<div className={styles.scsnsBox}>
								<label>Сканы</label>
								<div className={styles.files}>
									{item?.legalAuthorityRecord?.file && (
										<span className={styles.documentName}>
											Документ загружен:{" "}
											{item?.legalAuthorityRecord?.file}
										</span>
									)}
									<FileField
										name="documentFile"
										register={register}
										fileTitle="Документ"
										fileText={
											item?.legalAuthorityRecord?.file
												? "Загрузить новый документ"
												: "Не загружена"
										}
										icon={<AddFile />}
										setValue={setValue}
										isMultiply={false}
										registerObj={{
											required:
												"Поле обязательно для заполнения",
										}}
									/>
									{item?.legalAuthorityRecord?.sigFile && (
										<span className={styles.documentName}>
											Документ загружен:{" "}
											{
												item?.legalAuthorityRecord
													?.sigFile
											}
										</span>
									)}
									<FileField
										name="signatureFile"
										register={register}
										fileTitle="Подпись .sig"
										fileText={
											item?.legalAuthorityRecord?.sigFile
												? "Загрузить новый документ"
												: "Не загружена"
										}
										icon={<AddFile />}
										setValue={setValue}
										isMultiply={false}
										// registerObj={{
										// 	required:
										// 		"Поле обязательно для заполнения",
										// }}
									/>
								</div>
							</div>
							{uuid && (
								<Button
									variant="outline-green"
									type="submit"
									disabled={!watchedDocumentFile}
								>
									Сохранить
								</Button>
							)}
						</form>
					)}
				</RepresentativeBox>
			);
		}

		if (companyData.length > 0) {
			return (
				<RepresentativeBox
					handleRemove={() => handleRemove(companyData)}
				>
					<div className={styles.companyWrapper}>
						<span className={styles.companyTitle}>
							Юрлицо - представитель
						</span>
						<span>{item.companyName}</span>
						<span>{item.inn}</span>
					</div>
				</RepresentativeBox>
			);
		}

		if (physicalData.length > 0) {
			return (
				<RepresentativeBox
					handleRemove={() => handleRemove(physicalData)}
					className={styles.companyBox}
				>
					<div className={styles.companyWrapper}>
						{item && (
							<>
								<span className={styles.name}>
									{item.client}
								</span>
								<div className={styles.documentsBox}>
									<span>СНИЛС</span>
									<span>{item.snils}</span>
								</div>
							</>
						)}
					</div>
				</RepresentativeBox>
			);
		}

		return null;
	}, [
		data,
		companyData,
		physicalData,
		isFull,
		firstName,
		lastName,
		patronymic,
		item,
		snils,
		formatDocumentDate,
		handleSubmit,
		onSubmitHandler,
		documentTypes,
		register,
		setValue,
		uuid,
		watchedDocumentFile,
		handleRemove,
	]);

	return <>{listVariantRender}</>;
};
