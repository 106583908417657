import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Context } from "../../../App";
import { CreateObjectForm } from "../../../components/Forms";
import { ObjectsList } from "../../../components/Lists";
import { ModalWindow } from "../../../components/ModalWindow";
import { ALL_REAL_OBJECT } from "../../../services/queries/queries/object";
import styles from "./Objects.module.scss";
import { Pagination } from "../../../components/Pagination/ui/Pagination";
import { FilteredSearch } from "../../../components/FilteredSearch";
import { Preloader } from "../../../components/Preloader";

export const Objects = () => {
  const [query, setQuery] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const { showModal, setShowModal } = useContext(Context);
  const [currentPage, setCurrentPage] = useState(0);
  const [objects, setObjects] = useState([]);
  const [first, setFirst] = useState(10); // Количество объектов на странице
  const [offset, setOffset] = useState(currentPage * first);

  const [allObjects, { data, loading, refetch, error }] = useLazyQuery(
    ALL_REAL_OBJECT,
    {
      variables: { offset: offset, first: first },
    }
  );

  useEffect(() => {
    if (data && data.allRealObject) {
      setObjects(extractObjectData(data));
    }
  }, [data]);

  const extractObjectData = (data) => {
    if (!data) return [];
    return data.allRealObject.edges.map((edge) => edge.node);
  };

  useEffect(() => {
    setOffset(currentPage * first);
  }, [currentPage, first]);

  // useEffect(() => {
  //   setLoadingStatus(loading);
  // }, [loading]);

  const totalObjects = data ? data.allRealObject.totalCount : 0;
  const totalPages = Math.ceil(totalObjects / first);

  return (
    <>
      {!loadingStatus ? (
        <ul>
          <FilteredSearch
            response={allObjects}
            className={styles.basesSearch}
            placeholder={"Адрес, кадастровый №"}
            setCurrentPage={setCurrentPage}
            setFirst={setFirst}
          />
          <ObjectsList objectData={objects} />
          <Pagination
            page={currentPage}
            setPage={setCurrentPage}
            totalPages={[...Array(totalPages).keys()]}
          />
          {currentPath === "/objects/" && showModal && (
            <ModalWindow
              show={showModal}
              setShowModal={setShowModal}
              dialogClassName={styles.objectsModal}
            >
              <CreateObjectForm
                objectData={objects}
                hideModal={setShowModal}
                setShowModal={setShowModal}
              />
            </ModalWindow>
          )}
        </ul>
      ) : (
        <Preloader />
      )}
    </>
  );
};
