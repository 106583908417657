import { gql } from "@apollo/client";

export const UPDATE_SIGNATURE = gql`
  mutation UpdateSignature($consumerUuid: UUID!){
  updateSignatureActive(consumerUuid: $consumerUuid){
    signature{
      consumerUuid
      connector
    }
  }
}
`