import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    representatives:  []
};

const legalPersonSlice = createSlice({
	name: "legalPerson",
	initialState: initialState,
	reducers: {
		setRepresentatives(state, action) {
			return {
				...state,
				representatives: action.payload,
			};
		},
		setRepresentative(state, action) {
			const newRepresentatives = state.representatives.map(item => {
				if(item.uuid === action.payload?.uuid || item.physicalPerson?.uuid === action.payload?.uuid) {
					
					return {
						...item,
						...action.payload.data
					}
				} else {
					return item;
				}
			})

			return {
				...state,
				representatives: newRepresentatives,
			};
		},
	},
	selectors: {
		selectRepresentatives: (state) => {
			return state.representatives;
		},
	},
});

export const { setRepresentatives, setRepresentative} = legalPersonSlice.actions;
export const { selectRepresentatives } = legalPersonSlice.selectors;
export default legalPersonSlice.reducer;
