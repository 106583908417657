import { gql } from "@apollo/client";

export const OBJECT_RIGHT = gql`
  query ObjectRight{
  allObjectRight{
    edges{
      node{
        uuid
        title
      }
    }
  }
}

`