import { gql } from "@apollo/client";

export const ADD_REPRESENTATIVE = gql`
  mutation AddRepresentative(
  $representatives: [UUID]!
	$uuid: UUID!
){
  addRepresentativesToLegalPerson(representatives: $representatives, uuid: $uuid){
    legalPerson{
      uuid
    }
  }
}
`