import { CREATE_STATEMENT_APPLICATION } from "../../../../services/queries/mutations/applications/statement/statement";
import { toast } from "react-toastify";
import { Button } from "../../../../components/Button";
import styles from './CreateStatement.module.scss';
import { useMutation } from "@apollo/client";


export const CreateStatement = ({applicationSended, uuid, applicationInfo, applicationId}) => {
  const [createStatementApplication] = useMutation(
    CREATE_STATEMENT_APPLICATION,
    {
      // refetchQueries: [APPLICATION_INFO],
      onError(err) {
        toast.error(err.message);
      },
    }
  );

  return (
    <>
      {applicationSended && (
        <Button
          variant="outline-green"
          className={styles.addBtn}
          type="button"
          // disabled={isDocumentSigned === false}
          onClick={() => {
            createStatementApplication({
              variables: {
                applicationUuid: uuid,
              },
            }).then(() => {
              if(applicationInfo && applicationId){
                applicationInfo({
                  variables: {
                    id: applicationId
                  }
                })
              }
            });
          }}
        >
          <span>Сформировать заявление</span>
        </Button>
      )}
    </>
  );
};
