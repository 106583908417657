import { createSlice } from "@reduxjs/toolkit";

const initialState = { token: "", refreshToken: "" };

const userSlice = createSlice({
	name: "user",
	initialState: initialState,
	reducers: {
		setTokens(state, action) {
			localStorage.setItem("accessToken", action.payload.token);
			localStorage.setItem("refreshToken", action.payload.refreshToken);

			return {
				...state,
				token: action.payload.token,
				refreshToken: action.payload.refreshToken,
			};
		},
        setUserData(state, action) {
			return {
				...state,
				...action.payload,
			};
		},
		setDocument(state, action) {
			return {
				...state,
				document: action.payload.token,
			};
		},
	},
	selectors: {
		selectToken: (state) => {
			const token = localStorage.getItem("accessToken");
			if (token) {
				return token;
			}
			return state.token;
		},
		selectRefreshToken: (state) => {
			const refreshToken = localStorage.getItem("refreshToken");
			if (refreshToken) {
				return refreshToken;
			}
			return state.refreshToken;
		},
		selectDocument: (state) => {
			return state.document;
		},
	},
});

export const { setTokens, setDocument, setUserData } = userSlice.actions;
export const { selectToken, selectRefreshToken, selectDocument } = userSlice.selectors;
export default userSlice.reducer;
