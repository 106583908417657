import React from "react";
import { ModalWindow } from "../../../../../ModalWindow";
import { Button } from "../../../../../Button";
import styles from "./DeleteApplication.module.scss";
import { DELETE_APPLICATION } from "../../../../../../services/queries/mutations/applications/deleteApplication";
import { ALL_APPLICATIONS } from "../../../../../../services/queries/queries/allApplications";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

export const DeleteApplication = ({
  deleteShow,
  setDeleteShow,
  uuid,
}) => {
  const navigate = useNavigate();

  const [deleteApplication] = useMutation(DELETE_APPLICATION, {
    awaitRefetchQueries: true,
    refetchQueries: [ALL_APPLICATIONS],
    onCompleted: () => {
      navigate("/applications/");
    }
  });

  function applicationDeleteHandler() {
    if (uuid) {
      deleteApplication({
        variables: {
          uuid,
        },
      });

    } else {
      navigate("/applications/");
    }
  };

  return (
    <ModalWindow show={deleteShow} setShowModal={setDeleteShow}>
      <h2 className={cn(styles.title, styles.modalTitle)}>
        Вы действительно хотите удалить обращение?
      </h2>
      <div className={styles.deleteButtonBlock}>
        <Button
          type="button"
          variant="green"
          className={styles.edsBtn}
          onClick={() => {applicationDeleteHandler()}}
        >
          Да
        </Button>
        <Button
          type="button"
          variant="red"
          className={styles.edsBtnNon}
          onClick={() => {setDeleteShow(false)}}
        >
          Нет
        </Button>
      </div>
    </ModalWindow>
  );
};
