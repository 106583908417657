import { useCallback, useState } from "react";
import { BaseFinderTemplate } from "../../../../../BaseFinderTemplate/BaseFinderTemplate";
import { Button } from "../../../../../Button";
import styles from "./ObjectBaseFinder.module.scss";
import { useQuery } from "@apollo/client";
import { ALL_REAL_OBJECT } from "../../../../../../services/queries/queries/object";

const ListRow = ({ data, onAdd }) => {
  console.log(data);
  return (
    <li className={styles.row}>
      <div className={styles.col}>
        <span>Адрес {data?.node?.address?.value}</span>
        <span>
          {data?.node?.type?.title} {data?.node?.buildingArea} м²
        </span>
      </div>

      <div className={styles.col}>
        <strong>{data?.node?.cadastralNumber}</strong>
        <span className={styles.knLabel}>КН объекта</span>
      </div>

      <Button variant="green" onClick={() => onAdd(data)}>
        Добавить
      </Button>
    </li>
  );
};

export const ObjectBaseFinder = ({ setObjectData, setShow }) => {
  const [search, setSearch] = useState("");
  const onAdd = useCallback((value) => {
    setObjectData(value);
    setShow(false);
  }, []);

  const { data } = useQuery(ALL_REAL_OBJECT, {
    variables: {
      search: search,
    },
  });

  const array = data?.allRealObject?.edges;

  return (
    <BaseFinderTemplate
      inputLabel="Поиск по базе объектов"
      isEmpty={false}
      emptyText="Начните вводить адрес, КН объекта"
      withList={true}
      ListRow={ListRow}
      data={search !== "" ? array : []}
      onAdd={onAdd}
      loadMore={() => {}}
      setSearch={setSearch}
    />
  );
};
