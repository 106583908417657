import React, { useState, useEffect } from "react";
import styles from "./Loader.module.scss";

export const Loader = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 1
      );
    }, 150); // Обновление каждые 150 мс для 15-секундного цикла

    return () => clearInterval(interval);
  }, []);
  const [seconds, setSeconds] = useState(15);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds === 1 ? 15 : prevSeconds - 1));
    }, 995);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.progressCircle}>
      <span className={styles.progressTimer}>{seconds}</span>
      <svg viewBox="0 0 36 36" className={styles.circularChart}>
        <path
          className={styles.circleBg}
          d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className={styles.circle}
          strokeDasharray={`${progress}, 100`}
          d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
        />
      </svg>
    </div>
  );
};
