import {useMemo} from "react";

/* 
Компонент, который выводит статус документа, который должен подписать клиент

Пропсы:

status: Наименование текущего статуса документа, которое передается из родительского компонента (строка)

className - передача дополнительных стилей из компонента где вызывается инпут (строка)

fileUrl - ссылка на скачиваение sig файла, которая передается в фунцкию downloadExistFiles


*/

export const SignatureStatus = ({ status, className, fileUrl, fileName }) => {


  const downloadSigFile = async (file, name) => {
    const defaultExtension = '.sig'; // Установите ваше желаемое расширение по умолчанию

    if (file instanceof Blob || file instanceof File) {
      // Если файл загружен и является объектом Blob или File
      const url = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      // Убедитесь, что имя файла содержит расширение
      link.download = name ? name + (name.endsWith(defaultExtension) ? '' : defaultExtension) : '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else if (typeof file === "string") {
      try {
        // Используем fetch для загрузки файла
        const token = localStorage.getItem("accessToken");
        const response = await fetch(file, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
        const blob = await response.blob(); // Получаем файл в формате Blob
        const url = URL.createObjectURL(blob);
        // Создаем ссылку и эмулируем клик для скачивания файла
        const link = document.createElement("a");
        link.href = url;
        // Убедитесь, что имя файла содержит расширение
        link.download = name ? name + (name.endsWith(defaultExtension) ? '' : defaultExtension) : '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Failed to download file:", error);
      }
    } else {
      console.error("Unsupported file type for download");
    }
  };


  const statusVariantRender = useMemo(() => {
    if (status === undefined) {
      return null;
    }

    switch (status) {
      case "COMPLETE":
        return <span className={className} style={{cursor: 'pointer'}} onClick={() => downloadSigFile(fileUrl, fileName)}>Скачать файл .sig</span>;
      case "WAITING":
        return <span className={className}>Ожидает подписания</span>;
      // case "WAITING_SEND":
      //   return <span className={className}>Ожидает подписания</span>;
      case "ERROR":
        return <span className={className}>Ошибка</span>;
      case "UPLOAD_ERROR":
        return <span className={className}>Ошибка отправки</span>;
      case "REJECTED":
        return <span className={className}>Отменено</span>;
    }
  }, [status]);

  return <>{statusVariantRender}</>;
};
