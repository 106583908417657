import { FormSelect } from "react-bootstrap"
import { useForm } from "react-hook-form"
import styles from './SidebarSelect.module.scss'
import { useState, useCallback, useEffect } from "react"
import { Select } from "../../Select/ui/Select"

export const SidebarSelect = ({clientData, setApplication}) => {
  const {register, watch} = useForm()
  const [options, setOptions] = useState([])

  const changeVariant = watch('clientApplications')

  useEffect(() => {
    // setOptions([]);

    if (clientData?.physicalPersonProfile?.participants) {
      clientData?.physicalPersonProfile?.participants?.forEach(
        (participant) => {
          setOptions(options => {
            const newOption = {
              value: participant?.application?.uuid || '',
              title: participant?.application?.realObject?.address?.value || ''
            };
            if (!options?.some(option => option?.title === newOption?.title)) {
              return [...options, newOption];
            }
            return options;
          });
        }
      );
    }
  }, [clientData]);

  useEffect(() => {
    if(changeVariant){
      setApplication(changeVariant)
    }
  }, [changeVariant])

  return(
    <Select 
      isFormSelect={true}
      name='clientApplications'
      variant={'bordered'}
      options={options}
      register={register}
      className={styles.sidebarSelect}
    />
  )
}