import { useRef } from "react";
import { BasketIcon } from "../../../assets/icons/BasketIcon";
import styles from "./RepresentativeBox.module.scss";
import cn from "classnames";

export const RepresentativeBox = ({ children, isFull, setIsFull, handleRemove, className }) => {
  const boxRef = useRef(null)

  return (
    <div
      className={cn(styles.wrapper, className, {
        [styles.fullWrapper]: isFull,
      })}
      ref={boxRef}
    >
      {children}

      <button onClick={handleRemove}>
        <BasketIcon className={styles.removeItem}/>
      </button>
    </div>
  );
};
