export const downloadFileFromUrl = (url, fileName) => {
  const token = localStorage.getItem('accessToken')


  if (fileName) {
    function removeTrailingSlash(str) {
      if (str.endsWith("/")) {
        return str.slice(0, -1);
      }
      return str;
    }
    fetch(url, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Непредвиденная ошибка");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = removeTrailingSlash(url);
        a.download = fileName;
        a.target = "_blank";
        a.rel = "noreferrer";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Ошибка скачивания:", error);
      });
  }
};