import { gql } from "@apollo/client";

export const GET_ADDRESSES = gql`
  query GetAddresses($address: String!) {
    getAddresses(address: $address) {
      region
      regionType
      district
      locality
      house
      structure
      okato
      oktmo
      index
      city
      street
      fias
      kladr
      typeStreet
      block
      apartment
      flatType
      country
      value
      regionKladrId
      settlementTypeFull
      areaWithType
      settlementWithType
      kladr
      fias
    }
  }
`;
