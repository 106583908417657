import styles from "./FormTemplate.module.scss";

export const FormTemplate = ({
  children,
  formTitle,
  handleSubmit,
  item,
  dataArr,
  reset,
  setShowModal,
  hideModal,
  dataRep,
  id,
}) => {
  const onSubmitHandle = (data) => {
    if (!item) {
      dataArr.unshift(data);

      reset();
      setShowModal(false);
    } else {
      for (let key in item) {
        if (item[key] !== data[key]) {
          item[key] = data[key];
        }
      }

      hideModal(null);
    }

    if (
      item &&
      item.representatives?.every((value, index) => value !== dataRep[index])
    ) {
      if (dataRep) {
        item.representatives = dataRep;
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandle)} id={id}>
        {formTitle && <h3 className={styles.formTitle}>{formTitle}</h3>}
        <div className={styles.formInner}>{children}</div>
      </form>
    </>
  );
};
