import {gql} from "@apollo/client";

export const CREATE_AUTHORITY_RECORD = gql`
  mutation CreateLegalAuthorityRecord(
    $durationStart: Date
    $durationStop: Date
    $file: Upload!
    $sigFile: Upload
    $typeUuid: UUID!
  ) {
    createLegalAuthorityRecord(
      durationStart: $durationStart
      durationStop: $durationStop
      file: $file
      typeUuid: $typeUuid
      sigFile: $sigFile
    ) {
      legalAuthorityRecord {
        uuid
        id
      }
    }
  }
`;

export const LEGAL_AUTHORITY_RECORD_TYPES = gql`
  query LegalAuthorityRecordTypes {
    legalAuthorityRecordTypes {
      edges {
        node {
          name
          uuid
          id
        }
      }
    }
  }
`;

export const ADD_AUTHORITY_LEGAL_RECORD_TO_REPRESENTATIVE = gql`
	mutation AddAuthorityLegalRecordToRepresentative(
		$physicalPersonUuid: UUID!
		$legalPersonUuid: UUID!
		$legalAuthorityRecordUuid: UUID!
	) {
		addAuthorityLegalRecordToRepresentative(
			legalAuthorityRecordUuid: $legalAuthorityRecordUuid
			legalPersonUuid: $legalPersonUuid
			physicalPersonUuid: $physicalPersonUuid
		) {
			representative {
				physicalPerson {
					uuid
				}
			}
		}
	}
`;