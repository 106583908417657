import { gql } from "@apollo/client";

export const CREATE_APPLICATION_PARTICIPANT = gql`
  mutation createApplicationParticipant(
    $applicationUuid: UUID!
    $applicantUuid: UUID
    $legalPersonUuid: UUID
    $legalAuthorityRecordUuid: UUID
    $participantType: String!
    $physicalPersonUuid: UUID
    $representativeUuid: UUID
    $role: UUID!
    $shareDenominator: Int
    $shareNumerator: Int
  ) {
    createParticipant(
      applicationUuid: $applicationUuid
      applicantUuid: $applicantUuid
      legalPersonUuid: $legalPersonUuid
      participantType: $participantType
      physicalPersonUuid: $physicalPersonUuid
      representativeUuid: $representativeUuid
      role: $role
      shareDenominator: $shareDenominator
      shareNumerator: $shareNumerator
      legalAuthorityRecordUuid: $legalAuthorityRecordUuid
    ) {
      participant {
        id
      }
    }
  }
`;
export const READ_APPLICATION = gql`
  mutation ReadApplication($ticketId: UUID!) {
    markNotificationAsRead(ticketId: $ticketId) {
      success
    }
  }
`;
