export const Logo = () => {
  return (
    <svg
      width="296"
      height="64"
      viewBox="0 0 296 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M139.157 13.3788H143.198L145.02 11.8764C146.026 11.0437 146.888 10.3288 146.933 10.2926C146.987 10.2473 145.972 10.2111 144.598 10.2201H142.183L139.157 13.3788Z"
        fill="#394A4A"
      />
      <path
        d="M80.7173 14.2476C78.4454 14.5372 76.6674 15.3065 75.6887 16.4288C74.8715 17.37 74.3686 18.8996 74.4674 20.1757C74.5842 21.5785 74.9793 22.4564 75.9491 23.4339C76.4699 23.9498 76.8111 24.2032 77.4038 24.4928C79.7924 25.6784 83.465 25.787 86.5001 24.7643C87.7573 24.348 87.6316 24.5652 87.6316 22.737V21.1622L87.2814 21.3342C86.2936 21.841 84.2193 22.4293 82.9083 22.565C82.1899 22.6465 80.7532 22.4745 80.1336 22.2392C78.9752 21.8048 78.3377 20.936 78.3556 19.8137C78.3556 19.0987 78.5172 18.6914 78.9752 18.1755C79.3883 17.714 79.8103 17.4968 80.6903 17.2614C82.3336 16.8451 84.8569 17.2162 86.994 18.1846C87.3083 18.3294 87.5777 18.4471 87.5957 18.4471C87.6136 18.4471 87.6316 17.7411 87.6316 16.8723V15.2975L87.0299 15.0712C85.4226 14.4829 84.3271 14.2838 82.4683 14.2386C81.6781 14.2205 80.8879 14.2295 80.7173 14.2476Z"
        fill="#394A4A"
      />
      <path
        d="M123.236 14.3834C119.725 14.836 117.462 16.7999 117.291 19.5332C117.112 22.4022 119.213 24.5653 122.778 25.1988C123.891 25.3979 126.118 25.3979 127.187 25.1988C130.518 24.5743 132.485 22.7733 132.673 20.1758C132.934 16.6461 129.728 14.2386 124.852 14.2929C124.313 14.302 123.586 14.3472 123.236 14.3834ZM126.091 17.0986C127.331 17.2886 128.363 17.9855 128.731 18.8725C128.929 19.3703 128.902 20.3206 128.669 20.8817C128.175 22.0311 126.944 22.6104 124.987 22.6104C123.371 22.6104 122.374 22.2664 121.673 21.47C121.242 20.9722 121.09 20.5197 121.099 19.7323C121.108 19.0626 121.422 18.3204 121.871 17.9403C122.769 17.171 124.439 16.8452 126.091 17.0986Z"
        fill="#394A4A"
      />
      <path
        d="M27.0639 16.1573C27.7374 16.999 29.0753 18.6643 30.0451 19.868L31.7962 22.0673L30.9162 23.1714C30.4313 23.7869 29.9015 24.4566 29.7308 24.6648L29.4255 25.054H33.4664L37.2199 20.2391C39.2942 17.5873 41.117 15.2432 41.2787 15.035L41.575 14.6459H37.6599L35.828 16.9628C34.8223 18.2299 33.9692 19.2616 33.9333 19.2435C33.8884 19.2254 33.0443 18.1846 32.0476 16.9266L30.2337 14.6459H25.8427L27.0639 16.1573Z"
        fill="#394A4A"
      />
      <path
        d="M42.8232 25.054H46.415V21.2527H52.7008V25.054H56.2926V14.6459H52.7008V18.3566H46.415V14.6459H42.8232V25.054Z"
        fill="#394A4A"
      />
      <path
        d="M58.8967 25.054H61.8421L65.3351 22.1035L68.8192 19.153L68.8462 22.1035L68.8641 25.054H72.456V14.6459H69.4119L65.9907 17.542C64.1049 19.1349 62.5245 20.4382 62.4796 20.4382C62.4347 20.4382 62.3988 19.2616 62.3988 17.542V14.6459H58.8967V25.054Z"
        fill="#394A4A"
      />
      <path
        d="M89.0683 17.542H93.648V25.054H97.2398V17.542H101.73V14.6459H89.0683V17.542Z"
        fill="#394A4A"
      />
      <path
        d="M103.166 25.054H106.758V22.5198H109.883C112.496 22.5198 113.089 22.4927 113.502 22.375C114.903 21.9496 115.828 21.0627 116.241 19.7232C116.411 19.1802 116.402 17.9764 116.232 17.4153C115.944 16.465 115.208 15.569 114.373 15.1346C113.466 14.673 113.197 14.6459 107.997 14.6459H103.166V25.054ZM111.742 17.18C112.2 17.2796 112.721 17.8316 112.811 18.2932C112.945 19.0173 112.487 19.7956 111.805 19.9857C111.616 20.0309 110.458 20.0762 109.12 20.0762H106.758V18.6462C106.758 17.8588 106.785 17.18 106.821 17.1528C106.911 17.0533 111.338 17.0804 111.742 17.18Z"
        fill="#394A4A"
      />
      <path
        d="M134.236 25.054H135.745L137.244 25.0449L140.701 22.1125C142.605 20.5015 144.185 19.1802 144.23 19.1711C144.266 19.1711 144.293 20.4925 144.293 22.1125V25.054H147.795V14.6459H144.787L141.33 17.5782L137.873 20.5015L137.846 17.5782L137.828 14.6459H134.236V25.054Z"
        fill="#394A4A"
      />
      <path
        d="M11.2914 14.3783L20.2992 7.44922V24.772L11.2914 31.7011V14.3783Z"
        fill="#7CC36D"
      />
      <path
        d="M0.204834 7.44865L9.21269 0.519531V17.8423L0.204834 24.7715V7.44865Z"
        fill="#7CC36D"
      />
    </svg>
  );
};
