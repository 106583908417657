import { createAsyncThunk } from "@reduxjs/toolkit";

export const getClientDocuments = createAsyncThunk(
  'contractSlice/getClientDocuments',
  async (uuid, thunkApi) => {
    try {
      const response = await fetch(`https://api.unireg.redfoxlab.ru/api/physical-persons/${uuid}/documents/`)

      if(!response.ok){
        throw new Error('Не удалось загрузить документы')
      }

      const data = await response.json()

      return data


    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  }

)