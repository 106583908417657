import { useState, createContext } from "react";
import { useLocation } from "react-router-dom";
import { AppRouter } from "./components/AppRouter";
import { Container } from "./components/Container";
import { Header } from "./components/Header";
import ApolloAppProvider from "./services/ApolloProvider";
import { ToastContainer } from "react-toastify";
export const Context = createContext(null);

function App() {
  const location = useLocation();
  const withoutMainContainer =
    location.pathname === "/login" ||
    location.pathname === "/applications/new" ||
    location.pathname === "/applications/edit" ||
    location.pathname.slice(0, -39) === "/reset-password/" ||
    location.pathname.slice(0, -39) === "/register/" ||
    location.pathname === "/login/" ||
    location.pathname.includes("/client-cabinet/") ||
    location.pathname.includes("/application-documents/") ||
    location.pathname === "/client-documents/" ||
    location.pathname === "/contract/" ||
    location.pathname === "/eds-info/" ||
    location.pathname === "/duty/" ||
    location.pathname === "/reestr-history/" ||
    location.pathname.includes("/copy-application/") ||
    location.pathname === "/reset-client-password/" ||
    location.pathname === "/object-info/" ||
    location.pathname === "/hot-document/" ||
    location.pathname.includes("/additional/") ||
    location.pathname.includes("/applications/edit/") ||
    location.pathname === "/applications/new/";
  const [tab, setTab] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  return (
    <ApolloAppProvider>
      <Context.Provider
        value={{
          tab,
          setTab,
          tabIndex,
          setTabIndex,
          showModal,
          setShowModal,
        }}
      >
        {!withoutMainContainer && <Header />}
        {withoutMainContainer ? (
          <AppRouter />
        ) : (
          <Container>
            <AppRouter />
          </Container>
        )}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Context.Provider>
    </ApolloAppProvider>
  );
}

export default App;
