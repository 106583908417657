import { Input } from "../../../../components/Input";
import styles from "./ApplicationsSearch.module.scss";
import { useDebouncedCallback } from "use-debounce";
import { useEffect } from "react";

/* 
Компонент для фильтрации заявок по значению, переданному в поиск

Пропсы:

value - перменная для хранения значения инпута (обычный инпут) (строка)

setValue - функция для управления значением инпута, помогает сделать обычный инпут управляемым

Функция debounce позволяет не отправлять запрос после каждого введенного символа, а только через 500 мс, после того как символы перестали набираться

*/

export const ApplicationsSearch = ({
  value,
  setValue,
  setCurrentPageFilter,
  setCurrentPage,
}) => {
  const currentValue = sessionStorage.getItem("searchValue");

  const debounce = useDebouncedCallback((query) => {
    if (query) {
      sessionStorage.setItem("searchValue", query);
    }

    if (query === "") {
      sessionStorage.removeItem("searchValue");
    }
  }, 500);

  useEffect(() => {
    debounce(value);
  }, [value]);

  useEffect(() => {
    if (currentValue) {
      setValue(currentValue);
      setCurrentPageFilter(0);
      setCurrentPage(0);
    }
  }, [currentValue]);

  return (
    <Input
      variant="bordered-green"
      placeholder="ФИО,адрес,КН, КУВД,ИНН,название ЮЛ"
      value={value}
      setValue={setValue}
      className={styles.applicationsSearch}
      size="short"
    />
  );
};
