import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { REFRESH_TOKEN } from "../services/queries";
import { selectRefreshToken } from "../redux/slices/user";
import { setTokens } from "../redux/slices/user";
const useRefreshToken = () => {
	const dispatch = useDispatch();
	const refreshToken = useSelector(selectRefreshToken);
	const [mutateFunction, { data }] = useMutation(REFRESH_TOKEN, {
		onError(err) {
			console.log(err?.message);
		},
	});

	useEffect(() => {
		if (data?.refreshToken) {
			dispatch(setTokens(data.refreshToken));
		}
	}, [data?.refreshToken]);

	const refreshTokenHandle = () => {
		mutateFunction({
			variables: {
				refreshToken: refreshToken,
			},
		});
	};

	return { refreshTokenHandle };
};

export default useRefreshToken;
