import { useNavigate } from "react-router-dom";
import styles from "./ReestrHistory.module.scss";
import { Button } from "../../../components/Button";

export const ReestrHistory = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.reestrWrapper}>
      <Button
        className={styles.reestrPrevBtn}
        onClick={() => navigate(`/client-cabinet/`)}
      >
        <span>{"<"}</span>
        Кабинет
      </Button>
      <div className={styles.reestrContainer}>
      <div className={styles.reestInfo}>
        <h3>Заявление</h3>
        <span className={styles.documentStatus}>Статус: Отправлено</span>

        <div className={styles.documentsPath}>
          <span>11 сентября 2024 13:43 - Сформировано</span>
          <span>11 сентября 2024 14:00 - Отправлено - <button>скачать отправленный пакет</button></span>
        </div>
      </div>
    </div>
    </div>
    
  );
};
