import styles from "./PhysicalDocumentsUpload.module.scss";
import { UploadField } from "./UploadField/UploadField";


export const downloadFile = async (file, name) => {
  if (file instanceof Blob || file instanceof File) {
    // Если файл загружен и является объектом Blob или File
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } else if (typeof file === "string") {
    try {
      // Используем fetch для загрузки файла
      const token = localStorage.getItem("accessToken");
      const response = await fetch(file, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }
      const blob = await response.blob(); // Получаем файл в формате Blob
      const url = URL.createObjectURL(blob);

      // Создаем ссылку и эмулируем клик для скачивания файла
      const link = document.createElement("a");
      link.href = url;

      link.download = name || "";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Очищаем объект URL
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  } else {
    console.error("Unsupported file type for download");
  }
};

export const PhysicalDocumentsUpload = ({
  uuid,
  documentsData,
  id,
  personLoading,
  signatureActive
}) => {

  return (
    <>
      <h3 className={styles.uploadTitle}>Загрузка документов</h3>
      <div className={styles.uploadWrapper}>
        <UploadField
          documentsData={documentsData}
          id={id}
          downloadFile={downloadFile}
          uuid={uuid}
          personLoading={personLoading}
          signatureActive={signatureActive}
        />
      </div>
    </>
  );
};
