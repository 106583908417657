import { gql } from "@apollo/client";

export const APPLICATION_DOCUMENTS_TOKEN_SIGN = gql`
  mutation ApplicationDocumentsTokenSign(
    $base64File: String!
    $documentUuid: UUID!
    $participantUuid: UUID!
  ) {
    documentSignParticipantToken(
      base64File: $base64File
      documentUuid: $documentUuid
      participantUuid: $participantUuid
    ) {
      documentSignature {
        id
        person {
          firstName
        }
        presignedSignatureFile
        state
      }
      errors
    }
  }
`;
