import { useEffect } from "react";
import { toast } from "react-toastify";

export const Error = ({ error, errorText }) => {

  useEffect(() => {
    if(error){
      toast.error(errorText);
    }
  }, [error, errorText])

  return (
    <></>
  );
};
