import { useForm } from "react-hook-form";
import { Select } from "../../../../components/Select/ui/Select";
import { useEffect, useState } from "react";
import styles from "./ApplicationsStatusFilter.module.scss";
import { useQuery } from "@apollo/client";
import { GET_ALL_STATUSES } from "../../../../services/queries/queries/applications/allStatuses";

export const ApplicationsStatusFilter = ({
  isClear,
  setStatus,
  status,
  setCurrentPageFilter,
  setCurrentPage,
}) => {
  const { data: allStatuses } = useQuery(GET_ALL_STATUSES);
  const [options, setOptions] = useState([
    { title: "Статус заявки", value: "" },
  ]);
  useEffect(() => {
    if (allStatuses && allStatuses?.applicationStatuses) {
      allStatuses?.applicationStatuses.forEach((item) => {
        setOptions((prev) => [
          ...prev,
          { value: item.value, title: item.title },
        ]);
      });
    }
  }, [allStatuses]);

  useEffect(() => {
    if (status) {
      setCurrentPageFilter(0);
      setCurrentPage(0);
    }
  }, [status]);
  return (
    <Select
      variant="bordered-green"
      size="short"
      value={status}
      setValue={setStatus}
    >
      {options.map((item, index) => (
        <option key={index} value={item.value}>
          {item.title}
        </option>
      ))}
    </Select>
  );
};
