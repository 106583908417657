import { useState } from "react";
import styles from "./Sidebar.module.scss";
import cn from 'classnames'

/* 
Компонент для вывода сайдбара

Пропсы:

children - то, что будет отображаться внутри сайдбара (компонент, текст, картинка, разметка)

ref - определение текущего файла в разметке при необходимости (компонент)

onClick - функция, которая может запускаться при клике на сайдбар

className - дополнительные стили, которые могут передаваться из компонента, где вызывается сайдбар (строка)

*/

export const Sidebar = ({children, ref, onClick, className}) => {
  

  return (
    <div
      className={cn(styles.sidebarWrapper, className)}
      ref={ref}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
