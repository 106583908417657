import { gql } from "@apollo/client";

export const ALL_REPRESENTATIVES_BY_COMPANY = gql`
  query GetCompanyRepresentedByCompany(
    $search: String
    $first: Int
    $offset: Int
  ) {
    allLegalPersonRepresentedByLegalPerson(
      search: $search
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          id
          uuid
          legalPerson {
            fullName
            uuid
          }

          representative {
            lastName
            firstName
            patronymic
            uuid
          }

          representedLegalPerson {
            fullName
            uuid
            representatives {
              legalAuthorityRecord {
                type {
                  uuid
                  name
                }
                uuid
                sigFile
                presignedUrlSigFile
                presignedUrlFile
                id
                file
                durationStart
                durationStop
              }
              physicalPerson {
                firstName
                id
                lastName
                patronymic
                placeOfBirth
                snils
                uuid
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;
