import { gql } from "@apollo/client";

  export const SEND_REQUEST_APP_SERVICE = gql`
      mutation sendRequestsApplicationService(
          $applicationUuid: UUID!
      ) {
          sendRequestsApplicationService(
              applicationUuid: $applicationUuid
          ) {
                message
                success
          }
      }
  `;
  