import { gql } from "@apollo/client";

export const CREATE_COMPANY = gql`
  mutation CreateCompany(
    $inputAddress: AddressInput
		$inputLegal: CreateLegalPersonInput
  ) {
    createLegalPerson(
      inputAddress:  $inputAddress
			inputLegal: $inputLegal
    ) {
      legalPerson {
        id
        uuid
      }
    }
  }
`;
