import styles from './StatusLabel.module.scss'
import cn from 'classnames'

export const StatusLabel = ({text, className, variant}) => {
  return(
    <span className={cn(styles.statusLabel, className, {
      [styles.negative]: variant === 'negative',
      [styles.positive]: variant === 'positive',
      [styles.neutral]: variant === 'neutral',
      [styles.warning]: variant === 'warning'
    })}>{text}</span>
  )
}