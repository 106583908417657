import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  documentLoading: false
}

const documentLoadingSlice = createSlice({
  name: 'documentLoadingSlice',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.documentLoading = action.payload
    }
  }
})

export const {actions: documentLoadingSliceActions} = documentLoadingSlice
export const {reducer: documentLoadingSliceReducer} = documentLoadingSlice