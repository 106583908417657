import { gql } from "@apollo/client";

export const CREATE_TICKET = gql`
  mutation CreateTicket($description: String!, $file: Upload) {
    createSupportTicket(description: $description, file: $file) {
      supportTicket {
        uuid
        id
      }
    }
  }
`;
