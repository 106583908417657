import { gql } from "@apollo/client";

export const CREATE_COMPANY_REPRESENTED_COMPANY = gql`
  mutation CreateCompanyRepresentedCompany(
  $representativeId: UUID!
	$representedId: UUID!
	$representingId: UUID!
){
  createLegalPersonRepresentedByLegalPerson(
    representativeId: $representativeId
		representedId: $representedId
		representingId: $representingId
    
  ){
    legalPersonRepresented{
      id
      uuid
    }
  }
}
`