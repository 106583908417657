import { useEffect, useMemo, useState } from "react";
import styles from "./ApplicationDocuments.module.scss";
import { useLocation, useNavigate, useParams} from "react-router-dom";
import { Sidebar } from "../../../components/Sidebar";
import { Button } from "../../../components/Button";
import cn from 'classnames'
import { DocumentViewer } from "../../../components/DocumentViewer/ui/DocumentViewer";

export const ApplicationDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [tabIndex, setTabindex] = useState(Number(id));
  const tabNumbers = [];

  const documentVariantrender = useMemo(() => {
    if(tabIndex && documents.length > 0){
      return(
        <DocumentViewer fileUrl={documents[tabIndex - 1]?.presignedUrl}/>
      )
    } else {
      return null
    }
  }, [tabIndex, documents])

  
  useEffect(() => {
    if (location.state && location.state.documents) {
      setDocuments(location.state.documents);
    }
  }, []);


  return (
    <div className={styles.applicationsDocumentsWrapper}>
      <Sidebar className={styles.applicationsDocumentsSidebar}>
        <Button
          onClick={() => navigate("/client-cabinet/")}
          className={styles.sidebarBtn}
        >
          <span>{"<"}</span>
          Кабинет
        </Button>
        <h3 className={styles.sidebarTitle}>Документы</h3>
        <div className={styles.sidebarTabs}>
          {documents.map((document, index) => (
            <Button
              key={index}
              className={cn(styles.sidebarTab, {
                [styles.activeTab]: tabIndex === (index + 1)
              })}
              onClick={() => setTabindex(index + 1)}
            >
              <h3>{document.title}</h3>
              <span
                className={styles.greenRightText}
              >{document?.sigFile !== '' ? 'Подписано' : 'Не подписано'}</span>
            </Button>
          ))}
        </div>
      </Sidebar>
      <div className={styles.applicationsDocumentsContent}>
        {documentVariantrender}
      </div>
    </div>
  );
};
