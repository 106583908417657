import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/user"
import applicationReducer from "../slices/application"
import legalPersonReducer from "../slices/legalPerson"
import { contractSliceReducer } from "../../pages/Contract";
import { notificationReducer } from "../slices/notificationsSlice";
import { documentLoadingSliceReducer } from "../../pages/HotDocument";

const rootReducer = combineReducers({
  user: userReducer,
  application: applicationReducer,
  legalPerson: legalPersonReducer,
  clientDocuments: contractSliceReducer,
  notifications: notificationReducer,
  loadingDocuments: documentLoadingSliceReducer
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})