import { gql } from "@apollo/client";

export const CREATE_REAL_OBJECT = gql`
  mutation CreateRealObject(
    $cadastralNumber: String!
    $buildingArea: String
    $region: String
    $district: String
    $locality: String
    $house: String
    $structure: String
    $okato: String
    $oktmo: String
    $index: String
    $city: String
    $street: String
    $streetType: String
    $country: String
    $value: String
    $typeId: UUID!
    $apartment: String
    $regionKladrId: String
    $block: String
    $fias: String
    $kladr: String
  ) {
    createRealObject(
      inputObject: {
        cadastralNumber: $cadastralNumber
        buildingArea: $buildingArea
        typeId: $typeId
      }
      inputAddress: {
        apartment: $apartment
        region: $region
        district: $district
        locality: $locality
        house: $house
        structure: $structure
        okato: $okato
        oktmo: $oktmo
        index: $index
        city: $city
        street: $street
        streetType: $streetType
        country: $country
        value: $value
        regionKladrId: $regionKladrId
        block: $block
        fias: $fias
        kladr: $kladr
      }
    ) {
      realObjectData {
        uuid
        cadastralNumber
        buildingArea
        address {
          region
          district
          locality
          house
          structure
          okato
          oktmo
          index
          city
          street
          streetType
          country
          value
        }
      }
    }
  }
`;

export const UPDATE_REAL_OBJECT = gql`
  mutation UpdateRealObject(
    $inputAddress: AddressInput
  	$inputObject:RealObjectInput
  	$realObjectUuid: UUID!
  ) {
    updateRealObject(
      realObjectUuid: $realObjectUuid
      inputObject: $inputObject
      inputAddress: $inputAddress
    ) {
      realObjectData {
        uuid
        cadastralNumber
        buildingArea
        address {
          region
          district
          locality
          house
          structure
          okato
          oktmo
          index
          city
          street
          streetType
          country
          value
        }
      }
    }
  }
`;

export const DELETE_OBJECT = gql`
  mutation DeleteObject($objId: UUID!) {
    deleteRealObject(uuid: $objId) {
      success
    }
  }
`;
