import { gql } from "@apollo/client";

export const GET_DOCUMENT_TYPES = gql`
    query DocumentTypes{
        documentTypes{
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                node {
                    id
                    uuid
                    name
                    code
                }
                cursor
            }
        }
    }
`
