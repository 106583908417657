import { gql } from "@apollo/client";

export const GET_APPLICATION_TYPES = gql`
  query AllApplicationTypes($systemType: String) {
    allApplicationTypes(systemType: $systemType) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          uuid
          title
          roles {
            id
            uuid
            name
          }
        }
        cursor
      }
    }
  }
`;

export const GET_ALL_TYPES = gql`
  query GetAllTypes {
    allApplicationTypes {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;
