import {gql} from "@apollo/client";

export const INN_SEARCH = gql`
  query getInnCompany($inn: String!){
  getExternalLegalPersons(inn: $inn){
    fullName
    shortName
    ogrn
    kpp
    address{
      region
      regionType
      block
      country
      city
      district
      oktmo
      okato
      street
      typeStreet
      apartment
      flatType
      value
    }
  }
}

`