import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  notificationReads: []
}

const notificationSlice = createSlice(
  {
    name: 'notificationSlice',
    initialState,
    reducers: {
      changeReads: (state, action) => {
        state.notificationReads = action.payload
      }
    }
  }
)

export const {actions: notificationActions} = notificationSlice
export const {reducer: notificationReducer} = notificationSlice