import { useEffect, useState } from "react";
import { SearchVariantsList } from "../../Lists";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_REPRESENTATIVE } from "../../../services/queries/queries/companies/searchRepresentative";
import { useDebouncedCallback } from "use-debounce";
import { Input } from "../../Input";
import styles from "./BaseSearch.module.scss";

export const BaseSearch = ({ targetArr, setTargetArr, tooltipClassName }) => {
  const [query, setQuery] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchRepresentative, { data }] = useLazyQuery(SEARCH_REPRESENTATIVE, {
    fetchPolicy: "no-cache",
  });

  const debounce = useDebouncedCallback((query) => {
    if (query) {
      searchRepresentative({
        variables: {
          search: query
        },
      });
    }
  }, 500);

  useEffect(() => {
    debounce(query);
  }, [query, debounce]);

  useEffect(() => {
    if (data && data.allPhysicalPersons && data.allPhysicalPersons.edges) {
      setSearchData(data.allPhysicalPersons.edges);
    } else {
      setSearchData([]);
    }
  }, [data]);

  return (
    <div className="saerch">
      <Input
        variant="white-bg"
        placeholder={"Поиск по базе физлиц"}
        className={styles.globalSearch}
        value={query}
        setValue={setQuery}
        type="search"
      />
      {searchData && searchData.length > 0 && query.trim() !== "" && (
        <SearchVariantsList
          className={tooltipClassName}
          physicalData={searchData}
          results={targetArr}
          setSearchResults={setTargetArr}
          setQuery={setQuery}
        />
      )}
    </div>
  );
};
