import { gql } from "@apollo/client";

export const ALL_COMPANY_REPRESENTATIVES_BY_PERSON_LIST = gql`
  query AllCompanyRepresentativesByPhysical(
    $search: String
    $first: Int
    $offset: Int
  ) {
    allLegalPersonRepresentedByPhysicalPersons(
      search: $search
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          id
          uuid
          legalPerson {
            fullName
            uuid
          }
          representative {
            firstName
            lastName
            patronymic
            uuid
          }

          representedPhysicalPerson {
            firstName
            lastName
            patronymic
            uuid
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;
