import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../../../assets/icons/Logo";
import styles from "./Header.module.scss";
import { UserIcon } from "../../../assets/icons/UserIcon";
import cn from "classnames";
import { Button } from "../../Button";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Context } from "../../../App";
import { ToastComponent } from "../../ToastComponent";
import useRefreshToken from "../../../hooks/useRefreshToken";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { selectToken } from "../../../redux/slices/user";
import { ME } from "../../../services/queries";
import { ReactComponent as Notification } from "../../../assets/svgs/notfication.svg";
import {
  ALL_READ_TICKET,
  ALL_TICKETS_LIST,
} from "../../../services/queries/queries/support/allTicketsList";

const navArray = [
  {
    id: 1,
    title: "Заявки",
    path: "/applications/",
    role: ["AD", "MA"],
  },

  {
    id: 2,
    title: "ЕГРН",
    path: "/egrn/",
    role: ["AD"],
  },

  {
    id: 3,
    title: "Базы",
    path: "/bases/",
    role: ["AD", "MA"],
  },

  {
    id: 4,
    title: "Объекты",
    path: "/objects/",
    role: ["AD", "MA"],
  },

  {
    id: 5,
    title: "Поддержка",
    path: "/support/",
    role: ["AD", "MA"],
  },

  {
    id: 6,
    title: "Пользователи",
    path: "/users/",
    role: ["AD"],
  },
];

export const Header = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { tab, setShowModal, setTabIndex } = useContext(Context);
  const [showProfile, setShowProfile] = useState(false);
  const actionsRef = useRef(null);
  const { refreshTokenHandle } = useRefreshToken();
  const token = useSelector(selectToken);
  const id = localStorage.getItem("id");
  const { data: readData } = useQuery(ALL_READ_TICKET);
  const notificationsReads = useSelector(
    (state) => state.notifications.notificationReads
  );

  // function allMessage(readData) {
  //   return readData?.allTickets?.edges?.every(
  //     (element) => element?.node?.isRead === true
  //   );
  // }

  const allMessage = (readData) => {
    return readData?.every((element) => element?.node?.isRead === true);
  };

  const { data } = useQuery(ME, {
    variables: {
      id,
    },
    skip: !id,
  });

  const user = data?.user?.physicalPerson;
  const navigate = useNavigate();

  const buttonTextVariantRender = useMemo(() => {
    if (currentPath === "/applications/") {
      return (
        <Link className={styles.headerNewLink} to="/applications/new">
          Новая заявка
        </Link>
      );
    } else if (currentPath === "/egrn/") {
      return (
        <Button
          className={styles.headerBtn}
          variant="green"
          onClick={() => setShowModal(true)}
        >
          Новая выписка
        </Button>
      );
    } else if (currentPath === "/bases/") {
      return (
        <Button
          className={styles.headerBtn}
          variant="green"
          onClick={() => setShowModal(true)}
        >
          {tab}
        </Button>
      );
    } else if (currentPath === "/objects/") {
      return (
        <Button
          className={styles.headerBtn}
          variant="green"
          onClick={() => setShowModal(true)}
        >
          Новый объект
        </Button>
      );
    } else if (currentPath === "/support/") {
      return (
        <Button
          className={styles.headerBtn}
          variant="green"
          onClick={() => setShowModal(true)}
        >
          Новое обращение
        </Button>
      );
    } else if (currentPath === "/users/") {
      return (
        <Button
          className={styles.headerBtn}
          variant="green"
          onClick={() => setShowModal(true)}
        >
          Новое физлицо
        </Button>
      );
    }

    return null;
  }, [currentPath, tab]);

  // useEffect(() => {
  //   if (!token) {
  //     navigate("/login");
  //     localStorage.clear();
  //   }
  // }, [navigate, token]);

  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <div className={styles.headerNav}>
          <Link to="/" className={styles.headerLogo}>
            <Logo />
          </Link>
          {navArray.map((nav) => {
            if (nav.role.includes(data?.user?.role)) {
              if (nav.title === "Поддержка") {
                return (
                  <div className={styles.headerSupportBlock} key={nav.id}>
                    <Link
                      key={nav.id}
                      to={nav.path}
                      className={cn(styles.headerLink, {
                        [styles.activeLink]: nav.path === currentPath,
                      })}
                    >
                      {nav.title}
                    </Link>
                    {!allMessage(notificationsReads) && (
                      <div className={styles.headerNotification}>
                        <Notification />
                      </div>
                    )}
                  </div>
                );
              }
              if (nav.title === "Базы") {
                return (
                  <div
                    className={styles.headerSupportBlock}
                    key={nav.id}
                    onClick={() => setTabIndex(0)}
                  >
                    <Link
                      key={nav.id}
                      to={nav.path}
                      className={cn(styles.headerLink, {
                        [styles.activeLink]: nav.path === currentPath,
                      })}
                    >
                      {nav.title}
                    </Link>
                  </div>
                );
              } else {
                return (
                  <Link
                    key={nav.id}
                    to={nav.path}
                    className={cn(styles.headerLink, {
                      [styles.activeLink]: nav.path === currentPath,
                    })}
                  >
                    {nav.title}
                  </Link>
                );
              }
            }
          })}
        </div>

        <div className={styles.headerActions} ref={actionsRef}>
          <Button className={styles.sync} variant="transparent">
            Синхронизироваться с 1С
          </Button>
          {buttonTextVariantRender}
          <span
            className={styles.headerAvatar}
            onClick={() => setShowProfile(!showProfile)}
          >
            <UserIcon />
          </span>
          {showProfile && (
            <ToastComponent
              isOpen={showProfile}
              setIsOpen={setShowProfile}
              toastRef={actionsRef.current}
              className={styles.profileInfo}
            >
              <span className={styles.profileName}>
                {user?.lastName} {user?.firstName} {user?.patronymic}
              </span>
              <Link to={"#"} className={styles.profileLink}>
                Сменить пароль
              </Link>
              <Link
                to={"/login"}
                className={styles.profileLink}
                onClick={() => localStorage.clear()}
              >
                Выйти из профиля
              </Link>
            </ToastComponent>
          )}
        </div>
      </div>
    </header>
  );
};
