import { InfoList } from "../../../InfoList/ui/InfoList";
import { Pagination } from "../../../Pagination/ui/Pagination";
import { StatusLabel } from "../../../StatusLabel/ui/StatusLabel";
import styles from "./UsersList.module.scss";
import { useState } from "react";
import cn from "classnames";
import { ModalWindow } from "../../../ModalWindow";
import { CreateUserForm } from "../../../Forms";
import { Preloader } from "../../../Preloader";
import { toast } from "react-toastify";

export const UsersList = ({ usersData, loading, error }) => {
  const [activeUserId, setActiveUserId] = useState("");

  if (loading) return <Preloader />;
  if (error) {
    return toast.error(error.message);
  }
  console.log(usersData);
  return (
    <ul className={styles.usersList}>
      {usersData.map((user, index) => (
        <div key={index}>
          <InfoList key={index} onClick={() => setActiveUserId(user?.id)}>
            <div className={styles.usersInner}>
              <div className={styles.userInfo}>
                {user.permissionStatus ? (
                  <StatusLabel text="Работа разрешена" variant="positive" />
                ) : (
                  <StatusLabel text="Аккаунт отключен" variant="negative" />
                )}

                <span className={styles.userRole}>{user.userRole}</span>
                <div className={styles.userBio}>
                  <span>
                    {user?.physicalPerson?.lastName +
                      " " +
                      user?.physicalPerson?.firstName +
                      " " +
                      user?.physicalPerson?.patronymic}
                  </span>
                  <div className={styles.userContacts}>
                    <span>{user?.email}</span>
                    <span>{user?.phone}</span>
                  </div>
                </div>
              </div>
              <div className={styles.userEdsInfo}>
                <span
                  className={cn({
                    [styles.edsNone]: !user.accStatus,
                  })}
                >
                  {user.accStatus ? "ЭЦП действует" : "ЭЦП не действует"}
                </span>
                {/* {user.edsStatus && (
                  <span className={styles.edsProvider}>MyDSS</span>
                )}
                {user.edsStatus && (
                  <span className={styles.edsProvider}>ЭЦП действует</span>
                )} */}
              </div>
            </div>
          </InfoList>
          {activeUserId === user?.id && (
            <ModalWindow show={activeUserId} setShowModal={setActiveUserId}>
              <CreateUserForm
                usersData={usersData}
                item={user}
                id={user.id}
                hideModal={setActiveUserId}
                userUuid={user.uuid}
              />
            </ModalWindow>
          )}
        </div>
      ))}
    </ul>
  );
};
