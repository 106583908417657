import { createSlice } from "@reduxjs/toolkit";
import { getClientDocuments } from "../services/getClientDocuments";

const initialState = {
  data: [],
  isLoading: false,
  error: undefined
};

const contractSlice = createSlice({
  name: "contractSlice",
  initialState,
  reducers: {
    changeDocumentStatus: (state, action) => {
      state.data = action.payload
    }
  },
  extraReducers(build){
    build
    .addCase(getClientDocuments.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    })

    .addCase(getClientDocuments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = undefined;
    })

    .addCase(getClientDocuments.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    })
  }
});

export const { actions: conctractSliceActions } = contractSlice;
export const { reducer: contractSliceReducer } = contractSlice;
