import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser(
    $userInput: UserInput!
    $personInput: CreatePhysicalPersonInput!
    $addressInput: AddressInput!
    $documentInput: IdentityDocumentInput!
  ) {
    createUser(
      userInput: $userInput
      personInput: $personInput
      addressInput: $addressInput
      documentInput: $documentInput
    ) {
      user {
        uuid
        email
        phone
        role
        permissionStatus
        physicalPerson {
          firstName
          lastName
          patronymic
          gender
          dateOfBirth
          placeOfBirth
          citizenship
          snils
          inn
          representative {
            uuid
            firstName
            lastName
          }
          registrationAddress {
            region
            regionType
            district
            locality
            house
            structure
            okato
            oktmo
            index
            city
            street
            streetType
            country
            value
            block
            fias
            kladr
            regionKladrId
          }
          document {
            type
            series
            number
            dateIssue
            issuedBy
            code
          }
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $userInput: UserInput!
    $personInput: CreatePhysicalPersonInput!
    $addressInput: AddressInput!
    $documentInput: IdentityDocumentInput!
    $userId: UUID!
  ) {
    updateUser(
      userInput: $userInput
      personInput: $personInput
      addressInput: $addressInput
      documentInput: $documentInput
      userId: $userId
    ) {
      user {
        uuid
        email
        phone
        role
        permissionStatus
        physicalPerson {
          firstName
          lastName
          patronymic
          gender
          dateOfBirth
          placeOfBirth
          citizenship
          snils
          inn
          representative {
            uuid
            firstName
            lastName
          }
          registrationAddress {
            region
            regionType
            district
            locality
            house
            structure
            okato
            oktmo
            index
            city
            street
            streetType
            country
            value
            block
            fias
            kladr
            regionKladrId
          }
          document {
            type
            series
            number
            dateIssue
            issuedBy
            code
          }
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userId: UUID!) {
    deleteUser(userId: $userId) {
      success
    }
  }
`;
