import { gql } from "@apollo/client";

export const TICKET_INFO = gql`
  query TicketInfo($id: ID!){
  ticket(id: $id){
    uuid
    description
    file
    createdAt
    status
    presignedUrl
    messages{
      uuid
      message
      createdAt
      updatedAt
      file
      presignedUrl
      user{
        email
      }
    }
  }
}

`