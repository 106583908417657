import { gql } from "@apollo/client";

  export const CREATE_STATEMENT_APPLICATION = gql`
    mutation CreateStatementApplication(
        $applicationUuid: UUID!
        $previousStatement: PreviousStatementInput
    ){
    createStatementApplication(
      applicationUuid: $applicationUuid
      previousStatement: $previousStatement
    ){
      success
    }
  }
  `
