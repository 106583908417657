import { gql } from "@apollo/client";

export const UPLOAD_PHYSICAL_PERSON_DOCUMENT = gql`
  mutation UploadPhysicalPersonDocument(
    $actyonType: String
    $description: String
    $document: Upload!
    $physicalPersonUuid: UUID!
  ) {
    uploadPhysicalPersonDocument(
      actyonType: $actyonType
      description: $description
      document: $document
      physicalPersonUuid: $physicalPersonUuid
    ) {
      physicalPersonDocument {
        uuid
        document {
          uuid
          title
          state
        }
      }
    }
  }
`;
