import { useEffect, useMemo, useState } from "react";
import { GlobalSearch } from "../../../GlobalSearch/ui/GlobalSearch";
import styles from "./CreateCompanyRepresentativeForm.module.scss";
import { Button } from "../../../Button";
import cn from "classnames";
import { useMutation, useQuery } from "@apollo/client";
import { REPRESANTIVE_COMPANY_INFO } from "../../../../services/queries/queries/representativesByPhysical/RepresentiveCompanyInfo";
import { CREATE_REPRESENTED_COMPANY } from "../../../../services/queries/mutations/representativesByPhysical/createRepresentedCompany";
import { ALL_COMPANY_REPRESENTATIVES_BY_PERSON_LIST } from "../../../../services/queries/queries/representativesByPhysical/AllRepresentativesList";
import { RepresentativeItem } from "../../../RepresentativeItem";
import { CREATE_COMPANY_REPRESENTED_COMPANY } from "../../../../services/queries/mutations/representativesByCompany/createCompanyRepresentativesCompany";
import { ALL_REPRESENTATIVES_BY_COMPANY } from "../../../../services/queries/queries/representativesByCompany/allRepresentativesByCompany";
import { DELETE_REPRESENTED_COMPANY } from "../../../../services/queries/mutations/representativesByPhysical/deleteRepresentedCompany";
import { DELETE_REPRESENTED_COMPANY_BY_COMPANY } from "../../../../services/queries/mutations/representativesByCompany/deleteRepresentedCompanyByCompany";
import { REPRESENTATIVE_COMPANY_BY_COMPANY_INFO } from "../../../../services/queries/queries/representativesByCompany/representativeCompanyByCompanyInfo";
import { useForm } from "react-hook-form";
import useFormatDocumentDate from "../../../../hooks/useFormatDocumentDate";
import { toast } from "react-toastify";
export const documentTypes = [
  "Доверенность",
  "Свидетельство о рождении",
  "Приказ о назначении (руководителя или иного уполномоченного лица)",
  "Иной документ",
  "Решение суда",
  "Определение суда",
  "Протокол об избрании",
];
export const CreateCompanyRepresentativeForm = ({
  hideModal,
  isAgentForm = false,
  id,
  uuid,
  setShowModal,
}) => {
  const { data } = useQuery(REPRESANTIVE_COMPANY_INFO, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  const { formatDocumentDate } = useFormatDocumentDate();

  const { data: representativeCompanyData } = useQuery(
    REPRESENTATIVE_COMPANY_BY_COMPANY_INFO,
    {
      variables: {
        id,
      },

      skip: !id && !isAgentForm,
    }
  );

  const {
    formState: { errors },
    setError,
  } = useForm();

  const [physicalData, setPhysicalData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [representatives, setRepresentatives] = useState([]);
  const [physicalRepresentative, setPhysicalrepresentative] = useState([]);
  const [companyRepresentative, setCompanyRepresentative] = useState([]);
  const [physicalRepresentedData, setPhysicalRepresentedData] = useState({});
  const [companyRepresentativeData, setCompanyRepresentativeData] = useState(
    {}
  );
  const [physicalRepresentativeData, setPhysicalRepresentativeData] = useState(
    {}
  );
  const [companyRepresentedData, setCompanyRepresentedData] = useState({});

  const documents = documentTypes;

  const [createRepresentedCompany] = useMutation(CREATE_REPRESENTED_COMPANY, {
    refetchQueries: [ALL_COMPANY_REPRESENTATIVES_BY_PERSON_LIST],
  });

  const [createRepresentedCompanyByCompany] = useMutation(
    CREATE_COMPANY_REPRESENTED_COMPANY,
    {
      refetchQueries: [ALL_REPRESENTATIVES_BY_COMPANY],
    }
  );

  const [deleteRepresentingPhysicalCompany] = useMutation(
    DELETE_REPRESENTED_COMPANY,
    {
      refetchQueries: [ALL_COMPANY_REPRESENTATIVES_BY_PERSON_LIST],
    }
  );

  const [deleteRepresentingCompanyByCompany] = useMutation(
    DELETE_REPRESENTED_COMPANY_BY_COMPANY,
    {
      refetchQueries: [ALL_REPRESENTATIVES_BY_COMPANY],
    }
  );

  const companyDeleteHandler = () => {
    if (!isAgentForm) {
      deleteRepresentingPhysicalCompany({
        variables: {
          uuid,
        },
      });
    } else {
      deleteRepresentingCompanyByCompany({
        variables: {
          uuid,
        },
      });
    }
  };

  const physicalRepresentedHandleRemove = () => {
    setPhysicalData([]);
  };

  const companyRepresentativeHandleRemove = () => {
    setCompanyRepresentative([]);
    setRepresentatives([]);
    setPhysicalrepresentative([]);
  };

  const physicalRepresentativeHandleRemove = () => {
    setPhysicalrepresentative([]);
  };

  const companyRepresentedDataHandleRemove = () => {
    setCompanyData([]);
  };

  const representativeVariantRender = useMemo(() => {
    if (!isAgentForm) {
      return (
        <div className={styles.representedPersonSearch}>
          <span className={styles.searchTitle}>Представляемое физлицо</span>
          <GlobalSearch
            placeholder="Поиск по базе физлиц"
            targetArr={physicalData}
            setTargetArr={setPhysicalData}
          />

          {physicalData && physicalData.length > 0 ? (
            <RepresentativeItem
              physicalRepresented={physicalData[0]}
              physicalRepresentedRemove={physicalRepresentedHandleRemove}
              id={id}
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <div className={styles.representedPersonSearch}>
          <span className={styles.searchTitle}>Представляемое юрлицо</span>
          <GlobalSearch
            isCompanySearch={true}
            placeholder="Поиск по базе юрлиц"
            targetArr={companyData}
            setTargetArr={setCompanyData}
          />
          <RepresentativeItem
            companyRepresented={companyData[0]}
            companyRepresentedRemove={companyRepresentedDataHandleRemove}
            id={id}
          />
        </div>
      );
    }
  }, [isAgentForm, physicalData, companyData, id]);

  const onClickHandler = () => {
    if (!physicalData || physicalRepresentative.length === 0) {
      return toast.error("Представитель не указан");
    }
    if (physicalData.length === 0 && !isAgentForm) {
      return toast.error("Физлицо не указано");
    }
    if (!isAgentForm) {
      createRepresentedCompany({
        variables: {
          representingId: companyRepresentative[0].uuid,
          representativeId: physicalRepresentative[0]?.physicalPerson?.uuid,
          representedId: physicalData[0].uuid,
        },
      }).catch((error) => {
        setError("root.serverError", {
          type: "Ошибка при отправке запроса",
        });

        if (error.networkError) {
          setError("root.serverError", {
            type: "Received status code 400",
          });
          console.log(`[Network error]: ${error.networkError}`);
        }
      });
    } else {
      createRepresentedCompanyByCompany({
        variables: {
          representingId: companyRepresentative[0].uuid,
          representativeId: physicalRepresentative[0]?.physicalPerson?.uuid,
          representedId: companyData[0].uuid,
        },
      }).catch((error) => {
        setError("root.serverError", {
          type: "Ошибка при отправке запроса",
        });

        if (error.networkError) {
          setError("root.serverError", {
            type: "Received status code 400",
          });
          console.log(`[Network error]: ${error.networkError}`);
        }
      });
    }

    if (!errors?.root?.serverError) {
      setShowModal(false);

      if (hideModal) {
        hideModal(null);
      }
    }
  };

  useEffect(() => {
    if (data && data?.legalPersonRepresentedByPhysicalPersons) {
      const company =
        data.legalPersonRepresentedByPhysicalPersons?.legalPerson;
      const physicalrepresentativeUUid =
        data.legalPersonRepresentedByPhysicalPersons
          .representative?.uuid;

      const selectedRepresentative = company?.representatives?.find(
        (item) => item?.physicalPerson?.uuid === physicalrepresentativeUUid
      );

      setCompanyRepresentative([company]);

      setPhysicalrepresentative([selectedRepresentative]);

      setCompanyData([company]);

      setPhysicalData([
        data.legalPersonRepresentedByPhysicalPersons.representedPhysicalPerson,
      ]);
    }

    if (
      representativeCompanyData &&
      representativeCompanyData.legalPersonRepresentedByLegalPerson
    ) {
      const company =
        representativeCompanyData.legalPersonRepresentedByLegalPerson
          .legalPerson;
      const physicalrepresentativeUUid =
        representativeCompanyData.legalPersonRepresentedByLegalPerson
          .representative?.uuid;

      setCompanyRepresentative([company]);
      setCompanyData([
        representativeCompanyData.legalPersonRepresentedByLegalPerson
          .representedLegalPerson,
      ]);

      const selectedRepresentative = company?.representatives?.find(
        (item) => item?.physicalPerson?.uuid === physicalrepresentativeUUid
      );
      setPhysicalrepresentative([selectedRepresentative]);
    }
  }, [data, representativeCompanyData]);

  console.log(representativeCompanyData, 'representativeCompanyData')

  return (
    <div className={styles.mainWrapper}>
      <div
        className={cn(styles.searchInner, {
          [styles.fullInner]: id || isAgentForm,
        })}
      >
        <div className={styles.bottom}>
          <div className={styles.companySearch}>
            <span className={styles.searchTitle}>Юрлицо-представитель</span>
            <GlobalSearch
              placeholder="Поиск по базе юрлиц"
              targetArr={companyRepresentative}
              setTargetArr={setCompanyRepresentative}
              isCompanySearch={true}
              setRepresentativesInfo={setRepresentatives}
            />

            {companyRepresentative && companyRepresentative.length > 0 ? (
              <RepresentativeItem
                companyRepresentative={companyRepresentative[0]}
                companyRepresentativeRemove={companyRepresentativeHandleRemove}
                id={id}
              />
            ) : null}
          </div>
          {representativeVariantRender}
        </div>
        <div>
          <div className={styles.representativeSearch}>
            <span className={styles.searchTitle}>Представитель юрлица</span>
            <div className={styles.resultsInner}>
              {physicalRepresentative.length === 0 && !id
                ? representatives.map((item) =>
                    item.node.representatives.map((representative, index) => (
                      <>
                        {companyRepresentative.some((item) =>
                          item.representatives.includes(representative)
                        ) && (
                          <div className={styles.resultsWrapper}>
                            <div
                              key={index}
                              className={styles.listItem}
                              onClick={() =>
                                setPhysicalrepresentative([representative])
                              }
                            >
                              <div className={styles.info}>
                                <span className={styles.client}>
                                  {representative.physicalPerson?.lastName +
                                    " " +
                                    representative.physicalPerson?.firstName +
                                    " " +
                                    representative.physicalPerson?.patronymic}
                                </span>
                                <span className={styles.attorney}>
                                  {
                                    representative?.legalAuthorityRecord?.type
                                      ?.title
                                  }
                                  {representative?.legalAuthorityRecord
                                    ?.durationStop && (
                                    <>
                                      {" "}
                                      до{" "}
                                      {formatDocumentDate(
                                        representative?.legalAuthorityRecord
                                          ?.durationStop
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))
                  )
                : null}
            </div>

            {physicalRepresentative && physicalRepresentative.length > 0 ? (
              <RepresentativeItem
                physicalRepresentative={physicalRepresentative[0]}
                physicalRepresentativeRemove={
                  physicalRepresentativeHandleRemove
                }
                companyRepresentative={companyRepresentative}
                documents={documentTypes}
                data={representatives}
                setData={setRepresentatives}
                id={id}
                uuid={uuid}
              />
            ) : (
              <p className="paginationInfo">Представитель не указан</p>
            )}
          </div>
        </div>
      </div>

      {errors?.root?.serverError?.type === "Ошибка при отправке запроса" ||
        (errors?.root?.serverError?.type === "Received status code 400" && (
          <p className={styles.formError}>Ошибка при отправке запроса</p>
        ))}

      {!id ? (
        <Button variant="green" onClick={onClickHandler}>
          Сохранить
        </Button>
      ) : null}
      <Button
        variant="red"
        className={styles.deletebtn}
        onClick={() => {
          companyDeleteHandler();
          // setShowModal(false);
          if (hideModal) {
            hideModal(null);
          }
        }}
      >
        Удалить
      </Button>
    </div>
  );
};
