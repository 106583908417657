export const UserIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99996 2.66732C7.46953 2.66732 6.96082 2.87803 6.58575 3.2531C6.21067 3.62818 5.99996 4.13688 5.99996 4.66732C5.99996 5.19775 6.21067 5.70646 6.58575 6.08153C6.96082 6.4566 7.46953 6.66732 7.99996 6.66732C8.53039 6.66732 9.0391 6.4566 9.41417 6.08153C9.78925 5.70646 9.99996 5.19775 9.99996 4.66732C9.99996 4.13688 9.78925 3.62818 9.41417 3.2531C9.0391 2.87803 8.53039 2.66732 7.99996 2.66732ZM5.64294 2.31029C6.26806 1.68517 7.1159 1.33398 7.99996 1.33398C8.88401 1.33398 9.73186 1.68517 10.357 2.31029C10.9821 2.93542 11.3333 3.78326 11.3333 4.66732C11.3333 5.55137 10.9821 6.39922 10.357 7.02434C9.73186 7.64946 8.88401 8.00065 7.99996 8.00065C7.1159 8.00065 6.26806 7.64946 5.64294 7.02434C5.01782 6.39922 4.66663 5.55137 4.66663 4.66732C4.66663 3.78326 5.01782 2.93542 5.64294 2.31029ZM4.22872 10.2294C5.22892 9.22922 6.58547 8.66732 7.99996 8.66732C9.41445 8.66732 10.771 9.22922 11.7712 10.2294C12.7714 11.2296 13.3333 12.5862 13.3333 14.0007C13.3333 14.3688 13.0348 14.6673 12.6666 14.6673H3.33329C3.15648 14.6673 2.98691 14.5971 2.86189 14.4721C2.73686 14.347 2.66663 14.1775 2.66663 14.0007C2.66663 12.5862 3.22853 11.2296 4.22872 10.2294ZM7.99996 10.0007C6.93909 10.0007 5.92168 10.4221 5.17153 11.1722C4.5804 11.7634 4.1934 12.5204 4.05589 13.334H11.944C11.8065 12.5204 11.4195 11.7634 10.8284 11.1722C10.0782 10.4221 9.06082 10.0007 7.99996 10.0007Z"
        fill="#3D2E33"
      />
    </svg>
  );
};
