import { gql } from "@apollo/client";

export const CREATE_APPLICATION = gql`
  mutation CreateApplication(
    $area: Decimal
    $mortgage: Boolean!
    $ownershipUuid: UUID
    $participantsUuids: [UUID]
    $realObjectUuid: UUID
    $status: String!
    $sum: Decimal
    $typeUuid: UUID!
  ) {
    createApplication(
      area: $area
      mortgage: $mortgage
      ownershipUuid: $ownershipUuid
      participantsUuids: $participantsUuids
      realObjectUuid: $realObjectUuid
      status: $status
      sum: $sum
      typeUuid: $typeUuid
    ) {
      application {
        id
        uuid
      }
    }
  }
`;

export const CREATE_APPLICATION_ADDITIONAL = gql`
  mutation createAdditionalApplication(
    $applicationTypeUuid: UUID!
    $originalApplicationUuid: UUID!
    $participantUuid: UUID!
    $roleUuid: UUID!
  ) {
    createAdditionalApplication(
      applicationTypeUuid: $applicationTypeUuid
      originalApplicationUuid: $originalApplicationUuid
      participantUuid: $participantUuid
      roleUuid: $roleUuid
    ) {
      additionalApplication {
        id
        uuid
        newApplication {
          id
          uuid
        }
      }
    }
  }
`;
