import { useState, useEffect, useCallback } from "react"
import { InfoList } from "../../../../InfoList";
import { useQuery } from "@apollo/client";

import { useNavigate, Navigate } from "react-router-dom";

/* 
  Компонент выводит список документов клиента по сделке

  Пропсы:

  className - помогает передать дополнительные стили для списка из родительского компонента

*/

export const ClientDocumentsList = ({className, clientData}) => {

  

  const [clientDocuments, setClientDocuments] = useState([]);
  const navigate = useNavigate()

  const createDocumentsList = useCallback(() => {
    setClientDocuments([]);

    if (clientData) {
      clientData[0]?.application?.documents?.forEach((document) =>
        setClientDocuments((clientDocuments) => [
          ...clientDocuments,
          document,
        ])
      );
    }
  }, [setClientDocuments, clientData]);

  useEffect(() => {
    if(clientData){
      createDocumentsList()
    }
  }, [clientData])

  return(
    <>
      {clientDocuments.map((document, index) => (
      <InfoList key={index} className={className} onClick={() => navigate(`/application-documents/${index + 1}`, {
        state: {documents: clientDocuments}
      })}>
        <h3>{document.title}</h3>
        <span>{document.sigFile !== '' ? 'Подписан' : 'Не подписан'}</span>
      </InfoList>
    ))}
    </>
  )
}