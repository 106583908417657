import {useForm} from "react-hook-form";
import styles from "./CreateUserForm.module.scss";
import {RadioButton} from "../../../RadioButton";
import {Input} from "../../../Input";
import {FormCalendar} from "../../../FormCalendar";
import {checkValidation} from "../../../../helpers/checkValidation";
import {Select} from "../../../Select/ui/Select";
import {Button} from "../../../Button";
import {useContext, useEffect, useRef, useState} from "react";
import {Context} from "../../../../App";
import {CREATE_USER, UPDATE_USER} from "../../../../services/mutations/user";
import {useMutation, useQuery} from "@apollo/client";
import useInputSearch from "../../../../hooks/useInputSearch";
import {transformDate} from "../../../../helpers/transformDate";
import {DeleteUserModal} from "./DeleteUserUI/DeleteUserModal";
import SignatureForm from "../SignatureForm/SignatureForm";
import {ALL_USERS, GET_ADDRESS_USER,} from "../../../../services/queries/queries/user";
import {SearchAddressInput} from "../SearchAddressInput/SearchAddressInput";
import {toast} from "react-toastify";
import {unTransformDate} from "../../../../helpers/untransformDate";

export const CreateUserForm = ({
  usersData,
  item,
  hideModal,
  id,
  userUuid,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    setError,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: item,
  });
  const { setShowModal } = useContext(Context);
  const [createUser] = useMutation(CREATE_USER, {
    refetchQueries: [ALL_USERS],
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [ALL_USERS],
  });
  const { search, setSearch, data } = useInputSearch("", "GET_ADDRESSES");

  const { data: userData } = useQuery(GET_ADDRESS_USER, {
    variables: { id: item?.id },
    skip: !item?.id,
  });
  useEffect(() => {
    if (errors?.root) {
      toast.error(errors.root.serverError.type);
    }
  }, [errors]);
  const [otherToAddress, setOtherToAddress] = useState(
    userData?.user.physicalPerson.registrationAddress || {}
  ); // Вытекающие из адреса (locality, city etc)
  const [errorMessage, setErrorMessage] = useState("");
  const [formStep, setFormStep] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [ageError, setAgeError] = useState("");
  const [dateGive, setDateGive] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [addressError, setAddressError] = useState(false);

  const snilsValidation = /^[0-9\-]+$/;
  const innValidation = /^[0-9]+$/;
  const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
  const passportValidation = /^[a-zA-Z0-9]+$/;

  const addressRef = useRef(null);

  const formatAddress = [
    otherToAddress?.index || "",
    otherToAddress?.region || "",
    otherToAddress?.district || "",
    otherToAddress?.locality || otherToAddress?.locality !== "None None"
      ? otherToAddress?.locality
      : "",
    otherToAddress?.settlementWithType || "",
    otherToAddress?.locationName || "",
    otherToAddress?.street || "",
    otherToAddress?.house ? "дом " + otherToAddress?.house : "",
    otherToAddress?.block ? "корпус " + otherToAddress?.block : "",
    otherToAddress?.apartment ? "кв " + otherToAddress?.apartment : "",
  ]
    .filter(Boolean)
    .join(", ");

  function removeNonDigits(input) {
    return input.replace(/\D/g, "");
  }

  if (errorMessage.length !== 0) {
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  }

  useEffect(() => {
    if (item && item.physicalPerson) {
      reset({
        firstName: item.physicalPerson.firstName,
        lastName: item.physicalPerson.lastName,
        patronimic: item.physicalPerson.patronymic,
        userRole: item.role === "AD" ? "Администратор" : "Менеджер",
        workStatus: item.permissionStatus ? "Разрешена" : "Запрещена",
        sex:
          item.physicalPerson.gender === "MALE"
            ? "Мужской"
            : item.physicalPerson.gender === "FEMALE"
            ? "Женский"
            : null,
        inn: item.physicalPerson.inn,
        phone: item.physicalPerson.phone,
        email: item.physicalPerson.email,
        citizenship: item.physicalPerson.citizenship,
        placeOfBirth: item.physicalPerson.placeOfBirth,
        dateOfBirth: unTransformDate(item.physicalPerson.dateOfBirth),
        snils: item.physicalPerson.snils,
        series: item?.physicalPerson?.document?.series,
        number: item?.physicalPerson?.document?.number,
        issueDate: unTransformDate(item?.physicalPerson?.document?.dateIssue),
        whomIssued: item?.physicalPerson?.document?.issuedBy,
        departmentCode: item?.physicalPerson?.document?.code,
        documents: "Паспорт",
      });
    }
  }, [item, reset]);

  // это надо вынести в отдельный файл
  const saveUser = async (user) => {
    await createUser({
      variables: {
        userInput: {
          email: user.email || "", // Убедитесь, что поля не пустые
          phone: user.phone.replace(/[^\d+]/g, "") || "",
          role: user.userRole === "Администратор" ? "AD" : "MA",
          permissionStatus: user.workStatus === "Разрешена",
        },
        personInput: {
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          patronymic: user.patronimic || "",
          gender:
            user.sex === "Мужской"
              ? "MALE"
              : user.sex === "Женский"
              ? "FEMALE"
              : "",
          dateOfBirth: transformDate(user.dateOfBirth),
          placeOfBirth: user.placeOfBirth || "",
          citizenship: user.citizenship || "",
          snils: removeNonDigits(user.snils) || "",
          inn: user.inn || "",
          representativeId: null,
        },
        addressInput: {
          block: otherToAddress?.block,
          city: otherToAddress?.city,
          country: otherToAddress?.country,
          district: otherToAddress?.district,
          house: otherToAddress?.house,
          index: otherToAddress?.index,
          locality: otherToAddress?.locality,
          okato: otherToAddress?.okato,
          oktmo: otherToAddress?.oktmo,
          region: otherToAddress?.region,
          street: otherToAddress?.street,
          streetType: otherToAddress?.typeStreet,
          structure: otherToAddress?.structure,
          value: formatAddress?.length > 0 ? formatAddress : search,
          apartment: otherToAddress?.apartment,
          regionKladrId: otherToAddress?.regionKladrId,
          fias: otherToAddress?.fias,
          kladr: otherToAddress?.kladr,
        },
        documentInput: {
          type: user.documents === "Паспорт" ? "PASSPORT" : "",
          series: user.series || "",
          number: user.number || "",
          dateIssue: transformDate(user.issueDate),
          issuedBy: user.whomIssued || "",
          code: removeNonDigits(user.departmentCode) || "",
        },
      },
    })
      .then((res) => {
        if (!errors?.root?.serverError) {
          setShowModal(false);
          if (hideModal) {
            hideModal(null);
          }
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setError("root.serverError", {
          type: "Ошибка при отправке запроса",
        });

        if (error.graphQLErrors) {
          error.graphQLErrors.forEach(({ message }) =>
            setError("root.serverError", {
              type: message.split(".")[0],
            })
          );
        }

        if (error.networkError) {
          setError("root.serverError", {
            type: "Received status code 400",
          });
          console.log(`[Network error]: ${error.networkError}`);
        }
      });
  };
  const updateUserInfo = (user) => {
    console.log(user, "user");
    updateUser({
      variables: {
        userId: userUuid,
        userInput: {
          email: user.email, // Убедитесь, что поля не пустые
          phone: user.phone.replace(/[^\d+]/g, ""),
          role: user.userRole === "Администратор" ? "AD" : "MA",
          permissionStatus: user.workStatus === "Разрешена",
        },
        personInput: {
          firstName: user.firstName,
          lastName: user.lastName,
          patronymic: user.patronimic,
          gender:
            user.sex === "Мужской"
              ? "MALE"
              : user.sex === "Женский"
              ? "FEMALE"
              : "",
          dateOfBirth: transformDate(user?.dateOfBirth),
          placeOfBirth: user.placeOfBirth,
          citizenship: user.citizenship,
          snils: removeNonDigits(user?.snils),
          inn: user.inn,
          representativeId: null,
        },
        addressInput: {
          block: otherToAddress?.block,
          city: otherToAddress?.city,
          country: otherToAddress?.country,
          district: otherToAddress?.district,
          house: otherToAddress?.house,
          index: otherToAddress?.index,
          locality: otherToAddress?.locality,
          okato: otherToAddress?.okato,
          oktmo: otherToAddress?.oktmo,
          region: otherToAddress?.region,
          street: otherToAddress?.street,
          streetType: otherToAddress?.typeStreet,
          structure: otherToAddress?.structure,
          value: formatAddress?.length > 0 ? formatAddress : search,
          apartment: otherToAddress?.apartment,
          regionKladrId: otherToAddress?.regionKladrId,
          fias: otherToAddress?.fias,
          kladr: otherToAddress?.kladr,
        },
        documentInput: {
          type: user.documents === "Паспорт" ? "PASSPORT" : "",
          series: user.series,
          number: user.number,
          dateIssue: transformDate(user?.issueDate),
          issuedBy: user.whomIssued,
          code: removeNonDigits(user?.departmentCode),
        },
      },
    })
      .then((res) => {
        if (!errors?.root?.serverError) {
          setShowModal(false);
          if (hideModal) {
            hideModal(null);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setError("root.serverError", {
          type: "Ошибка при отправке запроса",
        });

        if (error.graphQLErrors) {
          error.graphQLErrors.forEach(({ message }) =>
            setError("root.serverError", {
              type: message.split(".")[0],
            })
          );
        }

        if (error.networkError) {
          setError("root.serverError", {
            type: "Received status code 400",
          });
          console.log(`[Network error]: ${error.networkError}`);
        }
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(item?.id ? updateUserInfo : saveUser)}>
        <div className={styles.formInner}>
          <h3 className={styles.formTitle}>Аккаунт</h3>
          <div>
            <div className={styles.radioBox}>
              <span className={styles.boxTitle}>Работа в системе</span>
              <div>
                <RadioButton
                  name="workStatus"
                  register={register}
                  text1="Разрешена"
                  text2="Запрещена"
                  className={styles.radioField}
                  registerObj={{ required: "Выберите подходящий вариант" }}
                  errors={errors}
                />
                <p className={styles.infoText}>
                  Если работа запрещена, пользователь не может входить в систему
                  и работать с ней.
                </p>
              </div>
            </div>
            <div className={styles.radioBox}>
              <span className={styles.boxTitle}>Роль</span>
              <div>
                <RadioButton
                  name="userRole"
                  register={register}
                  text1="Менеджер"
                  text2="Администратор"
                  className={styles.radioField}
                  registerObj={{ required: "Выберите подходящий вариант" }}
                  errors={errors}
                />
              </div>
            </div>
            <Input
              isFormField={true}
              name="email"
              label="Почта"
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{
                pattern: {
                  value: emailValidation,
                  message: "Введите коректынй e-mail",
                },
                required: "Поле обязательно для заполнения",
              }}
              errors={errors}
              placeholder="test@mail.ru"
            />
            <Input
              isFormField={true}
              name="phone"
              mask="+9(999) 999-99-99"
              label="Телефон"
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{ required: "Поле обязательно для заполнения" }}
              errors={errors}
              placeholder="+7(000) 000-00-00"
              className={styles.userPhone}
            />
          </div>

          <div className={styles.bottomForm}>
            <div>
              <h3 className={styles.formTitle}>Физлицо</h3>
              <div className={styles.info}>
                <Input
                  isFormField={true}
                  name="lastName"
                  label="Фамилия*"
                  variant="bordered-green"
                  size="full"
                  register={register}
                  registerObj={{ required: "Поле обязательно для заполнения" }}
                  errors={errors}
                />
                <Input
                  isFormField={true}
                  name="firstName"
                  label="Имя*"
                  variant="bordered-green"
                  size="full"
                  register={register}
                  registerObj={{ required: "Поле обязательно для заполнения" }}
                  errors={errors}
                />
                <Input
                  isFormField={true}
                  name="patronimic"
                  label="Отчество"
                  variant="bordered-green"
                  size="full"
                  register={register}
                  errors={errors}
                />
                <label className={styles.radiosBox}>
                  Пол
                  <RadioButton
                    name="sex"
                    text1="Мужской"
                    text2="Женский"
                    register={register}
                    registerObj={{ required: "Выберите подходящий вариант" }}
                    errors={errors}
                  />
                </label>
                <FormCalendar
                  label="Дата рождения"
                  name="dateOfBirth"
                  register={register}
                  setValue={setValue}
                  className={styles.userCalendar}
                  registerObj={{ required: "Поле обязательно для заполнения" }}
                  error={ageError}
                  setError={setAgeError}
                  errors={errors}
                />
                <Input
                  isFormField={true}
                  name="placeOfBirth"
                  label="Место рождения"
                  variant="bordered-green"
                  size="full"
                  register={register}
                  registerObj={{ required: "Поле обязательно для заполнения" }}
                  errors={errors}
                />
                <div>
                  <SearchAddressInput
                    defaultAddress={item?.physicalPerson?.registrationAddress}
                    data={data}
                    setSearch={setSearch}
                    defaultValues={
                      userData?.user.physicalPerson.registrationAddress || {}
                    }
                    setOtherToAddress={setOtherToAddress}
                    otherToAddress={otherToAddress}
                    formatAddress={formatAddress}
                    ref={addressRef}
                    addressError={addressError}
                    setAddressError={setAddressError}
                  />
                </div>
                <Input
                  isFormField={true}
                  name="citizenship"
                  label="Гражданство"
                  variant="bordered-green"
                  size="full"
                  register={register}
                  errors={errors}
                  value="РФ"
                  placeholder="РФ"
                  readOnly
                />
                <Input
                  isFormField={true}
                  name="snils"
                  label="СНИЛС"
                  variant="bordered-green"
                  mask="999-999-999-99"
                  size="full"
                  setValue={setValue}
                  register={register}
                  registerObj={{
                    required: "Поле обязательно для заполнения",
                    minLength: {
                      value: 11,
                      message: "Минимум 11 символов",
                    },
                  }}
                  placeholder="000-000-000-00"
                  onKeyPress={(e) => {
                    checkValidation(e, snilsValidation);
                  }}
                  errors={errors}
                />
                <Input
                  isFormField={true}
                  name="inn"
                  label="ИНН"
                  variant="bordered-green"
                  size="full"
                  register={register}
                  mask="999999999999"
                  placeholder="000000000000"
                  registerObj={{
                    maxLength: {
                      value: 12,
                      message: "Максимум 12 цифр",
                    },

                    required: "Поле обязательно для заполнения",
                  }}
                  onKeyPress={(e) => {
                    checkValidation(e, innValidation);
                  }}
                  errors={errors}
                />
              </div>
              <div className={styles.documents}>
                <p className={styles.documentsTitle}>Удостоверение личности</p>
                <Select
                  isFormSelect={true}
                  label="Документ"
                  options={["Паспорт", "Загранпаспорт"]}
                  variant="bordered-green"
                  size="full"
                  name="documents"
                  register={register}
                />
                <label className={styles.series}>
                  <>
                    <Input
                      isFormField={true}
                      name="series"
                      label="Серия и номер"
                      variant="bordered-green"
                      register={register}
                      mask="9999"
                      registerObj={{
                        required: true,
                      }}
                      errors={errors}
                      className={styles.seriesField}
                      placeholder="0000"
                    />
                    <Input
                      isFormField={true}
                      name="number"
                      variant="bordered-green"
                      size="full"
                      register={register}
                      registerObj={{
                        required: true,
                      }}
                      mask="999999"
                      errors={errors}
                      className={styles.numberField}
                      placeholder="000000"
                    />
                  </>
                </label>
                <FormCalendar
                  label="Дата выдачи"
                  name="issueDate"
                  register={register}
                  registerObj={{ required: "Поле обязательно для заполнения" }}
                  errors={errors}
                  setValue={setValue}
                  className={styles.userCalendar}
                  error={dateGive}
                  setError={setDateGive}
                  event={"issue"}
                />
                <Input
                  isFormField={true}
                  name="whomIssued"
                  label="Кем выдан"
                  variant="bordered-green"
                  size="full"
                  registerObj={{ required: "Поле обязательно для заполнения" }}
                  register={register}
                  errors={errors}
                />
                <Input
                  isFormField={true}
                  name="departmentCode"
                  label="Код подразделения"
                  mask="999-999"
                  variant="bordered-green"
                  registerObj={{ required: "Поле обязательно для заполнения" }}
                  size="full"
                  register={register}
                  errors={errors}
                  placeholder="000-000"
                />
              </div>
            </div>
            <SignatureForm
              formStep={formStep}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              setFormStep={setFormStep}
              id={id}
              register={register}
              setValue={setValue}
              errors={errors}
              item={item}
            />
          </div>
          <div className={styles.formButtons}>
            <Button
              type="submit"
              variant="green"
              className={styles.formBtn}
              onClick={() =>
                (formatAddress === "" ||
                  item?.physicalPerson?.registrationAddress === undefined) &&
                setAddressError(true)
              }
              disabled={ageError !== ""}
            >
              Сохранить
            </Button>
            <Button
              variant="red"
              className={styles.deleteForm}
              onClick={(e) => {
                e.preventDefault();
                setDeleteShow(true);
              }}
            >
              Удалить пользователя
            </Button>
          </div>
        </div>
      </form>
      <DeleteUserModal
        hideModal={hideModal}
        setDeleteShow={setDeleteShow}
        deleteShow={deleteShow}
        usersData={usersData}
        userId={item?.uuid}
      />
    </>
  );
};
