import { useEffect, useMemo, useState } from "react";
import { Button } from "../../../../../Button";
import { ModalWindow } from "../../../../../ModalWindow";
import styles from "./ParticipantCardWithAgent.module.scss";
import { BasketIcon } from "../../../../../../assets/icons/BasketIcon";
import { CompanyRepresentativesBaseFinder } from "../CompanyRepresentativesBaseFinder/CompanyRepresentativesBaseFinder";
import { IndividualBaseFinder } from "../IndividualBaseFinder/IndividualBaseFinder";
import { EntityBaseFinder } from "../EntityBaseFinder/EntityBaseFinder";
import { EntityAgentBaseFinder } from "../EntityAgentBaseFinder/EntityAgentBaseFinder";
import { newRoolParticipantRole } from "../../../../../../constants/constants";

const EntityInfo = ({ data }) => {
	return (
		<>
			{(data?.node?.shortName || data?.shortName || data?.node?.legalPerson?.shortName || data?.legalPerson) &&
				<div className={styles.name}>
					<strong>{data?.node?.shortName || data?.shortName || data?.node?.legalPerson?.shortName || data?.legalPerson?.shortName}</strong>
				</div>
			}

			<div className={styles.representative}>
				{(data?.representative?.physicalPerson || data?.representative || data?.node?.representative || data?.representative) ? (
					<>
						{data?.representative?.physicalPerson &&
							<strong>{data?.representative?.physicalPerson?.lastName + " " + data?.representative?.physicalPerson?.firstName + " " + data?.representative?.physicalPerson?.patronymic}</strong>
						}
						{data?.node?.representative &&
							<strong>{data?.node?.representative?.lastName + " " + data?.node?.representative?.firstName + " " + data?.node?.representative?.patronymic}</strong>
						}
						{data?.representative &&
							<strong>{data?.representative?.lastName + " " + data?.representative?.firstName + " " + data?.representative?.patronymic}</strong>
						}
						{data?.representative?.legalAuthorityRecord?.title && (
							<div>
								<span className={styles.document}>
									{data?.representative?.legalAuthorityRecord?.title} до{" "}
									{data?.representative?.documentLimit}
								</span>
							</div>
						)}
					</>
				) : ( data?.value?.node?.physicalPersonRepresentative ?
					<div className={styles.representative}>

						<div className={styles.name}>
							<strong>{data?.value?.node?.representingLegalPerson?.shortName}</strong>
						</div>

						<strong>{data?.value?.node?.physicalPersonRepresentative?.lastName + " " + data?.value?.node?.physicalPersonRepresentative?.firstName + " " + data?.value?.node?.physicalPersonRepresentative?.patronymic}</strong>
						{data?.representative?.legalAuthorityRecord?.title && (
							<div>
								<span className={styles.document}>
									{data?.representative?.legalAuthorityRecord?.title} до{" "}
									{data?.representative?.documentLimit}
								</span>
							</div>
						)}
					</div>
					:
					<div>
						<span className={styles.document}>
							Нет представителей
						</span>
					</div>
				)}
			</div>
		</>
	);
};

export const ParticipantCardWithAgent = ({ deleteAllCard, data, setParticipantType, setParticipants }) => {
	const [entityType, setEntityType] = useState("entity");
	const [individualData, setIndividualData] = useState(null);
	const [individualAgentData, setIndividualAgentData] = useState(null);
	const [entityAgentData, setEntityAgentData] = useState(null);
	const [entityData, setEntityData] = useState(null);
	const [showEntityForm, setShowEntityForm] = useState(false);
	const [showIndividualForm, setShowIndividualForm] = useState(false);
	const [closeIndividualForm, setCloseIndividualForm] = useState(false);
	const agentData = useMemo(() => {
		if (individualAgentData?.id) {
			return individualAgentData;
		}

		if (entityAgentData?.id) {
			return entityAgentData;
		}

		return null;
	}, [individualAgentData, entityAgentData]);

	const showCard =
		individualData !== null || entityData?.id || agentData?.id

	useEffect(() => {
		if (data?.physicalPerson?.uuid) {
			setIndividualData(data?.physicalPerson);
			setEntityData(null);
			setEntityAgentData(null);
			setIndividualAgentData(null);
		}
		if (data?.legalPerson?.uuid) {
			setEntityData({...data?.legalPerson, representative: data.representative});
			setIndividualData(null);
			setEntityAgentData(null);
			setIndividualAgentData(null);
		}
		if ( data?.participantType === 'REPRESENTED_LEGAL_PERSON') {
			setEntityAgentData({...data?.applicant, representative: data.representative});
			setEntityData(data?.legalPerson);
			setIndividualData(null);
			setIndividualAgentData(null);
		}
		if ( data?.participantType === 'REPRESENTED_PHYSICAL_PERSON') {
			setIndividualAgentData({...data?.applicant, representative: data.representative});
			setEntityData(null);
			setIndividualData(data?.physicalPerson);
			setEntityAgentData(null);
		}
	}, [data])

	const deleteCard = () => {
		setEntityAgentData(null);
		setIndividualAgentData(null);
		setEntityData(null);
		setIndividualData(null);
	};

	const openEntityModal = (type) => {
		setShowEntityForm(true);
		setEntityType(type);
	};

	return (
		<>
			<div className={styles.container}>
				<div className={styles.containerInner}>
					{showCard && (
						<div className={styles.infoCard}>
							<div className={styles.main}>
								{agentData?.id && (
									<div className={styles.infoCol}>
										<div className={styles.label}>
											Агент
										</div>

										<EntityInfo data={agentData} />
									</div>
								)}

								{entityData?.fullName && (
									<div className={styles.infoCol}>
										<div className={styles.label}>
											Юрлицо
										</div>

										<EntityInfo data={entityData} />
									</div>
								)}

								{individualData !== null && (
									<div className={styles.infoCol}>
										<div className={styles.label}>
											Физлицо
										</div>

										<div className={styles.name}>
											<strong>
												{individualData?.lastName}{" "}
												{individualData?.firstName}{" "}
												{individualData?.patronymic}
											</strong>
										</div>

										<div
											className={styles.representative}
										/>
									</div>
								)}
							</div>

							<Button
								variant="transparent"
								type="button"
								className={styles.cardDeleteButton}
								onClick={deleteCard}
							>
								<BasketIcon />
							</Button>
						</div>
					)}

					{!individualAgentData?.id && 
					!entityAgentData?.id && 
					!individualData?.id &&
					!entityData?.id &&
						<>
							<Button
								variant="transparent"
								className={styles.addBtn}
								type="button"
								onClick={() => setShowIndividualForm(true)}
							>
								<span>+&nbsp;ЮЛ - агент ФЛ</span>
							</Button>
							<Button
								variant="transparent"
								className={styles.addBtn}
								type="button"
								onClick={() => openEntityModal("agent")}
							>
								<span>+&nbsp;ЮЛ - агент ЮЛ</span>
							</Button>
							<Button
								variant="transparent"
								className={styles.addBtn}
								type="button"
								onClick={() => setCloseIndividualForm(true)}
							>
								<span>+&nbsp;Физлицо</span>
							</Button>
							<Button
								variant="transparent"
								className={styles.addBtn}
								type="button"
								onClick={() => openEntityModal("entity")}
							>
								<span>+&nbsp;Юрлицо</span>
							</Button>
						</>
					}
				</div>
				<div className={styles.deleteBtn}>
					<Button
						variant="transparent"
						type="button"
						onClick={() => {deleteAllCard(data);}}
					>
						<BasketIcon />
					</Button>
				</div>
			</div>

			<ModalWindow
				show={showIndividualForm}
				setShowModal={() => setShowIndividualForm(false)}
				dialogClassName={styles.modal}
				className={styles.modalInner}
			>
				<CompanyRepresentativesBaseFinder
					setShow={setShowIndividualForm}
					participantType={showIndividualForm.type}
					setData={setIndividualAgentData}
					setIndividualData={setIndividualData}
					cardId={data}
					setParticipantType={setParticipantType}
				/>
			</ModalWindow>

			<ModalWindow
				show={closeIndividualForm}
				setShowModal={() => setCloseIndividualForm(false)}
				dialogClassName={styles.modal}
				className={styles.modalInner}
			>
				<IndividualBaseFinder
					individualData={individualData}
					setData={setIndividualData}
					setShow={setCloseIndividualForm}
					participantType={"PHYSICAL_PERSON"}
					cardId={data}
					setParticipantType={setParticipantType}
					role={newRoolParticipantRole}
				/>
			</ModalWindow>

			<ModalWindow
				show={showEntityForm}
				setShowModal={() => setShowEntityForm(false)}
				dialogClassName={styles.modal}
				className={styles.modalInner}
			>
				{entityType === "agent" ?
					<EntityAgentBaseFinder
						setData={setEntityAgentData}
						setEntityData={setEntityData}
						setShow={setShowEntityForm}
						cardId={data}
						role={newRoolParticipantRole}
						setParticipantType={setParticipantType}
					/>
					: 
					<EntityBaseFinder
						setData={setEntityData}
						setShow={setShowEntityForm}
						cardId={data}
						role={newRoolParticipantRole}
						setParticipantType={setParticipantType}
					/>
				}
			</ModalWindow>
		</>
	);
};
