export const ArrowLeft = () => {
	return (
		<svg
			width="17"
			height="21"
			viewBox="0 0 17 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.1656 6.87095C10.3156 7.02097 10.3998 7.22442 10.3998 7.43655C10.3998 7.64868 10.3156 7.85213 10.1656 8.00215L7.5312 10.6365L10.1656 13.2709C10.3113 13.4218 10.392 13.6239 10.3901 13.8337C10.3883 14.0434 10.3042 14.2441 10.1559 14.3924C10.0075 14.5407 9.80688 14.6249 9.59712 14.6267C9.38737 14.6285 9.18528 14.5479 9.0344 14.4021L5.8344 11.2021C5.68443 11.0521 5.60017 10.8487 5.60017 10.6365C5.60017 10.4244 5.68443 10.221 5.8344 10.0709L9.0344 6.87095C9.18443 6.72097 9.38787 6.63672 9.6 6.63672C9.81213 6.63672 10.0156 6.72097 10.1656 6.87095Z"
				fill="#298D14"
			/>
		</svg>
	);
};
