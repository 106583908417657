import {useEffect, useState} from "react";
import styles from "./CryptoToken.module.scss";
import { CryptoPro } from "ruscryptojs";
import { ToastComponent } from "../../ToastComponent";
import { CertificatesList } from "./CertificatesList/CertificatesList";
import { Button } from "../../Button";
import { useMutation } from "@apollo/client";
import { DOCUMENT_SIGNATURE_TOKEN } from "../../../services/queries/mutations/physicalPersons/tokenSign";
import cn from 'classnames'
import { APPLICATION_DOCUMENTS_TOKEN_SIGN } from "../../../services/queries/mutations/applications/signatures/tokenSign";
import { APPLICATION_INFO } from "../../../services/queries/queries/applications/applicationInfo";

/* 
Комопнент для подписания документа в карточке физлица криптотокеном

Пропсы: 

toastRef - пропс для отслеживания области, при клике на которую не будет закрываться toastComponent

fileName - имя файла, которое необходимо для подписания

onClickHandler - действие для кнопки подписания документа через приложение,

documentId - id документа для передачи в graphql вместе с ответом от функции подписания

signatureActive - информация о наличии электронной подписи у физлица, если подписи нет - подписать документ через приложение нельзя, но можно криптотокеном если стоит плагин (за проверку плагина отчечает checkPlugin). Если и плагина нет, то и кроптокеном документ подписать нельзя.


*/

export const CryptoToken = ({
  toastRef,
  fileName,
  file,
  onClickHandler,
  documentId,
  signatureActive,
  showEdsBtn = true,
  tokenBtnContent = 'Подписать токеном',
  tokenBtnClassName,
  toastClassName,
  setCheckPluginStatus,
  applicationId,
  applicationInfo
}) => {
  const [checkPlugin, setCheckPlugin] = useState(null);
  const [currentCetificate, setCurrentCertificate] = useState("");
  const [certificateInfo, setCertificateInfo] = useState([]);
  const [infoLoading, setInfoLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [convertedFile, setConvertedFile] = useState(null)

  const [documentSign] = useMutation(DOCUMENT_SIGNATURE_TOKEN);
  const [applicationDocumentSign] = useMutation(APPLICATION_DOCUMENTS_TOKEN_SIGN)

  const cryptopro = new CryptoPro();

  cryptopro.init().then(function (info) {
    console.log("Initialized", info);
  });

  const convertFileToBase64 = async (file) => {
    const token = localStorage.getItem('accessToken');
    const response = await fetch(file, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });
  
    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`);
    }
  
    const pdfFile = await response.blob(); // Получаем blob из ответа
    const fileReader = new FileReader();
  
    return new Promise((resolve, reject) => {
      fileReader.onload = (e) => {
        const header = ";base64,";
        const base64Data = e.target.result;
        const base64File = base64Data.substr(base64Data.indexOf(header) + header.length);
        resolve(base64File)// Возвращаем base64 как результат
      };
  
      fileReader.onerror = (error) => {
        reject(error); // Обрабатываем ошибку
      };
  
      fileReader.readAsDataURL(pdfFile); // Считываем blob как Data URL
    });
  };

  const handleFileConversion = async (file) => {
    try {
      const base64File = await convertFileToBase64(file);
      setConvertedFile(base64File);
    } catch (error) {
      console.error("Error converting file:", error);
    }
  };

  useEffect(() => {
    if(file){
      handleFileConversion(file)
    }
  }, [file])


  const signatureDocument = () => {
    
    if (convertedFile && currentCetificate) {
      cryptopro
        .signData(convertedFile, currentCetificate)
        .then(function (data) {
          if (data && documentId) {
            documentSign({
              variables: {
                base64File: data,
                signatureId: documentId,
              },
            }).then(() => {
              if(applicationInfo && applicationId){
                applicationInfo({
                  variables: {
                    id: applicationId
                  },
                  skip: !applicationInfo || !applicationId
                })
              }
            });
          }

          // if(data && participantUuid){
          //   applicationDocumentSign({
          //     variables: {
          //       base64File: data,
          //       documentUuid,
          //       participantUuid
          //     }
          //   }).then(() => setIsChecked(false))
          // }
        });
    }

    setShowToast(false);
  };

  useEffect(() => {
    setInfoLoading(true);
    if (currentCetificate) {
      cryptopro.certificateInfo(currentCetificate).then((data) => {
        setCertificateInfo([data]);
        setInfoLoading(false);
      });
    }
  }, [currentCetificate]);

  useEffect(() => {
    if(setCheckPluginStatus && checkPlugin?.version){
      setCheckPluginStatus(true)
    }

    if(setCheckPluginStatus && !checkPlugin?.version){
      setCheckPluginStatus(false)
    }
  }, [checkPlugin, setCheckPluginStatus])

  console.log(documentId, 'documentid')

  return (
    <div>
      <div className={styles.signatureButtons}>
        {showEdsBtn && (
          <Button
            variant="green"
            type="button"
            disabled={!signatureActive ? true : false}
            className={styles.signatureBtn}
            onClick={onClickHandler}
          >
            Подписать
          </Button>
        )}
        <Button
          type="button"
          variant="outline-green"
          onClick={() => setShowToast((prev) => !prev)}
          className={cn(styles.signatureBtn, tokenBtnClassName)}
          disabled={!checkPlugin?.version ? true : false}
        >
          {tokenBtnContent}
        </Button>
      </div>
      <ToastComponent
        isOpen={showToast}
        setIsOpen={setShowToast}
        toastRef={toastRef}
        className={cn(styles.cryptoToast, toastClassName)}
      >
        <div>
          <h3 className={styles.toastTitle}>Выберите сертификат</h3>
          <CertificatesList
            value={currentCetificate}
            setValue={setCurrentCertificate}
            cryptopro={cryptopro}
            setPlugin={setCheckPlugin}
          />
          <div className={styles.toastInfoWrapper}>
            <h3 className={styles.toastTitle}>Информация о сертификате</h3>
            {!infoLoading ? (
              <ul className={styles.toastInfoList}>
                {certificateInfo &&
                  certificateInfo.length > 0 &&
                  certificateInfo.map((info) => (
                    <>
                      <li key={info.SerialNumber}>
                        <span>Алгоритм:</span>
                        <span className={styles.listInfo}>
                          {info.Algorithm}
                        </span>
                      </li>
                      <li key={info.SerialNumber}>
                        <span>Имя:</span>
                        <span className={styles.listInfo}>{info.Name}</span>
                      </li>
                      <li key={info.SerialNumber}>
                        <span>Провайдер:</span>
                        <span className={styles.listInfo}>
                          {info.ProviderName}
                        </span>
                      </li>
                      <li key={info.SerialNumber}>
                        <span>Серийный номер:</span>
                        <span className={styles.listInfo}>
                          {info.SerialNumber}
                        </span>
                      </li>
                      <li key={info.SerialNumber}>
                        <span>Действителен после:</span>
                        <span className={styles.listInfo}>
                          {String(
                            new Date(info.ValidFromDate).toLocaleString("ru-RU")
                          )}
                        </span>
                      </li>
                      <li key={info.SerialNumber}>
                        <span>Действителен до:</span>
                        <span className={styles.listInfo}>
                          {String(
                            new Date(info.ValidToDate).toLocaleString("ru-RU")
                          )}
                        </span>
                      </li>
                    </>
                  ))}
              </ul>
            ) : null}
          </div>
          <div className={styles.signatureWrapper}>
            {fileName && <h3>Вы подписываете документ {fileName}</h3>}
            <Button variant="green" type="button" onClick={signatureDocument}>
              Подписать
            </Button>
          </div>
        </div>
      </ToastComponent>
    </div>
  );
};