import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
    participants:  []
};

const applicationSlice = createSlice({
	name: "application",
	initialState: initialState,
	reducers: {
		setParticipants(state, action) {
			return {
				...state,
				participants: action.payload,
			};
		},
		setParticipant(state, action) {
			const newParticipants = state.participants.map(item => {
				console.log(item.id, action.payload?.id)
				if(item.id === action.payload?.id) {
					return {...item, ...action.payload}
				} else {
					return item;
				}
			})

			return {
				...state,
				participants: newParticipants,
			};
		},
		deleteInnerParticipant(state, action) {
			const newParticipants = state.participants.map(item => {
				if(item.id === action.payload?.id) {
					return action.payload
				} else {
					return item;
				}
			})

			return {
				...state,
				participants: newParticipants,
			};
		},
		setParticipantRole(state, action) {
			const newParticipants = state.participants.map(item => {
				if(item.id === action.payload?.id || item.uuid === action.payload?.id) {
					
					return {
						...item,
						role: action.payload?.role
					}
				} else {
					return item;
				}
			})

			return {
				...state,
				participants: newParticipants,
			};
		},
        setParticipantUpdated(state, action) {
            const newParticipant = state.participants.map(item => {
                if(item.uuid === action.payload.uuid) {
                    return {
                        ...item,
                        isUpdated: true
                    }
                } else {
                    return item;
                }
            });
			return {
				...state,
				participants: newParticipant,
			};
		},
		setParticipantShare(state, action) {
            const newParticipant = state.participants.map(item => {
                if(item.id === action.payload?.id || item.uuid === action.payload?.id) {
                    return {
                        ...item,
                        shareDenominator: action.payload?.shareDenominator,
						shareNumerator: action.payload?.shareNumerator
                    }
                } else {
                    return item;
                }
            });
			return {
				...state,
				participants: newParticipant,
			};
		},
	},
	selectors: {
		selectParticipants: (state) => {
			return state.participants;
		},
	},
});

export const { setParticipants, setParticipantUpdated, deleteInnerParticipant, setParticipant, setParticipantRole, setParticipantShare } = applicationSlice.actions;
export const { selectParticipants } = applicationSlice.selectors;
export default applicationSlice.reducer;
