export const AddFile = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 3.33366C8.23124 3.33366 6.53555 4.03604 5.2853 5.28628C4.03506 6.53652 3.33268 8.23222 3.33268 10.0003C3.33268 10.8758 3.50512 11.7427 3.84015 12.5515C4.17518 13.3604 4.66625 14.0953 5.2853 14.7144C5.90436 15.3334 6.63929 15.8245 7.44813 16.1595C8.25696 16.4946 9.12387 16.667 9.99935 16.667C10.8748 16.667 11.7417 16.4946 12.5506 16.1595C13.3594 15.8245 14.0943 15.3334 14.7134 14.7144C15.3325 14.0953 15.8235 13.3604 16.1585 12.5515C16.4936 11.7427 16.666 10.8758 16.666 10.0003C16.666 8.23222 15.9636 6.53652 14.7134 5.28628C13.4632 4.03604 11.7675 3.33366 9.99935 3.33366ZM4.10679 4.10777C5.6696 2.54497 7.78921 1.66699 9.99935 1.66699C12.2095 1.66699 14.3291 2.54497 15.8919 4.10777C17.4547 5.67057 18.3327 7.79019 18.3327 10.0003C18.3327 11.0947 18.1171 12.1783 17.6983 13.1894C17.2796 14.2004 16.6657 15.1191 15.8919 15.8929C15.1181 16.6667 14.1994 17.2805 13.1884 17.6993C12.1773 18.1181 11.0937 18.3337 9.99935 18.3337C8.905 18.3337 7.82137 18.1181 6.81032 17.6993C5.79927 17.2805 4.88061 16.6667 4.10679 15.8929C3.33297 15.1191 2.71914 14.2004 2.30035 13.1894C1.88156 12.1783 1.66602 11.0947 1.66602 10.0003C1.66602 7.79019 2.54399 5.67057 4.10679 4.10777ZM9.99935 6.66699C10.4596 6.66699 10.8327 7.04009 10.8327 7.50033V9.16699H12.4993C12.9596 9.16699 13.3327 9.54009 13.3327 10.0003C13.3327 10.4606 12.9596 10.8337 12.4993 10.8337H10.8327V12.5003C10.8327 12.9606 10.4596 13.3337 9.99935 13.3337C9.53911 13.3337 9.16602 12.9606 9.16602 12.5003V10.8337H7.49935C7.03911 10.8337 6.66602 10.4606 6.66602 10.0003C6.66602 9.54009 7.03911 9.16699 7.49935 9.16699H9.16602V7.50033C9.16602 7.04009 9.53911 6.66699 9.99935 6.66699Z"
        fill="#3F3F46"
      />
    </svg>
  );
};
