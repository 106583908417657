import { useState, useEffect } from "react";
import { GET_ADDRESSES } from "../services/queries/queries/search";
import { useQuery } from "@apollo/client";

const useInputSearch = (initialSearch, queryName) => {
  const [search, setSearch] = useState(initialSearch);
  const [debouncedSearch, setDebouncedSearch] = useState(initialSearch);
  const [data, setData] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search);
    }, 1000);

    return () => clearTimeout(timer);
  }, [search]);

  // Тут надо доделать позже
  const selectedQuery =
    queryName === "GET_ADDRESSES" ? GET_ADDRESSES : GET_ADDRESSES;

  const { data: queriedData } = useQuery(selectedQuery, {
    variables: {
      address: debouncedSearch,
    },
  });

  useEffect(() => {
    if (queriedData !== undefined) {
      setData(queriedData);
    }
  }, [queriedData]);

  return {
    search,
    setSearch,
    data,
  };
};

export default useInputSearch;
