/* 

Функция для форматирования номера телефона.

Если телефон (строка) передан и его длина 12 символов, то возвращается форматированный номер телефона в формате +7 000-000-00-00

В ином случае возвращается неизменененное значение переданное как аргумент функции


*/

export const formatPhoneNumber = (phone) => {
  if (phone && phone.length === 12) {
    // Добавим символ + перед 7
    let formattedPhone = "+" + phone[1] + " ";

    let part1 = phone.substring(2, 5);
    let part2 = phone.substring(5, 8);
    let part3 = phone.substring(8, 10);
    let part4 = phone.substring(10, 12);

    formattedPhone += part1 + " " + part2 + "-" + part3 + "-" + part4;

    return formattedPhone;
  } else {
    return phone;
  }
}