import { ArrowLeft } from "../../../assets/icons/ArrowLeft";
import { Link, useParams, useLocation } from "react-router-dom";
import styles from './AdditionalApp.module.scss'
import { useLazyQuery } from "@apollo/client";
import { APPLICATION_INFO } from "../../../services/queries/queries/applications/applicationInfo";
import { useEffect } from "react";
import { AdditionalApplication } from "../../../components/Forms/ui/AdditionalApplication/AdditionalApplication";

export const AdditionalApp = () => {
  const location = useLocation();
  const {id} = useParams()
  const [applicationInfo, {data}] = useLazyQuery(APPLICATION_INFO, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if(id){
      applicationInfo({
        variables: {
          id
        }
      })
    }
  }, [id, applicationInfo])

  return(
    <div className={styles.container}>
			<div className={styles.applicationFormContainer}>
				<Link to="/applications/" className={styles.backBtn}>
					<ArrowLeft /> <span>Кабинет</span>
				</Link>
				<AdditionalApplication 
          applicationData={location.pathname === `/additional/edit/${id}` ? null : data} 
          applicationInfo={location.pathname === `/additional/edit/${id}` ? data : null}
          uuid={data?.application?.uuid} 
          id={id}
          queryApplicationInfo={applicationInfo}
        />
			</div>
		</div>
  )
}