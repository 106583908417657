// import React from "react";
// import {
//   ApolloClient,
//   ApolloLink,
//   ApolloProvider,
//   InMemoryCache,
//   split,
// } from "@apollo/client";
// import { Observable, getMainDefinition } from "@apollo/client/utilities";
// import { setContext } from "@apollo/client/link/context";
// import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
// import { createClient } from "graphql-ws";
// import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
// import { onError } from "@apollo/client/link/error";
// import { REFRESH_TOKEN } from "./queries";
// import { GraphQLError } from "graphql"

// const baseUrl = process.env.REACT_APP_API_BASE_URL;
// const websocketUrl = process.env.REACT_APP_API_BASE_URL_WS;
// const refresh = localStorage.getItem('refreshToken')

// const refreshToken = async () => {
//   try {
//     const refreshResolverResponse = await client.mutate({
//       mutation: REFRESH_TOKEN,
//       variables: {
//         refreshToken: refresh
//       }
//     });

//     const accessToken = refreshResolverResponse?.refreshToken?.accessToken;
//     const refreshToken = refreshResolverResponse?.refreshToken?.refreshToken;
//     localStorage.setItem('accessToken', accessToken || '');
//     localStorage.setItem('refreshToken', refreshToken || '');
//     return accessToken;
//   } catch (err) {
//     localStorage.clear();
//     throw err;
//   }
// };

// const uploadLink = createUploadLink({
//   uri: baseUrl,
// });

// const wsLink = new GraphQLWsLink(
//   createClient({
//     url: websocketUrl,
//   })
// );

// const authLink = setContext((_, { headers }) => {
//   const token = localStorage.getItem("accessToken");

//   // Возвращаем новые заголовки с добавленным токеном
//   return {
//     headers: {
//       ...headers,
//       Authorization: token ? `JWT ${token}` : "",
//     },
//   };
// });

// const errorLink = onError(
//   ({ graphQLErrors, networkError, operation, forward }) => {
//     if (graphQLErrors) {
//       for (let err of graphQLErrors) {
//         switch (err.extensions.code) {
//           case 'UNAUTHENTICATED':
//             // ignore 401 error for a refresh request
//             if (operation.operationName === 'refreshToken') return;

//             const observable = new Observable(
//               (observer) => {
//                 // used an annonymous function for using an async function
//                 (async () => {
//                   try {
//                     const accessToken = await refreshToken();

//                     if (!accessToken) {
//                       throw new GraphQLError('Empty AccessToken');
//                     }

//                     // Retry the failed request
//                     const subscriber = {
//                       next: observer.next.bind(observer),
//                       error: observer.error.bind(observer),
//                       complete: observer.complete.bind(observer),
//                     };

//                     forward(operation).subscribe(subscriber);
//                   } catch (err) {
//                     observer.error(err);
//                   }
//                 })();
//               }
//             );

//             return observable;
//         }
//       }
//     }

//     if (networkError) console.log(`[Network error]: ${networkError}`);
//   }
// );

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
//   authLink.concat(uploadLink), // Make sure authLink is concatenated with uploadLink for HTTP requests
//   errorLink
// );

// const client = new ApolloClient({
//   link: splitLink,
//   cache: new InMemoryCache(),
// });

// const ApolloAppProvider = ({ children }) => {
//   return <ApolloProvider client={client}>{children}</ApolloProvider>;
// };

// export default ApolloAppProvider;

// import React from "react";
// import {
//   ApolloClient,
//   ApolloLink,
//   ApolloProvider,
//   InMemoryCache,
//   split,
// } from "@apollo/client";
// import { getMainDefinition } from "@apollo/client/utilities";
// import { setContext } from "@apollo/client/link/context";
// import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
// import { createClient } from "graphql-ws";
// import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
// import { onError } from "@apollo/client/link/error";
// import { REFRESH_TOKEN } from "./queries";
// import { GraphQLError } from "graphql";

// const baseUrl = process.env.REACT_APP_API_BASE_URL;
// const websocketUrl = process.env.REACT_APP_API_BASE_URL_WS;

// let client; // Объявляем клиент здесь

// const refreshToken = async () => {
//   try {
//     const refresh = localStorage.getItem('refreshToken');
//     const refreshResolverResponse = await client.mutate({
//       mutation: REFRESH_TOKEN,
//       variables: {
//         refreshToken: refresh
//       }
//     });

//     const accessToken = refreshResolverResponse.data?.refreshToken.accessToken;
//     const newRefreshToken = refreshResolverResponse.data?.refreshToken.refreshToken;
//     localStorage.setItem('accessToken', accessToken || '');
//     localStorage.setItem('refreshToken', newRefreshToken || '');
//     return accessToken;
//   } catch (err) {
//     localStorage.clear();
//     throw err;
//   }
// };

// const uploadLink = createUploadLink({
//   uri: baseUrl,
// });

// const wsLink = new GraphQLWsLink(
//   createClient({
//     url: websocketUrl,
//   })
// );

// const authLink = setContext(async (_, { headers }) => {
//   const token = localStorage.getItem("accessToken");

//   return {
//     headers: {
//       ...headers,
//       Authorization: token ? `JWT ${token}` : "",
//     },
//   };
// });

// const errorLink = onError(
//   ({ graphQLErrors, networkError, operation, forward }) => {
//     if (graphQLErrors) {
//       for (let err of graphQLErrors) {
//         if (err?.message === 'Signature has expired') {
//           return new Promise((resolve, reject) => {
//             (async () => {
//               try {
//                 const accessToken = await refreshToken();

//                 if (!accessToken) {
//                   throw new GraphQLError('Empty AccessToken');
//                 }

//                 operation.setContext(({ headers = {} }) => ({
//                   headers: {
//                     ...headers,
//                     Authorization: `JWT ${accessToken}`,
//                   },
//                 }));

//                 resolve(forward(operation));
//               } catch (error) {
//                 reject(error);
//               }
//             })();
//           });
//         }
//       }
//     }

//     if (networkError) console.log(`[Network error]: ${networkError}`);
//   }
// );

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
//   ApolloLink.from([errorLink, authLink.concat(uploadLink)])
// );

// client = new ApolloClient({
//   link: splitLink,
//   cache: new InMemoryCache(),
// });

// const ApolloAppProvider = ({ children }) => {
//   return <ApolloProvider client={client}>{children}</ApolloProvider>;
// };

// export default ApolloAppProvider;

import React from "react";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  split,
  Observable,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { onError } from "@apollo/client/link/error";
import { REFRESH_TOKEN } from "./queries";
import { GraphQLError } from "graphql";

const baseUrl = process.env.REACT_APP_API_BASE_URL;
const websocketUrl = process.env.REACT_APP_API_BASE_URL_WS;

let client; // Объявляем клиент здесь

const refreshToken = async () => {
  try {
    const refresh = localStorage.getItem('refreshToken');
    const refreshResolverResponse = await client.mutate({
      mutation: REFRESH_TOKEN,
      variables: {
        refreshToken: refresh
      }
    });
    const accessToken = refreshResolverResponse.data?.refreshToken.token;
    const newRefreshToken = refreshResolverResponse.data?.refreshToken.refreshToken;
    localStorage.setItem('accessToken', accessToken || '');
    localStorage.setItem('refreshToken', newRefreshToken || '');
    return accessToken;
  } catch (err) {
    // localStorage.clear();
    throw err;
  }
};

const uploadLink = createUploadLink({
  uri: baseUrl,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: websocketUrl,
  })
);

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("accessToken");

  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : "",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err?.message === 'Signature has expired') {
          return new Observable((observer) => {
            (async () => {
              try {
                const accessToken = await refreshToken();

                if (!accessToken) {
                  throw new GraphQLError('Empty AccessToken');
                }

                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    Authorization: accessToken ? `JWT ${accessToken}` : '',
                  },
                }));

                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                forward(operation).subscribe(subscriber);
              } catch (error) {
                observer.error(error);
              }
            })();
          });
        }
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  ApolloLink.from([errorLink, authLink.concat(uploadLink)])
);

client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

const ApolloAppProvider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloAppProvider;


