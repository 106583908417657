import { gql } from "@apollo/client";

export const READ_DOCUMENT = gql`
  mutation ReadDocument(
    $description: String
    $isConfirmed: Boolean
    $personDocumentUuid: UUID!
  ) {
    hasReadPhysicalPersonDocument(
      description: $description
      isConfirmed: $isConfirmed
      personDocumentUuid: $personDocumentUuid
    ) {
      physicalPersonDocument {
        isConfirmed
      }
    }
  }
`;
