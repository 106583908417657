import { gql } from "@apollo/client";

export const PERSON_INFO = gql`
  query GetPerson($id: ID!) {
    physicalPerson(id: $id) {
      uuid
      firstName
      lastName
      patronymic
      dateOfBirth
      placeOfBirth
      citizenship
      snils
      inn

      user {
        phone
        email
      }
      signatures {
        updatedAt
        type
        state
        isActive
        id
        expireDate
        dateRelease
        createdAt
        consumerUuid
        connector
      }
      signatureRelease {
        connector
        consumerUuid
        createdAt
        dateRelease
        expireDate
        id
        isActive
        state
        type
        updatedAt
      }
      signaturesCurrent {
        connector
        consumerUuid
        createdAt
        expireDate
        isActive
        state
        type
      }
      signaturesActive {
        state
        type
        isActive
        createdAt
        consumerUuid
        expireDate
        connector
      }
      gender
      document {
        type
        series
        number
        issuedBy
        dateIssue
        code
      }
      registrationAddress {
        region
        district
        locality
        street
        streetType
        house
        building
        index
        value
        regionKladrId
        apartment
      }

      personDocuments {
        uuid
        document {
          uuid
          title
          presignedUrl
          presignedSigUrl
          file
          sigFile
          documentType {
            name
          }

          documentSignature {
            id
            state
            presignedSignatureFile
          }
        }
      }
    }
  }
`;
