import { useContext, useEffect, useRef } from "react";
import { CloseIcon } from "../../../assets/icons/CloseIcon";
import styles from "./ModalWindow.module.scss";
import Modal from "react-bootstrap/Modal";
import { Context } from "../../../App";

export const ModalWindow = ({ children, className, dialogClassName, modalTitle, size, setShowModal, closeHandler, ...props }) => {

  const {showModal} = useContext(Context)
  const modalRef = useRef(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showModal && modalRef.current && !modalRef.current.contains(e.target)) {
        setShowModal(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [showModal, setShowModal])

  return (
    <Modal
      {...props}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={dialogClassName}
    >
      
      <Modal.Body className={className} ref={modalRef}>{children}</Modal.Body>
      <button className={styles.modalClose} onClick={ (e) => setShowModal(false)}>
        <CloseIcon />
      </button>
    </Modal>
  );
};
