export const CopyIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 4.81836C9.73478 4.81836 9.48043 4.92372 9.29289 5.11125C9.10536 5.29879 9 5.55314 9 5.81836V15.8184C9 16.0836 9.10536 16.3379 9.29289 16.5255C9.48043 16.713 9.73478 16.8184 10 16.8184H18C18.2652 16.8184 18.5196 16.713 18.7071 16.5255C18.8946 16.3379 19 16.0836 19 15.8184V9.23257L14.5859 4.81847L10 4.81836ZM7.87868 3.69704C8.44129 3.13443 9.20435 2.81836 10 2.81836H14.586C15.1163 2.81847 15.6251 3.02922 16.0001 3.40425M16.0001 3.40425L20.414 7.81815C20.414 7.81811 20.414 7.81818 20.414 7.81815C20.789 8.19312 20.9999 8.70181 21 9.23215V15.8184C21 16.614 20.6839 17.3771 20.1213 17.9397C19.5587 18.5023 18.7957 18.8184 18 18.8184H17V19.8184C17 20.614 16.6839 21.3771 16.1213 21.9397C15.5587 22.5023 14.7957 22.8184 14 22.8184H6C5.20435 22.8184 4.44129 22.5023 3.87868 21.9397C3.31607 21.3771 3 20.614 3 19.8184V9.81836C3 9.02271 3.31607 8.25965 3.87868 7.69704C4.44129 7.13443 5.20435 6.81836 6 6.81836H7V5.81836C7 5.02271 7.31607 4.25965 7.87868 3.69704M7 8.81836H6C5.73478 8.81836 5.48043 8.92372 5.29289 9.11125C5.10536 9.29879 5 9.55314 5 9.81836V19.8184C5 20.0836 5.10536 20.3379 5.29289 20.5255C5.48043 20.713 5.73478 20.8184 6 20.8184H14C14.2652 20.8184 14.5196 20.713 14.7071 20.5255C14.8946 20.3379 15 20.0836 15 19.8184V18.8184H10C9.20435 18.8184 8.44129 18.5023 7.87868 17.9397C7.31607 17.3771 7 16.614 7 15.8184V8.81836Z"
        fill="#298D14"
      />
    </svg>
  );
};
