import { gql } from "@apollo/client";

export const UPDATE_PAYMENT_REQUEST = gql`
	mutation updatePaymentRequest(
		$participantUuid: UUID!
        $paymentUuid: UUID!
	) {
		updatePaymentRequest(
			participantUuid: $participantUuid
            paymentUuid: $paymentUuid
		) {
			paymentRequest {
				id
			}
		}
	}
`;