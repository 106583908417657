import { Input } from "../../../../components/Input";
import styles from "./TypeInfoForm.module.scss";
import { FakeCheckbox } from "../../../../components/FakeCheckbox";

export const TypeInfoForm = ({ typeData }) => {
  return (
    <form className={styles.typeInfoForm}>
      <div className={styles.fieldWrapper}>
        <label>Сумма, ₽</label>
        <Input variant="bordered-green" value={typeData?.sum} readOnly={true} />
      </div>
      <div className={styles.fieldWrapper}>
        <label>Площадь из договора, м²</label>
        <Input
          variant="bordered-green"
          value={typeData?.area}
          readOnly={true}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <label>Тип права</label>
        <Input
          variant="bordered-green"
          value={typeData?.ownership ? typeData?.ownership?.title : "-"}
          readOnly={true}
        />
      </div>
      <div className={styles.fieldWrapper}>
        <label>Особенности</label>
        <FakeCheckbox isChecked={typeData?.mortgage} label="Ипотека" />
      </div>
    </form>
  );
};
