export const CalendarIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33333 1.33398C5.70152 1.33398 6 1.63246 6 2.00065V2.66732H10V2.00065C10 1.63246 10.2985 1.33398 10.6667 1.33398C11.0349 1.33398 11.3333 1.63246 11.3333 2.00065V2.66732H12.6667C13.1971 2.66732 13.7058 2.87803 14.0809 3.2531C14.4559 3.62818 14.6667 4.13688 14.6667 4.66732V12.6673C14.6667 13.1978 14.4559 13.7065 14.0809 14.0815C13.7058 14.4566 13.1971 14.6673 12.6667 14.6673H3.33333C2.8029 14.6673 2.29419 14.4566 1.91911 14.0815C1.54404 13.7065 1.33333 13.1978 1.33333 12.6673V4.66732C1.33333 4.13688 1.54404 3.62818 1.91911 3.2531C2.29419 2.87803 2.8029 2.66732 3.33333 2.66732H4.66666V2.00065C4.66666 1.63246 4.96514 1.33398 5.33333 1.33398ZM4.66666 4.00065H3.33333C3.15652 4.00065 2.98695 4.07089 2.86192 4.19591C2.7369 4.32094 2.66666 4.49051 2.66666 4.66732V12.6673C2.66666 12.8441 2.7369 13.0137 2.86192 13.1387C2.98695 13.2637 3.15652 13.334 3.33333 13.334H12.6667C12.8435 13.334 13.013 13.2637 13.1381 13.1387C13.2631 13.0137 13.3333 12.8441 13.3333 12.6673V4.66732C13.3333 4.49051 13.2631 4.32094 13.1381 4.19591C13.013 4.07089 12.8435 4.00065 12.6667 4.00065H11.3333V4.66732C11.3333 5.03551 11.0349 5.33398 10.6667 5.33398C10.2985 5.33398 10 5.03551 10 4.66732V4.00065H6V4.66732C6 5.03551 5.70152 5.33398 5.33333 5.33398C4.96514 5.33398 4.66666 5.03551 4.66666 4.66732V4.00065ZM3.99999 7.33398C3.99999 6.96579 4.29847 6.66732 4.66666 6.66732H11.3333C11.7015 6.66732 12 6.96579 12 7.33398C12 7.70217 11.7015 8.00065 11.3333 8.00065H4.66666C4.29847 8.00065 3.99999 7.70217 3.99999 7.33398Z"
        fill="#64585C"
      />
    </svg>
  );
};
