import { gql } from "@apollo/client";

export const CREATE_SUPPORT_MESSAGE = gql`
  mutation CreateMessage(
  $message: String!
	$ticketId: UUID!
  $file: Upload!
){
  createSupportMessage(
    message: $message
    ticketId: $ticketId
    file: $file
  ){
    supportMessage{
      createdAt
    }
  }
}

`