import { gql } from "@apollo/client";

export const ALL_COMPANIES = gql`
  query AllCompanies($search: String, $first: Int, $offset: Int) {
    allLegalPersons(search: $search, first: $first, offset: $offset) {
      edges {
        node {
          id
          uuid
          fullName
          shortName
          inn
          kpp
          ogrn
          email
          phoneNumber
          representatives {
            legalAuthorityRecord {
              durationStart
              durationStop
              file
              id
              presignedUrlFile
              presignedUrlSigFile
              sigFile
              uuid
              type {
                name
                uuid
              }
            }
            physicalPerson {
              id
              uuid
              firstName
              lastName
              patronymic
              snils
              placeOfBirth
              inn
              gender
            }
          }

          address {
            value
            structure
            streetType
            street
            regionType
            regionKladrId
            region
            oktmo
            okato
            locality
            kladr
            index
            id
            house
            fias
            building
            block
            city
            country
            district
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;
