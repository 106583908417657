import { gql } from "@apollo/client";

export const DOCUMENT_SIGNATURE_TOKEN = gql`
  mutation documentSignToken($base64File: String!, $signatureId: Int!) {
    documentSignToken(base64File: $base64File, signatureId: $signatureId) {
      documentSignature {
        id
        state
        presignedSignatureFile
      }
    }
  }
`;
