import { useForm } from "react-hook-form";
import styles from "./CreateAppealForm.module.scss";
import { StatusLabel } from "../../../StatusLabel/ui/StatusLabel";
import { Input } from "../../../Input";
import { FileField } from "../../../FileField";
import { Button } from "../../../Button";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_TICKET } from "../../../../services/queries/mutations/support/createTicket";
import {
  ALL_READ_TICKET,
  ALL_TICKETS_LIST,
} from "../../../../services/queries/queries/support/allTicketsList";
import { TICKET_INFO } from "../../../../services/queries/queries/support/ticketInfo";
import { CREATE_SUPPORT_MESSAGE } from "../../../../services/queries/mutations/support/createMessage";
import { formattedDateFunc } from "../../../../helpers/formattedDateFunc";
import { READ_APPLICATION } from "../../../../services/queries/mutations/applications/createApplicationPatricipants";
import { READ_MESSAGE_STATUS } from "../../../../services/queries/mutations/support/readMessageStatus";

export const CreateAppealForm = ({
  item,
  setShowModal,
  hideModal,
  uuid,
  id,
  ticketId,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({
    mode: "onChange",
    defaultValues: item,
  });
  const userId = localStorage.getItem("id");
  const [markNotificationAsRead] = useMutation(READ_APPLICATION);
  const token = localStorage.getItem("accessToken");
  const [createTicket] = useMutation(CREATE_TICKET, {
    refetchQueries: [ALL_TICKETS_LIST],
  });

  const { data } = useQuery(TICKET_INFO, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    uuid && markNotificationAsRead({ variables: { ticketId: uuid } });
  }, []);

  const [createMessage] = useMutation(CREATE_SUPPORT_MESSAGE, {
    refetchQueries: [ALL_TICKETS_LIST],
  });

  const [readMessages] = useMutation(READ_MESSAGE_STATUS, {
    refetchQueries: [ALL_READ_TICKET, ALL_TICKETS_LIST],
    fetchPolicy: "no-cache",
    skip: !uuid,
  });

  const onSubmitHandler = async (formData) => {
    try {
      await createTicket({
        variables: {
          description: formData.appealText,
          file: formData.appealFile ? formData.appealFile[0] : null,
        },
      });

      if (!errors?.root?.serverError) {
        setShowModal(false);
      }
    } catch (error) {
      setError("root.serverError", {
        type: "Ошибка при отправке запроса",
      });

      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message }) =>
          setError("root.serverError", {
            type: message.split(".")[0],
          })
        );
      }

      if (error.networkError) {
        setError("root.serverError", {
          type: "Received status code 400",
        });
        console.log(`[Network error]: ${error.networkError}`);
      }
    }
  };

  const editAppealSubmitHandler = async (formData) => {
    try {
      await createMessage({
        variables: {
          ticketId: uuid,
          message: formData.commentText,
          file: formData?.commentAppealFile
            ? formData?.commentAppealFile[0]
            : "",
        },

        skip: !uuid,
      });

      if (!errors?.root?.serverError) {
        if (hideModal) {
          hideModal(null);
        }
      }
    } catch (error) {
      setError("root.serverError", {
        type: "Ошибка при отправке запроса",
      });

      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message }) =>
          setError("root.serverError", {
            type: message.split(".")[0],
          })
        );
      }

      if (error.networkError) {
        setError("root.serverError", {
          type: "Received status code 400",
        });
        console.log(`[Network error]: ${error.networkError}`);
      }
    }
  };
  const downloadFile = (token, url, fileName) => {
    if (fileName) {
      function removeTrailingSlash(str) {
        if (str.endsWith("/")) {
          return str.slice(0, -1);
        }
        return str;
      }
      fetch(url, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Непредвиденная ошибка");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = removeTrailingSlash(url);
          a.download = fileName;
          a.target = "_blank";
          a.rel = "noreferrer";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Ошибка скачивания:", error);
        });
    }
  };

  const formVariantRender = useMemo(() => {
    if (id && data && data.ticket) {
      return (
        <form onSubmit={handleSubmit(editAppealSubmitHandler)}>
          <div className={styles.appealWrapper}>
            <div className={styles.titleWrapper}>
              <h3 className={styles.formTitle}>Обращение</h3>
              {data.ticket && data.ticket.status === "OPEN" ? (
                <StatusLabel text="В работе" variant="positive" />
              ) : data.ticket.status === "CLOSE" ? (
                <StatusLabel text="Завершено" variant="neutral" />
              ) : null}
            </div>
            <div className={styles.userSection}>
              <div className={styles.appealBox}>
                <span>Описание проблемы</span>
                <span>{formattedDateFunc(data.ticket.createdAt)}</span>
              </div>
              <div className={styles.descriptionInfo}>
                <p className={styles.appealDescr}>{data.ticket.description}</p>
                {data.ticket.file && data.ticket.presignedUrl ? (
                  <span
                    className={styles.appealFile}
                    onClick={() =>
                      downloadFile(
                        token,
                        data.ticket.presignedUrl,
                        data.ticket.file.split("/")[1]
                      )
                    }
                  >
                    {data.ticket.file.split("/")[1]}
                    {/* <a
                      href={
                        window.URL.createObjectURL(
                          new Blob([data.ticket.presignedUrl], { type: `image/${data.ticket.file.split[1]}` }))
                      }
                      download={data.ticket.file.split("/")[1]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data.ticket.file.split("/")[1]}
                    </a> */}
                    {/* <Link
                      to={data.ticket.presignedUrl}
                      download={data.ticket.file.split("/")[1]}
                      target="_blank"
                      rel="noreferrer"
                    >
                    {data.ticket.file.split("/")[1]}
                    </Link> */}
                  </span>
                ) : null}
              </div>
            </div>
            {data.ticket &&
              [...data?.ticket?.messages].reverse().map((message, index) => (
                <div className={styles.supportSection} key={index}>
                  <div className={styles.appealBox}>
                    <span>Описание проблемы</span>
                    <span>
                      {!message.updatedAt
                        ? formattedDateFunc(message.createdAt)
                        : formattedDateFunc(message.updatedAt)}
                    </span>
                  </div>
                  <div
                    onClick={() =>
                      downloadFile(
                        token,
                        message.presignedUrl,
                        message.file.split("/")[2]
                      )
                    }
                  >
                    <span className={styles.appealText}>{message.message}</span>
                    {message.file && message.presignedUrl ? (
                      <span className={styles.appealFile}>
                        {message.file.split("/")[2]}
                      </span>
                    ) : null}
                  </div>
                </div>
              ))}

            <Input
              isFormField={true}
              inputElement="textarea"
              name="commentText"
              register={register}
              variant="bordered-green"
              size="full"
              label="Комментарий"
              registerObj={{
                required: "Поле не может быть пустым",
              }}
              className={styles.appealTextItem}
              errorClassName={styles.textareaAppealError}
              errors={errors}
            />

            <div className={styles.fileWrapper}>
              <div className={styles.fileBoxItem}>
                <span className={styles.fileTitle}>Файлы</span>
                <FileField
                  register={register}
                  name="commentAppealFile"
                  className={styles.file}
                  setValue={setValue}
                />
              </div>
            </div>

            {errors?.root?.serverError?.type && (
              <p className={styles.formError}>Ошибка при отправке запроса</p>
            )}

            <Button type="submit" variant="green" className={styles.submitBtn}>
              Отправить
            </Button>
          </div>
        </form>
      );
    } else {
      return (
        <form onSubmit={handleSubmit(onSubmitHandler)} id="appealForm">
          <div className={styles.appealWrapper}>
            <div className={styles.titleWrapper}>
              <h3 className={styles.formTitle}>Обращение</h3>
              <StatusLabel text="В работе" variant="positive" />
            </div>
            <Input
              isFormField={true}
              inputElement="textarea"
              name="appealText"
              register={register}
              registerObj={{
                required: "Поле не может быть пустым",
              }}
              variant="bordered-green"
              size="full"
              label="Обращение"
              className={styles.appealText}
              errorClassName={styles.textareaError}
              errors={errors}
            />
            <div className={styles.fileWrapper}>
              <div className={styles.fileBox}>
                <span className={styles.fileTitle}>Файлы</span>
                <FileField
                  register={register}
                  name="appealFile"
                  className={styles.file}
                  setValue={setValue}
                />
              </div>
            </div>
            {errors?.root?.serverError?.type ===
              "Ошибка при отправке запроса" ||
              (errors?.root?.serverError?.type ===
                "Received status code 400" && (
                <p className={styles.formError}>Ошибка при отправке запроса</p>
              ))}
            <Button
              type="submit"
              variant="green"
              className={styles.submitBtn}
              form="appealForm"
            >
              Отправить
            </Button>
          </div>
        </form>
      );
    }
  }, [
    handleSubmit,
    hideModal,
    register,
    setShowModal,
    editAppealSubmitHandler,
    onSubmitHandler,
    id,
    data,
  ]);

  useEffect(() => {
    if (uuid) {
      readMessages({
        variables: { ticketId: uuid },
      });
    }
  }, [uuid]);

  return <>{formVariantRender}</>;
};
