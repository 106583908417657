import { gql } from "@apollo/client";

export const SIGNATURE_PHYSICAL_PERSON_DOCUMENT = gql`
  mutation SiagnaturePhisycalPersonDocument(
    $documentUuid: UUID!
    $physicalPersonUuid: UUID!
  ) {
    signPhysicalPersonDocumentsMutation(
      documentUuid: $documentUuid
      physicalPersonUuid: $physicalPersonUuid
    ) {
      documentSignature {
        signatureFile
        state
        document{
          uuid
          sigFile
          pdfUrl
        }
      }
    }
  }
`;
