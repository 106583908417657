import { gql } from "@apollo/client";

export const GET_SIGS_FILES = gql`
  query GetPerson($id: ID!) {
    physicalPerson(id: $id) {
      uuid
      personDocuments {
        uuid
        document {
          uuid
          title
          presignedUrl
          presignedSigUrl
          file
          sigFile
          documentType {
            name
          }

          documentSignature {
            state
            presignedSignatureFile
          }
        }
      }
    }
  }
`;

export const GET_SIGNED_FILES = gql`
  query GetPerson($id: ID!) {
    physicalPerson(id: $id) {
      uuid
      personDocuments {
        uuid
        document {
          uuid
          title
          presignedUrl
          presignedSigUrl
          file
          pdfUrl
          sigFile
          documentType {
            name
          }

          documentsSignatures {
            state
          }
        }
      }
    }
  }
`;
