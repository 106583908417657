import { ApplicationForm } from "../../../components/Forms";
import { Link } from "react-router-dom";
import styles from "./NewApplication.module.scss";
import { ArrowLeft } from "../../../assets/icons/ArrowLeft";

export const NewApplication = () => {
	return (
		<div className={styles.container}>
			<div className={styles.applicationFormContainer}>
				<Link to="/applications/" className={styles.backBtn}>
					<ArrowLeft /> <span>Кабинет</span>
				</Link>
				<ApplicationForm />
			</div>
		</div>
	);
};
