import { gql } from "@apollo/client";

export const REPRESANTIVE_COMPANY_INFO = gql`
	query RepresentedCompanyInfo($id: ID!) {
		  legalPersonRepresentedByPhysicalPersons(id: $id) {
			legalPerson {
				uuid
				fullName
				inn
				representatives {
					legalAuthorityRecord {
						durationStart
						durationStop
						file
						id
						presignedUrlFile
						presignedUrlSigFile
						type {
							name
							uuid
						}
						sigFile
						uuid
					}
					physicalPerson {
						firstName
						lastName
						patronymic
						snils
						uuid
					}
				}
			}
			representative {
				uuid
				firstName
				lastName
				patronymic
				snils
			}
			representedPhysicalPerson {
				uuid
				firstName
				lastName
				patronymic
				snils
			}
		}
	}
`;
