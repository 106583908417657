import { ExtractsList } from "../../../components/Lists/ui/ExtractsList/ExtractsList";
import styles from "./Egrn.module.scss";
import { Input } from "../../../components/Input";
import { useContext, useState } from "react";
import { Context } from "../../../App";
import { useLocation } from "react-router-dom";
import { ModalWindow } from "../../../components/ModalWindow";
import { CreateExtractForm } from "../../../components/Forms";
import { Preloader } from "../../../components/Preloader";
import { toast } from "react-toastify";

export const extractsMockData = [
  {
    date: "10 сентября 2022",
    cadNumber: "54:35:064285:460",
    types: ["Основные характеристики"],
    status: "Запрос отправляется",
  },

  {
    date: "15 августа 2022",
    cadNumber: "54:35:064285:460",
    types: ["Основные характеристики", "Переходы прав на недвижимость"],
    status: "Запрос отправлен",
  },

  {
    date: "14 августа 2022",
    cadNumber: "54:35:064285:460",
    types: ["Переходы прав на недвижимость"],
    status: "Получены выписки",
  },

  {
    date: "10 августа 2022",
    cadNumber: "54:35:064285:460",
    types: ["Основные характеристики"],
    status: "Отказ в выписке",
  },
];

export const Egrn = () => {
  const [query, setQuery] = useState("");
  const { showModal, setShowModal } = useContext(Context);
  const location = useLocation();
  const currentPath = location.pathname;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      <div className={styles.egrnTop}>
        <h2 className={styles.egrnTitle}>Выписки</h2>
        <Input
          size="short"
          variant="green"
          className={styles.egrnSearch}
          placeholder="Кадастровый номер"
          value={query}
          setValue={setQuery}
        />
      </div>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error("Выписки не найдены")
      ) : (
        <ExtractsList extractsData={extractsMockData} />
      )}
      {currentPath === "/egrn/" && showModal && (
        <ModalWindow show={showModal} setShowModal={setShowModal} size="xl">
          <CreateExtractForm />
        </ModalWindow>
      )}
    </>
  );
};
