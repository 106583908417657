import Toast from "react-bootstrap/Toast";
import styles from "./ToastComponent.module.scss";
import cn from "classnames";
import { useEffect } from "react";

/* 

Компонент для показа всплывающего уведомления

Пропсы:

isOpen - управляет видимостью компонента (boolean)

setIsOpen - функция для управления видимостью компонента

toastRef - ткущий компонент подсказки в разметке, нужен для определения где был клик, если вне подсказки, она закрывается, если внутри нее не закрывается (компонент, разметка)

className - передача дополнительных стилей из компонента, где вызывается подсказка (строка)

children - содержимое подсказки (текст, картинка, компонент)


*/

export const ToastComponent = ({
  isOpen,
  setIsOpen,
  toastRef,
  className,
  children,
}) => {
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && toastRef && !toastRef.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen, setIsOpen, toastRef]);

  return (
    <Toast className={cn(styles.toast, className, { [styles.hide]: !isOpen })}>
      <Toast.Body>{children}</Toast.Body>
    </Toast>
  );
};
