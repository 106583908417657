import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../../../components/Sidebar";
import styles from "./ClientDocuments.module.scss";
import { Button } from "../../../components/Button";
import { CreateClientDocumentsForm } from "../../../components/Forms";
import { useState } from "react";

export const ClientDocuments = () => {
  const isFullData = localStorage.getItem('fullData')
  const navigate = useNavigate();
  const location = useLocation();
  const {clientData} = location.state || null

  console.log(clientData, "clientData");

  return (
    <div className={styles.clientDocumentsWrapper}>
      <Sidebar className={styles.clientDocumentsSidebar}>
        <Button
          className={styles.clientDocumentsBtn}
          onClick={() => navigate(`/client-cabinet/`)}
        >
          <span>{"<"}</span>
          Кабинет
        </Button>
        {isFullData === 'true' ? (
          <>
            <h3 className={styles.sidebarTitle}>Требуемые данные</h3>
            <div className={styles.sidebarBox}>
              <h4>Удостоверение личности и СНИЛС</h4>
              <span>Ждём данные и фото</span>
            </div>
          </>
        ) : null}
      </Sidebar>
      <div className={styles.clientDocumentsContent}>
        <h3 className={styles.documentsContentTitle}>
          Удостоверение личности и СНИЛС
        </h3>
        <CreateClientDocumentsForm clientData={clientData}/>
      </div>
    </div>
  );
};
