import { gql } from "@apollo/client";

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument(
    $documentTypeUuid: UUID!
    $file: Upload!
    $title: String!
){
    createDocument(    
        documentTypeUuid: $documentTypeUuid
        file: $file
        title: $title
    ){
    document {
      id
      uuid
    }
  }
}
`

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument(
    $uuid: UUID!
){
    deleteDocument(    
        uuid: $uuid
    ){
      success
  }
}
`

export const SEND_DOCUMENT = gql`
  mutation createDocumentSignature(
    $participantUuid: UUID!
    $documentUuid: UUID!
){
    createDocumentSignature(    
        participantUuid: $participantUuid
        documentUuid: $documentUuid
    ){
        documentSignature {
          id
        }
    }
}
`

// export const DELETE_DOCUMENT_SIGNATURE = gql`
//   mutation DeleteDocumentSignature(
//     $participantUuid: UUID!
//     $documentUuid: UUID!
// ){
//     deleteDocumentSignature(    
//         participantUuid: $participantUuid
//         documentUuid: $documentUuid
//     ){
//         success
//     }
// }
// `

export const REQUEST_DOCUMENT = gql`
  mutation physicalPersonRequestDocument(
    $applicationUuid: UUID!
    $participantUuid: UUID!
    $ documentTypeUuid: UUID
){
    physicalPersonRequestDocument(    
        applicationUuid: $applicationUuid
        participantUuid: $participantUuid
        documentTypeUuid: $ documentTypeUuid
    ){
      document{
        id
        uuid
      }
  }
}
`

export const SEND_DOCUMENTS_FOR_SIGN = gql`
  mutation sendDocuments(
    $applicationUuid: UUID!
){
    sendDocuments(    
        applicationUuid: $applicationUuid
    ){
      message
      success
  }
}
`
export const CREATE_SIGNATURE_MANUAL = gql`
  mutation createDocumentSignatureManual(
    $documentUuid: UUID!
    $file: Upload!
){
    createDocumentSignatureManual(    
        documentUuid: $documentUuid
        file: $file
    ){
        documentSignature {
          id
        }
  }
}
`

