import {
	useMemo,
} from "react";
import { useQuery } from "@apollo/client";
import { LEGAL_AUTHORITY_RECORD_TYPES } from "../services/queries/mutations/companies/legalRepresentativeFileQueries";

export const useLegalAuthorityDocumentTypes = () => {
    const { data: documentTypesData } = useQuery(LEGAL_AUTHORITY_RECORD_TYPES);

	const documentTypes = useMemo(() => {
		const newArray = documentTypesData?.legalAuthorityRecordTypes?.edges?.map(
			(item) => {
				return {
					title: item?.node?.name,
					value: item?.node?.uuid,
				};
			}
		);

		return newArray;
	}, [documentTypesData?.legalAuthorityRecordTypes?.edges]);

    return {documentTypes}
}