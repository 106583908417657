import { gql } from "@apollo/client";

export const ALL_REAL_OBJECT = gql`
  query AllRealObject($search: String, $first: Int, $offset: Int) {
    allRealObject(search: $search, first: $first, offset: $offset) {
      edges {
        node {
          id
          uuid
          cadastralNumber
          developer
          buildingArea
          type {
            uuid
            title
            id
          }
          address {
            apartment
            region
            district
            locality
            house
            structure
            okato
            oktmo
            index
            city
            street
            streetType
            country
            value
            regionKladrId
            block
            fias
            kladr
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const ALL_OBJECT_TYPES = gql`
  query {
    allObjectTypes {
      edges {
        node {
          id
          title
          uuid
        }
      }
    }
  }
`;

export const GET_ADDRESS_OBJECT = gql`
  query GetAddressObject($id: ID!) {
    realObject(id: $id) {
      uuid
      address {
        apartment
        region
        district
        locality
        house
        structure
        okato
        oktmo
        index
        city
        street
        streetType
        country
        value
        fias
        kladr
        regionKladrId
        block
      }
      type {
        id
        uuid
        title
      }
    }
  }
`;
