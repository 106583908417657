import styles from "./FakeCheckbox.module.scss";
import cn from "classnames";

/* 
Фальшивый чекбокс для показа в тех местех, где чекбокс не должен сниматься или ставиться

Пропсы:

isChecked - если передано true, чекбокс будет отображаться как отмеченный, если false тогдф как пустой

label - текст который будет показываться рядом с чекбоксом

*/

export const FakeCheckbox = ({ isChecked, label }) => {
  return (
    <div className={styles.fakeWrapper}>
      <span
        className={cn(styles.fakeCheckbox, {
          [styles.isChecked]: isChecked,
        })}
      ></span>
      <span className={styles.fakeLabel}>{label}</span>
    </div>
  );
};
