import { gql } from "@apollo/client";

export const REQUESTS_HISTORY = gql`
  query RequestsHistory($id: ID!) {
    application(id: $id) {
      requestHistory {
        statusDescription
        id
        createdAt
        status
      }
    }
  }
`;
