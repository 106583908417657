import {useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import cn from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {useMutation, useQuery} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import {Input} from "../../../Input/ui/Input";
import {Button} from "../../../Button";
import {Select} from "../../../Select/ui/Select";
import {Checkbox} from "../../../Checkbox";
import {ObjectBlock} from "./ui/ObjectBlock/ObjectBlock";
import styles from "./ApplicationForm.module.scss";
import {ParticipantBlock} from "./ui/ParticipantBlock/ParticipantBlock";
import {DocumentsBlock} from "./ui/DocumentsBlock/DocumentsBlock";
import {ModalWindow} from "../../../ModalWindow";
import {DeclarationBlock} from "./ui/DeclarationBlock/DeclarationBlock";
import {CREATE_APPLICATION} from "../../../../services/queries/mutations/applications/applications";
import {GET_APPLICATION_TYPES} from "../../../../services/queries/queries/allApplicationTypes";
import {ALL_APPLICATIONS} from "../../../../services/queries/queries/allApplications";
import {OBJECT_RIGHT} from "../../../../services/queries/queries/applications/objectRight";
import {UPDATE_APPLICATION} from "../../../../services/queries/mutations/applications/updateApplication";
import {
  CREATE_APPLICATION_PARTICIPANT
} from "../../../../services/queries/mutations/applications/createApplicationPatricipants";
import {
  UPDATE_APPLICATION_PARTICIPANT
} from "../../../../services/queries/mutations/applications/updateApplicationParticipants";
import {selectParticipants, setParticipants as setStateParticipants} from "../../../../redux/slices/application";
import {CREATE_AUTHORITY_RECORD} from "../../../../services/queries/mutations/companies/legalRepresentativeFileQueries";
import {DELETE_DOCUMENT, SEND_DOCUMENTS_FOR_SIGN,} from "../../../../services/queries/mutations/documents/documents";
import {APPLICATION_INFO} from "../../../../services/queries/queries/applications/applicationInfo";
import {CREATE_STATEMENT_APPLICATION} from "../../../../services/queries/mutations/applications/statement/statement";
import {toast} from "react-toastify";
import {AddFileGreen} from "../../../../assets/icons/AddFileGreen";
import {
  SEND_REQUEST_APP_SERVICE
} from "../../../../services/queries/mutations/applications/sendRequestsApplicationService";
import {newRool} from "../../../../constants/constants";
import {CopyIcon} from "../../../../assets/icons/CopyIcon";
import {DUPLICATE_APPLICATION} from "../../../../services/queries/mutations/applications/duplicateApplication";
import {Preloader} from "../../../Preloader";
import {PaymentsBlock} from "./ui/PaymentsBlock/PaymentsBlock";
import {translateStatus} from "../../../../helpers/translateStatus";
import {formatDate} from "../../../../helpers/formatDate";
import {DeleteApplication} from "./ui/DeleteApplication/DeleteApplication";

export const downloadExistFiles = (token, url, name) => {
  fetch(url, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Непредвиденная ошибка");
      }
      return response.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const title = /[^/]*$/.exec(url)[0];
      a.href = url;
      a.download = name ? name : title;
      a.target = "_blank";
      a.rel = "noreferrer";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Ошибка скачивания:", error);
    });
};

export const ApplicationForm = ({
  applicationData,
  uuid,
  applicationInfo,
  id,
}) => {
  const accessToken = localStorage.getItem("accessToken"); // необходим для скачивания файла
  const dispatch = useDispatch();
  const [applicationType, setApplicationType] = useState(
    "Выберите тип обращения"
  );
  const participants = useSelector(selectParticipants);
  const { data: rights } = useQuery(OBJECT_RIGHT);
  const [openChooseModal, setOpenChooseModal] = useState(false);
  const [isImported, setIsImported] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [realObjectUuid, setRealObjectUuid] = useState();
  const [participantsUuids, setParticipantsUuids] = useState([]);
  const [deleteShow, setDeleteShow] = useState(false);
  const navigate = useNavigate();
  const [participantType, setParticipantType] = useState("");
  const [isMortage, setIsMortage] = useState(); //ипотека или нет
  const newApplication = window.location.pathname === "/applications/new";
  const applicationSended =
    applicationData?.application?.requestHistory?.length == 0;
  const [testData, setTestData] = useState(null);
  const kuvd = applicationData?.application?.kuvd;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValue: {
      peculiarities: applicationData?.application?.mortgage,
    },
  });

  const watchedLowType = watch("lowType");
  const watchedArea = watch("area");
  const watchAmount = watch("amount");

  useEffect(() => {
    if (newApplication) {
      setOpenChooseModal(true);
    }
  }, []);

  useEffect(() => {
    const socketLink = process.env.REACT_APP_API_BASE_URL_WS;
    const token = localStorage.getItem("accessToken");
    const fullSocketLink = socketLink + `?token=${token}`;
    const socket = new WebSocket(fullSocketLink);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data) {
        setTestData(data);
      }

      if (
        data.type !== "application_updated" ||
        data.type === "document_upload_confirmed" ||
        data.type === "document_change_state"
      ) {
        applicationInfo({
          variables: {
            id,
          },
        });
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (applicationData?.application?.ownership?.uuid) {
      setValue("lowType", applicationData?.application?.ownership?.uuid);
    }
  }, [applicationData?.application?.ownership?.uuid, setValue]);

  const { data } = useQuery(GET_APPLICATION_TYPES, {
    variables: { systemType: "statement" },
  });
  const applicationTypes = [data?.allApplicationTypes];

  useEffect(() => {
    setDocuments(applicationData?.application?.documents);
  }, [applicationData]);

  const allLawTypes = rights?.allObjectRight?.edges?.map((item) => {
    return {
      value: item.node.uuid,
      title: item.node.title,
    };
  });

  const selectRoles = useMemo(() => {
    if (applicationTypes?.roles !== undefined) {
      return applicationTypes?.roles?.map((item) => {
        return {
          value: item?.uuid,
          title: item?.name,
        };
      });
    } else {
      return applicationData?.application?.type?.roles?.map((item) => {
        return {
          value: item?.uuid,
          title: item?.name,
        };
      });
    }
  }, [applicationTypes]);

  const chooseApplicationType = (value) => {
    setApplicationType(value);
    setOpenChooseModal(false);
  };

  const [createStatementApplication] = useMutation(
    CREATE_STATEMENT_APPLICATION,
    {
      refetchQueries: [APPLICATION_INFO],
      onError(err) {
        toast.error(err.message);
      },
    }
  );

  const [sendRequestsApplicationService] = useMutation(
    SEND_REQUEST_APP_SERVICE,
    {
      refetchQueries: [APPLICATION_INFO],
      onCompleted: (data) => {
        toast.success(data.sendRequestsApplicationService.message);
      },
      onError(err) {
        toast.error(err.message);
      },
    }
  );

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    refetchQueries: [APPLICATION_INFO],
    onCompleted: (data) => {},
    onError(err) {
      console.log(err?.message);
    },
  });

  const [mutateApplication] = useMutation(CREATE_APPLICATION, {
    onCompleted: (data) => {
      navigate(
        `/applications/edit/${data?.createApplication?.application?.id}`
      );
    },
    onError(err) {
      console.log(err?.message);
    },

    refetchQueries: [ALL_APPLICATIONS],
  });

  const [updateApplication] = useMutation(UPDATE_APPLICATION, {
    refetchQueries: [ALL_APPLICATIONS],
    onCompleted: (data) => {
      navigate(`/applications/`);
    },
  });

  const [saveApplication] = useMutation(UPDATE_APPLICATION);

  const [createParcipants] = useMutation(CREATE_APPLICATION_PARTICIPANT, {
    refetchQueries: [APPLICATION_INFO],
    onCompleted: () => {
      setParticipantType("");
    },
  });

  const [updateParticipant] = useMutation(UPDATE_APPLICATION_PARTICIPANT, {
    refetchQueries: [APPLICATION_INFO],
    onCompleted: () => {
      setParticipantType("");
    },
  });

  const [sendDocumentsForSign] = useMutation(SEND_DOCUMENTS_FOR_SIGN, {
    onCompleted: (data) => {
      toast.success(data.sendDocuments.message);
    },
  });

  const [createLegalAuthorityRecord] = useMutation(CREATE_AUTHORITY_RECORD);

  const [duplicateApplication, {data: duplicateData, loading: duplicateLoading }] = useMutation(
    DUPLICATE_APPLICATION
  );

  const duplicateApplicationHandler = () => {
    duplicateApplication({ variables: { applicationUuid: uuid } }).then(
      (data) =>
        navigate(
          `/copy-application/${data?.data?.duplicateApplication?.application?.id}`
        )
    );
  };



  useEffect(() => {
    if (participantType !== "") {
      participants?.map((item) => {
        if (item?.isUpdated) {
          updateParticipantHanlde(item);
        }
        if (!item?.uuid) {
          createApplicationParticipantHandle(uuid, item);
        }
      });
    }
  }, [participants]);

  useEffect(() => {
    if (applicationData && applicationData.application) {
      reset({
        amount: applicationData?.application?.sum,
        area: applicationData?.application?.area,
      });

      setApplicationType(applicationData.application.type.uuid);
      dispatch(setStateParticipants(applicationData.application.participants));
    }
  }, [applicationData, reset]);

  const createApplicationParticipantHandle = async (
    applicationUuid,
    participantData
  ) => {
    console.log(participantData)
    if (
      participantData?.node?.fullName ||
      participantData?.legalPerson?.fullName ||
      participantData?.node?.legalPerson?.fullName
    ) {
      if (applicationType === newRool) {
        console.log("Новое право", participantData);
        if (participantType === "LEGAL_PERSON") {
          createParcipants({
            variables: {
              applicationUuid: applicationUuid,
              legalPersonUuid: participantData?.node?.uuid,
              participantType: participantData?.participantType?.toLowerCase(),
              representativeUuid:
                participantData?.representative?.physicalPerson?.uuid,
              role: participantData?.role,
            },
          });
        } else {
          createParcipants({
            variables: {
              applicationUuid: applicationUuid,
              applicantUuid:
                participantData?.node?.uuid || participantData?.applicantUuid,
              legalPersonUuid:
                participantData?.node?.representedLegalPerson?.uuid ||
                participantData?.node?.legalPerson?.uuid ||
                participantData?.legalPerson?.uuid,
              physicalPersonUuid:
                participantData?.representedPhysicalPerson?.uuid,
              participantType: participantData?.participantType?.toLowerCase(),
              representativeUuid:
                participantData?.node?.representative?.uuid ||
                participantData?.representative?.uuid,
              role: participantData?.role,
            },
          });
        }
      } else {
        createParcipants({
          variables: {
            applicationUuid: applicationUuid,
            legalPersonUuid:
              participantData?.node?.uuid?.toString() ||
              participantData?.legalPerson?.uuid?.toString() ||
              participantData?.node?.representedLegalPerson?.uuid?.toString() ||
              null,
            legalAuthorityRecordUuid:
              participantData?.representative?.legalAuthorityRecord?.uuid,
            representativeUuid:
              participantData?.representative?.physicalPerson?.uuid ||
              participantData?.representative?.physicalPerson?.uuid ||
              participantData?.representative?.uuid ||
              participantData?.node?.representative?.uuid ||
              null,
            role: participantData?.role?.uuid || participantData?.role,
            participantType: participantData?.participantType?.toLowerCase(),
            shareDenominator: participantData?.shareDenominator,
            shareNumerator: participantData?.shareNumerator,
          },
        });
      }
    } else {
      let legalAuthorityRecordData;
      if (participantData?.legalAuthorityRecord?.file) {
        legalAuthorityRecordData = await createLegalAuthorityRecord({
          variables: {
            file: participantData?.legalAuthorityRecord?.file,
            typeUuid: participantData?.legalAuthorityRecord?.type?.uuid,
          },
        });
      }

      let participantDataVariables = {
        applicationUuid: applicationUuid,
        physicalPersonUuid:
          participantData?.physicalPerson?.uuid?.toString() ||
          participantData?.uuid?.toString() ||
          null,

        representativeUuid:
          participantData?.representative?.physicalPerson?.uuid ||
          participantData?.representative?.uuid ||
          null,
        role: participantData?.role,
        participantType: participantData?.participantType?.toLowerCase(),

        shareDenominator: participantData?.shareDenominator,
        shareNumerator: participantData?.shareNumerator,
      };

      if (
        legalAuthorityRecordData?.data?.createLegalAuthorityRecord
          ?.legalAuthorityRecord?.uuid
      ) {
        participantDataVariables.legalAuthorityRecordUuid =
          legalAuthorityRecordData.data.createLegalAuthorityRecord?.legalAuthorityRecord?.uuid;
      }

      createParcipants({
        variables: participantDataVariables,
      });
    }
  };

  const updateParticipantHanlde = async (participantData) => {
    try {
      let legalAuthorityRecordData;
      if (participantData?.legalAuthorityRecord?.file) {
        legalAuthorityRecordData = await createLegalAuthorityRecord({
          variables: {
            file: participantData?.legalAuthorityRecord?.file,
            typeUuid: participantData?.legalAuthorityRecord?.type?.uuid,
          },
        });
      }

      let participantDataVariables = {
        role: participantData?.role?.uuid,
        participantType: participantData?.participantType?.toLowerCase() || "",
        physicalPersonUuid:
          participantData?.physicalPerson?.uuid?.toString() ||
          participantData?.uuid?.toString() ||
          null,

        representativeUuid:
          participantData?.representative?.physicalPerson?.uuid ||
          participantData?.representative?.[0]?.uuid ||
          null,
        shareDenominator: participantData?.shareDenominator,
        shareNumerator: participantData?.shareNumerator,
      };

      if (
        legalAuthorityRecordData?.data?.createLegalAuthorityRecord
          ?.legalAuthorityRecord?.uuid
      ) {
        participantDataVariables.legalAuthorityRecordUuid =
          legalAuthorityRecordData.data.createLegalAuthorityRecord?.legalAuthorityRecord?.uuid;
      }

      updateParticipant({
        variables: {
          participantUuid: participantData?.uuid,
          participantData: participantDataVariables,
        },
      });
    } catch (error) {}
  };

  const onSubmit = (formData, event) => {
    event.preventDefault();
    if (!uuid) {
      mutateApplication({
        variables: {
          area: formData.area || null,
          mortgage: isMortage,
          ownershipUuid: formData.lowType || watchedLowType,
          participantsUuids: participantsUuids || null,
          realObjectUuid: realObjectUuid,
          sum: formData.amount || null,
          typeUuid: applicationType?.uuid,
        },
      });
    } else {
      updateApplication({
        variables: {
          applicationUuid: uuid,
          applicationData: {
            area: formData.area || null,
            mortgage: isMortage,
            ownershipUuid: formData.lowType || watchedLowType,
            participantsUuids: participantsUuids,
            realObjectUuid: realObjectUuid,
            sum: formData.amount || null,
            typeUuid: applicationType?.uuid,
          },
        },
        skip: !uuid,
      });
    }
  };

  useEffect(() => {
    if (
      ((watchedLowType && watchedArea && isMortage && watchAmount) ||
        realObjectUuid) &&
      uuid
    ) {
      if (
        watchedLowType !==
          (applicationData?.application?.ownership?.uuid || "") ||
        watchedArea !== applicationData?.application?.area ||
        watchAmount !== applicationData?.application?.sum ||
        realObjectUuid !== applicationData?.application?.realObject?.uuid ||
        isMortage !== applicationData?.application?.mortgage
      ) {
        saveApplication({
          variables: {
            applicationUuid: uuid,
            applicationData: {
              area: watchedArea || null,
              mortgage: isMortage,
              ownershipUuid: watchedLowType,
              participantsUuids: participantsUuids,
              realObjectUuid: realObjectUuid,
              sum: watchAmount || null,
              typeUuid: applicationType?.uuid,
            },
          },
          skip: !uuid,
        });
      }
    }
  }, [watchedLowType, watchedArea, isMortage, watchAmount, realObjectUuid]);

  useEffect(() => {
    setIsMortage(applicationData?.application?.mortgage);
  }, [applicationData]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className={styles.title}>
          {applicationType?.title
            ? applicationType?.title
            : applicationData &&
              applicationData.application &&
              applicationData.application.type.title}
          {duplicateLoading ? (
            <Preloader
              variant="small"
              className={styles.applicationPreloader}
            />
          ) : (
            <abbr
              title="Копировать заявку"
              className={styles.applicationCopy}
              onClick={duplicateApplicationHandler}
            >
              <span>
                <CopyIcon />
              </span>
            </abbr>
          )}
        </h2>
        {applicationType !== "Выберите тип обращения" ||
        (applicationData && applicationData?.application) ? (
          <Button
            className={styles.editApplicationBtn}
            variant="transparent"
            type="button"
            onClick={() => setOpenChooseModal(true)}
          >
            Изменить тип обращения...
          </Button>
        ) : (
          <Button
            className={styles.editApplicationBtn}
            variant="transparent"
            type="button"
            onClick={() => setOpenChooseModal(true)}
          >
            Выбрать тип обращения...
          </Button>
        )}
        <section className={cn(styles.section, styles.topSection)}>
          <h3 className={styles.sectionTitle}>
            {isImported ? "Сделка с Унистроем" : "Сделка третьих лиц"}
          </h3>

          <Input
            name="amount"
            variant="bordered-green"
            label="Сумма, ₽"
            className={styles.input}
            register={register}
            isFormField={true}
          />

          <Input
            name="area"
            variant="bordered-green"
            label="Площадь из договора, м²"
            className={styles.input}
            register={register}
            isFormField={true}
          />
          <div className={styles.input}>
            <span>Тип права</span>
            <Select
              name="lowType"
              variant="bordered-green"
              className={styles.select}
              register={register}
              isFormSelect={true}
              placeholder="Выберите тип права"
              options={allLawTypes}
            />
          </div>

          <div className={styles.input}>
            <span>Особенности</span>
            <Checkbox
              name="peculiarities"
              register={register}
              label="Ипотека"
              isChecked={isMortage}
              onChange={() => setIsMortage(!isMortage)}
            />
          </div>
        </section>
        <section className={styles.section}>
          <h3 className={styles.sectionTitle}>Объект</h3>
          <ObjectBlock
            applicationData={applicationData?.application?.realObject}
            setRealObjectUuid={setRealObjectUuid}
            applicationSended={applicationSended}
          />
        </section>
        <section className={styles.section}>
          <h3 className={styles.sectionTitle}>Участники</h3>

          <ParticipantBlock
            lawType={watchedLowType}
            applicationType={applicationType}
            setParticipantsUuids={setParticipantsUuids}
            participantsUuids={participantsUuids}
            roles={selectRoles}
            setParticipantType={setParticipantType}
            applicationSended={applicationSended}
          />
        </section>
        <section className={styles.section}>
          <h3 className={styles.sectionTitle}>Документы</h3>
          {applicationSended && (
            <Button
              variant="outline-green"
              className={styles.addBtn}
              type="button"
              onClick={(e) => {
                setDocuments([
                  {
                    id: documents?.length + 1,
                  },
                  ...documents,

                ]);
              }}
            >
              <span>Добавить</span>
            </Button>
          )}
          {documents?.map((item, index) => {
            return (
              <DocumentsBlock
                key={item.id}
                id={index}
                applicationInfo={applicationInfo}
                item={item}
                title={item?.title}
                participants={participants}
                deleteDocument={deleteDocument}
                setDocuments={setDocuments}
                documents={documents}
                applicationUuid={uuid}
                applicationSended={applicationSended}
                testData={testData}
                fileUrl={item?.presignedUrl}
                documentsIds={item?.documentsSignatures}
                applicationId={id}
              />
            );
          })}
          {/* {documents?.length >= 1 &&
            participants.length >= 1 &&
            applicationSended && (
              <div className={styles.signButtons}>
                <Button
                  variant="outline-green"
                  type="button"
                  onClick={() => {}}
                >
                  Подписать токеном
                </Button>
              </div>
            )} */}
        </section>
        <section className={styles.section}>
          <h3 className={styles.sectionTitle}>Заявление</h3>

          {applicationSended && (
            <Button
              variant="outline-green"
              className={styles.addBtn}
              type="button"
              // disabled={isDocumentSigned === false}
              onClick={() => {
                createStatementApplication({
                  variables: {
                    applicationUuid: uuid,
                  },
                });
              }}
            >
              <span>Сформировать заявление</span>
            </Button>
          )}

          {applicationData?.application?.statement?.map((item, index) => (
            <DeclarationBlock
              key={index}
              declaration={item}
              participants={participants}
              applicationId={id}
              applicationInfo={applicationInfo}
            />
          ))}
          {applicationData?.application?.statement?.length > 0 &&
            applicationSended && (
              <Button
                variant="white"
                type="button"
                className={styles.addBtn}
                onClick={() => {
                  sendDocumentsForSign({
                    variables: {
                      applicationUuid: uuid,
                    },
                  });
                }}
              >
                <span>Отправить на подпись</span>
              </Button>
            )}
          <div className={styles.declarationBottomBlock}>
            {applicationSended && (
              <Button
                variant="extra-light-green"
                className={styles.addBtn_2}
                type="submit"
                onClick={() => {}}
              >
                <span>Сохранить без отправки</span>
              </Button>
            )}

            {((applicationData?.application?.statement?.length > 0 &&
              (applicationData?.application?.documentComplete === true) ||
              translateStatus(
                applicationData?.application?.requestHistory.at(-1)?.status,
                kuvd
              ) ===
                ("Ошибка при отправке" || "Ошибка при обработке запроса"))) && (
              <>
                <Button
                  variant="green"
                  className={styles.addBtn}
                  type="button"
                  onClick={() =>
                    sendRequestsApplicationService({
                      variables: {
                        applicationUuid: uuid,
                      },
                    })
                  }
                >
                  <span>Отправить обращение в РР</span>
                </Button>
                <span className={styles.info}>
                  Нужно получить все подписи, чтобы отправить обращение.
                </span>
              </>
            )}
          </div>
        </section>

        {applicationData?.application?.payments.length !== 0 &&
          <section className={styles.section}>
            <h3 className={styles.sectionTitle}>Пошлина</h3>
            {applicationData?.application?.payments.map((item, index) => (
              <PaymentsBlock
                key={index}
                payment={item}
                participants={participants}
              />
            ))}
          </section>
        }

        <section className={styles.section}>
            {applicationData?.application?.requestHistory?.length > 0 && (
              <div className={styles.applicationStatusBlock}>
                <div>
                  <h3 className={styles.application_headerText}>Статус заявления</h3>
                  {applicationData?.application?.requestHistory.map((item) => (
                    <div>
                      {formatDate(item.createdAt)} -{" "}
                      {item?.statusDescription !== null
                        ? item?.statusDescription
                        : translateStatus(item.status, kuvd)}
                      {item?.statusDescription === "Принято от заявителя" && ` Присвоен ${kuvd}`}
                      {item?.statusDescription === "Получено от РР" &&  
                        <span 
                          className={styles.link}
                          onClick={() =>{
                            downloadExistFiles(accessToken, `https://api.unireg.redfoxlab.ru/application/${applicationData?.application?.uuid}/download-zip-send/`)}
                          } 
                        >
                          {`скачать пакет`}
                        </span>
                      }
                      {item?.status === "SEND_TO" &&  
                        <span 
                          onClick={() =>{
                            downloadExistFiles(accessToken, `https://api.unireg.redfoxlab.ru/application/${applicationData?.application?.uuid}/download-zip-send/`)}
                          } 
                          className={styles.link}
                        >
                          {`скачать пакет`}
                        </span>
                      }
                    </div>
                  ))}
                </div>
              </div>       
            )}
        </section>

        <section className={styles.section}>
          {/* {applicationData?.application?.requestHistory?.length > 0 && ( */}
            <>
              <h3 className={styles.sectionTitle}>Дополнительное обращение</h3>
              <ul className={styles.additional}>
                {applicationData?.application?.additionalApplications?.map(
                  (item, index) => (
                    <li
                      key={index}
                      onClick={() => navigate(`/additional/edit/${item?.newApplication?.id}`)}
                    >
                      <div className={styles.card}>
                        <h4 className={styles.divTitle}>
                          {" "}
                          {item?.newApplication?.type?.title}{" "}
                        </h4>
                        <span className={styles.divText}>
                          {formatDate(item?.createdAt)} -{" "}
                          {item?.newApplication?.kuvd !== null
                            ? `Присвоен КУВД ${item?.newApplication?.kuvd}`
                            : `КУВД не присвоен`}
                        </span>
                      </div>
                    </li>
                  )
                )}
              </ul>
              <Button
                variant={"extra-light-green"}
                className={styles.buttonGreen}
                type="button"
                onClick={() => navigate(`/additional/${id}`)}
              >
                Дополнительное обращение... <AddFileGreen />
              </Button>
            </>
          {/* )} */}
        </section>

        {!isImported && (
          <section className={cn(styles.section, styles.deleteSection)}>
            <Button type='button' variant="red" onClick={() => setDeleteShow(true)}>
              Удалить обращение
            </Button>
          </section>
        )}
      </form>

      <ModalWindow
        show={openChooseModal}
        setShowModal={() => setOpenChooseModal(false)}
        dialogClassName={styles.newApplicationModal}
      >
        <h2 className={cn(styles.title, styles.modalTitle)}>
          Выберите тип обращения
        </h2>
        <ul>
          {applicationTypes?.[0]?.edges?.map((item, index) => (
            <li key={index}>
              <Button
                onClick={() => {
                  chooseApplicationType(item?.node);
                  newApplication &&
                    mutateApplication({
                      variables: {
                        mortgage: isMortage ? isMortage : false,
                        status: "DRAFT",
                        typeUuid: item?.node?.uuid,
                      },
                    });
                }}
                className={cn(styles.applicationTypesListItem, {
                  [styles.active]: applicationType === item?.node?.title,
                })}
              >
                {item?.node?.title}
              </Button>
            </li>
          ))}
        </ul>
      </ModalWindow>

      <DeleteApplication
        setDeleteShow={setDeleteShow}
        deleteShow={deleteShow}
        uuid={uuid}
      />
    </>
  );
};
