import { gql } from "@apollo/client";

export const REPRESENTATIVE_COMPANY_BY_COMPANY_INFO = gql`
  query RepresentativeCompanyByCompanyInfo($id: ID!) {
    legalPersonRepresentedByLegalPerson(id: $id) {
      legalPerson {
        uuid
        fullName
        inn
        representatives {
          legalAuthorityRecord {
            durationStart
            durationStop
            file
            id
            presignedUrlFile
            presignedUrlSigFile
            type {
              name
              uuid
            }
            sigFile
            uuid
          }
          physicalPerson {
            firstName
            lastName
            patronymic
            snils
            uuid
          }
        }
      }
      representative {
        uuid
        firstName
        lastName
        patronymic
        snils
      }
      representedLegalPerson {
        uuid
        fullName
      }
    }
  }
`;
