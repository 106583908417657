import { FILTERED_APPLICATIONS } from "../../../../services/queries/queries/applications/filteredApplications";
import { useDebouncedCallback } from "use-debounce";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Preloader } from "../../../Preloader";

/* 
Компонент для фильтрации заявок. который объединяет в себе значения выбранные в фильтрах и отправляет запрос с этими значениями на сервер. Если какое-то значени уходит пустым - фильтр для этого значения сбрасывается

 Пропсы:

  value - передача значения из поиска

  typeSearch - передача значения из фильтра по типу

  firstdate, secondDate - передача значений календаря для фильтрации по дате

  mainValue - значение для сброса фильтра по дате

  dealparty - значение из фильтра по продавцу

  status - значение из филььтра по статусу

  setAllApplications - функция для обновления массива с заявками значением, поступившим в ответ на отправленный запрос

  setLoadingStatus - функция для управления показом прелоадера

*/

export const ApplicationsFilters = ({
  value,
  typeSearch,
  firstDate,
  mainValue,
  secondDate,
  dealParty,
  status,
  setAllApplications,
  setLoadingStatus,
  setCurrentPageFilter,
  currentPageFilter,
  setTotalPageFilter,
  setTotalCountedFiltered,
}) => {
  const first = 10; // Количество объектов на странице
  const offset = currentPageFilter * first;
  const [getFilteredApplications, { data, loading }] = useLazyQuery(
    FILTERED_APPLICATIONS,
    {
      variables: { offset: offset, first: first },
      fetchPolicy: "no-cache",
    }
  );
  const totalObjects = data ? data.allApplications.totalCount : 0;
  useEffect(() => {
    setTotalPageFilter(Math.ceil(totalObjects / first));
    setTotalCountedFiltered(data?.allApplications?.totalCount);
  }, [data]);
  console.log(totalObjects);
  const debounce = useDebouncedCallback(
    (query, typeSearch, firstDate, secondDate, status, dealParty) => {
      getFilteredApplications({
        variables: {
          search: query,
          typeSearch,
          dateRange:
            firstDate && secondDate
              ? firstDate + "-" + secondDate
              : mainValue
              ? mainValue
              : "",
          dealParty,
          status,
        },
      });
    },
    1000
  );
  useEffect(() => {
    const hasFilters =
      value || typeSearch || firstDate || secondDate || dealParty || status;
    if (hasFilters) {
      debounce(value, typeSearch, firstDate, secondDate, status, dealParty);
    }
  }, [value, debounce, typeSearch, firstDate, secondDate, dealParty, status]);

  useEffect(() => {
    if (data && data?.allApplications?.edges) {
      setAllApplications(data?.allApplications?.edges);
    }
  }, [data]);

  useEffect(() => {
    setLoadingStatus(loading);
  }, [loading]);

  return <></>;
};
