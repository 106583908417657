export const AppStoreIcon = () => {
  return (
    <svg
      width="180"
      height="52"
      viewBox="0 0 180 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M179 46.0024C179 48.7735 176.709 51.0182 173.874 51.0182H6.13243C3.2995 51.0182 1 48.7735 1 46.0024V6.00284C1 3.23301 3.2995 0.980469 6.13243 0.980469H173.873C176.709 0.980469 178.999 3.23301 178.999 6.00284L179 46.0024Z"
        fill="black"
      />
      <path
        d="M173.333 1.04162C176.42 1.04162 178.932 3.4905 178.932 6.5V45.5C178.932 48.5095 176.42 50.9584 173.333 50.9584H6.66667C3.58 50.9584 1.06833 48.5095 1.06833 45.5V6.5C1.06833 3.4905 3.58 1.04162 6.66667 1.04162H173.333ZM173.333 2.74817e-06H6.66667C3.00167 2.74817e-06 0 2.92663 0 6.5V45.5C0 49.0734 3.00167 52 6.66667 52H173.333C176.998 52 180 49.0734 180 45.5V6.5C180 2.92663 176.998 2.74817e-06 173.333 2.74817e-06Z"
        fill="#A6A6A6"
      />
      <path
        d="M40.1695 25.7191C40.1308 21.5292 43.6881 19.4908 43.8508 19.3959C41.8361 16.532 38.7135 16.1407 37.6161 16.1095C34.9935 15.8404 32.4495 17.6396 31.1135 17.6396C29.7508 17.6396 27.6935 16.1355 25.4761 16.1797C22.6228 16.2226 19.9535 17.8333 18.4895 20.3345C15.4681 25.4344 17.7215 32.9289 20.6161 37.0512C22.0641 39.0701 23.7561 41.3243 25.9708 41.245C28.1375 41.1579 28.9468 39.8982 31.5615 39.8982C34.1521 39.8982 34.9121 41.245 37.1708 41.1943C39.4961 41.1579 40.9601 39.1663 42.3575 37.1292C44.0308 34.8152 44.7028 32.5363 44.7295 32.4193C44.6748 32.4011 40.2135 30.741 40.1695 25.7191Z"
        fill="white"
      />
      <path
        d="M35.9028 13.3977C37.0681 11.9768 37.8655 10.0437 37.6441 8.08203C35.9575 8.15483 33.8481 9.21953 32.6335 10.6092C31.5588 11.8338 30.5988 13.841 30.8468 15.7286C32.7415 15.8664 34.6868 14.7965 35.9028 13.3977Z"
        fill="white"
      />
      <path
        d="M71.5269 40.9547H68.4989L66.8402 35.873H61.0749L59.4949 40.9547H56.5469L62.2589 23.6543H65.7869L71.5269 40.9547ZM66.3402 33.741L64.8402 29.2235C64.6815 28.762 64.3842 27.6752 63.9455 25.9644H63.8922C63.7175 26.7002 63.4362 27.787 63.0495 29.2235L61.5762 33.741H66.3402Z"
        fill="white"
      />
      <path
        d="M86.2159 34.5628C86.2159 36.6844 85.6279 38.3614 84.4519 39.5925C83.3985 40.6884 82.0905 41.2357 80.5292 41.2357C78.8439 41.2357 77.6332 40.6455 76.8959 39.4651H76.8425V46.0366H73.9999V32.5855C73.9999 31.2517 73.9639 29.8828 73.8945 28.4788H76.3945L76.5532 30.4561H76.6065C77.5545 28.9663 78.9932 28.2227 80.9239 28.2227C82.4332 28.2227 83.6932 28.8038 84.7012 29.9673C85.7119 31.1321 86.2159 32.6635 86.2159 34.5628ZM83.3199 34.6642C83.3199 33.45 83.0399 32.449 82.4772 31.6612C81.8625 30.8396 81.0372 30.4288 80.0025 30.4288C79.3012 30.4288 78.6639 30.6576 78.0945 31.1087C77.5239 31.5637 77.1505 32.1578 76.9759 32.8936C76.8879 33.2368 76.8439 33.5176 76.8439 33.7386V35.8186C76.8439 36.726 77.1292 37.4917 77.6999 38.117C78.2705 38.7423 79.0119 39.0543 79.9239 39.0543C80.9945 39.0543 81.8279 38.6513 82.4239 37.8479C83.0212 37.0432 83.3199 35.9824 83.3199 34.6642Z"
        fill="white"
      />
      <path
        d="M100.933 34.5628C100.933 36.6844 100.345 38.3614 99.168 39.5925C98.116 40.6884 96.8079 41.2357 95.2466 41.2357C93.5613 41.2357 92.3506 40.6455 91.6146 39.4651H91.5613V46.0366H88.7186V32.5855C88.7186 31.2517 88.6826 29.8828 88.6133 28.4788H91.1133L91.272 30.4561H91.3253C92.2719 28.9663 93.7106 28.2227 95.6426 28.2227C97.1506 28.2227 98.4106 28.8038 99.4213 29.9673C100.428 31.1321 100.933 32.6635 100.933 34.5628ZM98.0373 34.6642C98.0373 33.45 97.7559 32.449 97.1933 31.6612C96.5786 30.8396 95.756 30.4288 94.72 30.4288C94.0173 30.4288 93.3813 30.6576 92.8106 31.1087C92.24 31.5637 91.8679 32.1578 91.6933 32.8936C91.6066 33.2368 91.5613 33.5176 91.5613 33.7386V35.8186C91.5613 36.726 91.8466 37.4917 92.4146 38.117C92.9853 38.741 93.7266 39.0543 94.6413 39.0543C95.712 39.0543 96.5453 38.6513 97.1413 37.8479C97.7386 37.0432 98.0373 35.9824 98.0373 34.6642Z"
        fill="white"
      />
      <path
        d="M117.387 36.1027C117.387 37.5743 116.863 38.7716 115.811 39.6959C114.655 40.706 113.046 41.2104 110.978 41.2104C109.068 41.2104 107.538 40.8516 106.379 40.1327L107.038 37.8226C108.286 38.5584 109.655 38.9276 111.147 38.9276C112.218 38.9276 113.051 38.691 113.65 38.2204C114.246 37.7498 114.543 37.118 114.543 36.3302C114.543 35.6282 114.298 35.0367 113.806 34.557C113.316 34.0773 112.499 33.6314 111.358 33.2193C108.251 32.0896 106.699 30.4347 106.699 28.2585C106.699 26.8363 107.243 25.6702 108.332 24.7628C109.418 23.8541 110.866 23.4004 112.676 23.4004C114.291 23.4004 115.632 23.6747 116.703 24.222L115.992 26.4814C114.992 25.951 113.862 25.6858 112.596 25.6858C111.596 25.6858 110.815 25.9263 110.255 26.4047C109.782 26.8324 109.544 27.3537 109.544 27.9712C109.544 28.655 109.815 29.2205 110.359 29.6651C110.832 30.0759 111.692 30.5205 112.94 31.0002C114.467 31.5995 115.588 32.3002 116.31 33.1036C117.028 33.9044 117.387 34.9067 117.387 36.1027Z"
        fill="white"
      />
      <path
        d="M126.784 30.5594H123.651V36.6161C123.651 38.1566 124.203 38.9262 125.31 38.9262C125.818 38.9262 126.239 38.8833 126.572 38.7975L126.651 40.9022C126.091 41.1063 125.354 41.209 124.44 41.209C123.318 41.209 122.44 40.8749 121.807 40.208C121.176 39.5398 120.859 38.4192 120.859 36.8449V30.5568H118.992V28.4768H120.859V26.1927L123.651 25.3711V28.4768H126.784V30.5594Z"
        fill="white"
      />
      <path
        d="M140.923 34.6141C140.923 36.5316 140.36 38.1059 139.237 39.337C138.06 40.6045 136.497 41.2363 134.549 41.2363C132.672 41.2363 131.177 40.6292 130.063 39.415C128.948 38.2008 128.391 36.6681 128.391 34.8208C128.391 32.8877 128.964 31.3043 130.115 30.0732C131.263 28.8408 132.812 28.2246 134.76 28.2246C136.637 28.2246 138.148 28.8317 139.288 30.0472C140.379 31.2263 140.923 32.7486 140.923 34.6141ZM137.973 34.7038C137.973 33.5533 137.721 32.5666 137.211 31.7437C136.615 30.7479 135.763 30.2513 134.659 30.2513C133.516 30.2513 132.648 30.7492 132.052 31.7437C131.541 32.5679 131.289 33.5702 131.289 34.7558C131.289 35.9063 131.541 36.893 132.052 37.7146C132.667 38.7104 133.525 39.207 134.633 39.207C135.719 39.207 136.571 38.7 137.185 37.6886C137.709 36.8501 137.973 35.853 137.973 34.7038Z"
        fill="white"
      />
      <path
        d="M150.162 30.9163C149.88 30.8656 149.58 30.8396 149.266 30.8396C148.266 30.8396 147.492 31.2075 146.948 31.9446C146.475 32.5946 146.238 33.4162 146.238 34.4081V40.9536H143.396L143.423 32.4074C143.423 30.9696 143.387 29.6605 143.316 28.4801H145.792L145.896 30.8669H145.975C146.275 30.0466 146.748 29.3862 147.396 28.8909C148.03 28.445 148.714 28.2227 149.451 28.2227C149.714 28.2227 149.951 28.2409 150.162 28.2734V30.9163Z"
        fill="white"
      />
      <path
        d="M162.875 34.1259C162.875 34.6225 162.841 35.0411 162.771 35.383H154.243C154.276 36.6154 154.688 37.5579 155.48 38.2079C156.199 38.789 157.128 39.0802 158.269 39.0802C159.532 39.0802 160.684 38.8839 161.72 38.49L162.165 40.414C160.955 40.9288 159.525 41.1849 157.876 41.1849C155.892 41.1849 154.335 40.6155 153.201 39.478C152.071 38.3405 151.504 36.813 151.504 34.8968C151.504 33.0157 152.031 31.4492 153.085 30.1999C154.189 28.8661 155.681 28.1992 157.559 28.1992C159.403 28.1992 160.799 28.8661 161.747 30.1999C162.497 31.2594 162.875 32.5698 162.875 34.1259ZM160.164 33.407C160.183 32.5854 159.997 31.8756 159.612 31.2763C159.12 30.5054 158.364 30.1206 157.347 30.1206C156.417 30.1206 155.661 30.4963 155.084 31.2503C154.611 31.8496 154.329 32.5685 154.243 33.4057H160.164V33.407Z"
        fill="white"
      />
      <path
        d="M65.3997 13.0123C65.3997 14.5424 64.9291 15.6942 63.989 16.4677C63.1184 17.1814 61.881 17.5389 60.2784 17.5389C59.4837 17.5389 58.8037 17.5051 58.2344 17.4375V9.07719C58.977 8.96019 59.777 8.90039 60.641 8.90039C62.1677 8.90039 63.3184 9.22409 64.0944 9.87149C64.9637 10.6034 65.3997 11.6499 65.3997 13.0123ZM63.9264 13.05C63.9264 12.0581 63.657 11.2976 63.1184 10.7672C62.5797 10.2381 61.793 9.97289 60.757 9.97289C60.317 9.97289 59.9424 10.0015 59.6317 10.0613V16.417C59.8037 16.443 60.1184 16.4547 60.5757 16.4547C61.645 16.4547 62.4704 16.1648 63.0517 15.585C63.6331 15.0052 63.9264 14.1602 63.9264 13.05Z"
        fill="white"
      />
      <path
        d="M73.2107 14.3491C73.2107 15.2916 72.9347 16.0638 72.3827 16.6696C71.804 17.2923 71.0374 17.603 70.08 17.603C69.1574 17.603 68.4227 17.3053 67.8747 16.7073C67.328 16.1106 67.0547 15.3579 67.0547 14.4505C67.0547 13.5015 67.336 12.7228 67.9014 12.1183C68.4667 11.5138 69.2267 11.2109 70.184 11.2109C71.1067 11.2109 71.848 11.5086 72.4094 12.1053C72.9427 12.6851 73.2107 13.4339 73.2107 14.3491ZM71.7614 14.3933C71.7614 13.8278 71.636 13.3429 71.3867 12.9386C71.0934 12.4498 70.676 12.2054 70.1334 12.2054C69.572 12.2054 69.1454 12.4498 68.852 12.9386C68.6014 13.3429 68.4774 13.8356 68.4774 14.418C68.4774 14.9835 68.6027 15.4684 68.852 15.8727C69.1547 16.3615 69.576 16.6059 70.12 16.6059C70.6534 16.6059 71.072 16.3576 71.3734 15.8597C71.632 15.4476 71.7614 14.9588 71.7614 14.3933Z"
        fill="white"
      />
      <path
        d="M83.6848 11.3359L81.7181 17.4641H80.4381L79.6235 14.803C79.4168 14.1387 79.2488 13.4783 79.1181 12.8231H79.0928C78.9715 13.4965 78.8035 14.1556 78.5875 14.803L77.7221 17.4641H76.4275L74.5781 11.3359H76.0141L76.7248 14.2492C76.8968 14.9382 77.0381 15.5947 77.1515 16.2161H77.1768C77.2808 15.7039 77.4528 15.0513 77.6955 14.2622L78.5875 11.3372H79.7261L80.5808 14.1998C80.7875 14.8979 80.9555 15.57 81.0848 16.2174H81.1235C81.2181 15.5869 81.3608 14.9148 81.5501 14.1998L82.3128 11.3372H83.6848V11.3359Z"
        fill="white"
      />
      <path
        d="M90.9312 17.4639H89.5339V13.9539C89.5339 12.8723 89.1125 12.3315 88.2672 12.3315C87.8525 12.3315 87.5179 12.4797 87.2579 12.7774C87.0005 13.0751 86.8699 13.4261 86.8699 13.8278V17.4626H85.4725V13.0868C85.4725 12.5486 85.4552 11.9649 85.4219 11.3331H86.6499L86.7152 12.2912H86.7539C86.9165 11.9935 87.1592 11.7478 87.4779 11.5515C87.8565 11.3227 88.2805 11.207 88.7445 11.207C89.3312 11.207 89.8192 11.3916 90.2072 11.7621C90.6899 12.2158 90.9312 12.8931 90.9312 13.7927V17.4639Z"
        fill="white"
      />
      <path
        d="M94.7827 17.4635H93.3867V8.52344H94.7827V17.4635Z"
        fill="white"
      />
      <path
        d="M103.011 14.3491C103.011 15.2916 102.735 16.0638 102.183 16.6696C101.605 17.2923 100.837 17.603 99.8808 17.603C98.9568 17.603 98.2221 17.3053 97.6755 16.7073C97.1288 16.1106 96.8555 15.3579 96.8555 14.4505C96.8555 13.5015 97.1368 12.7228 97.7021 12.1183C98.2675 11.5138 99.0275 11.2109 99.9835 11.2109C100.907 11.2109 101.647 11.5086 102.21 12.1053C102.743 12.6851 103.011 13.4339 103.011 14.3491ZM101.561 14.3933C101.561 13.8278 101.435 13.3429 101.186 12.9386C100.894 12.4498 100.475 12.2054 99.9341 12.2054C99.3715 12.2054 98.9448 12.4498 98.6528 12.9386C98.4021 13.3429 98.2781 13.8356 98.2781 14.418C98.2781 14.9835 98.4035 15.4684 98.6528 15.8727C98.9555 16.3615 99.3768 16.6059 99.9208 16.6059C100.454 16.6059 100.871 16.3576 101.173 15.8597C101.433 15.4476 101.561 14.9588 101.561 14.3933Z"
        fill="white"
      />
      <path
        d="M109.773 17.4639H108.519L108.415 16.758H108.376C107.947 17.3209 107.335 17.603 106.54 17.603C105.947 17.603 105.467 17.4171 105.105 17.0479C104.777 16.7125 104.613 16.2952 104.613 15.7999C104.613 15.0511 104.933 14.4804 105.577 14.0852C106.22 13.69 107.124 13.4963 108.288 13.5054V13.391C108.288 12.5837 107.853 12.1807 106.983 12.1807C106.363 12.1807 105.816 12.3328 105.344 12.6344L105.06 11.74C105.644 11.3877 106.365 11.2109 107.216 11.2109C108.859 11.2109 109.683 12.0559 109.683 13.7459V16.0027C109.683 16.615 109.713 17.1025 109.773 17.4639ZM108.323 15.3579V14.4128C106.781 14.3868 106.011 14.7989 106.011 15.6478C106.011 15.9676 106.099 16.2068 106.279 16.3667C106.459 16.5266 106.688 16.6059 106.961 16.6059C107.268 16.6059 107.555 16.511 107.816 16.3225C108.079 16.1327 108.24 15.8922 108.3 15.5971C108.315 15.5308 108.323 15.4502 108.323 15.3579Z"
        fill="white"
      />
      <path
        d="M117.715 17.4635H116.475L116.409 16.4794H116.371C115.975 17.2282 115.3 17.6026 114.352 17.6026C113.595 17.6026 112.964 17.3127 112.464 16.7329C111.964 16.1531 111.715 15.4004 111.715 14.4761C111.715 13.4842 111.986 12.6808 112.53 12.0672C113.056 11.4952 113.702 11.2092 114.469 11.2092C115.313 11.2092 115.904 11.4861 116.24 12.0412H116.267V8.52344H117.665V15.8125C117.665 16.4092 117.682 16.9591 117.715 17.4635ZM116.267 14.8791V13.8573C116.267 13.6805 116.253 13.5375 116.228 13.4283C116.149 13.1007 115.98 12.8251 115.723 12.6028C115.463 12.3805 115.149 12.2687 114.788 12.2687C114.267 12.2687 113.859 12.4702 113.559 12.8745C113.262 13.2788 113.111 13.7949 113.111 14.4254C113.111 15.0312 113.254 15.5226 113.54 15.9009C113.843 16.3039 114.251 16.5054 114.762 16.5054C115.22 16.5054 115.587 16.3377 115.866 16.001C116.135 15.6903 116.267 15.3159 116.267 14.8791Z"
        fill="white"
      />
      <path
        d="M129.664 14.3491C129.664 15.2916 129.388 16.0638 128.836 16.6696C128.257 17.2923 127.492 17.603 126.533 17.603C125.612 17.603 124.877 17.3053 124.328 16.7073C123.781 16.1106 123.508 15.3579 123.508 14.4505C123.508 13.5015 123.789 12.7228 124.354 12.1183C124.92 11.5138 125.68 11.2109 126.638 11.2109C127.56 11.2109 128.302 11.5086 128.862 12.1053C129.396 12.6851 129.664 13.4339 129.664 14.3491ZM128.216 14.3933C128.216 13.8278 128.09 13.3429 127.841 12.9386C127.546 12.4498 127.13 12.2054 126.586 12.2054C126.026 12.2054 125.6 12.4498 125.305 12.9386C125.054 13.3429 124.93 13.8356 124.93 14.418C124.93 14.9835 125.056 15.4684 125.305 15.8727C125.608 16.3615 126.029 16.6059 126.573 16.6059C127.106 16.6059 127.526 16.3576 127.828 15.8597C128.085 15.4476 128.216 14.9588 128.216 14.3933Z"
        fill="white"
      />
      <path
        d="M137.176 17.4639H135.78V13.9539C135.78 12.8723 135.359 12.3315 134.512 12.3315C134.097 12.3315 133.763 12.4797 133.504 12.7774C133.245 13.0751 133.116 13.4261 133.116 13.8278V17.4626H131.717V13.0868C131.717 12.5486 131.701 11.9649 131.668 11.3331H132.895L132.96 12.2912H132.999C133.163 11.9935 133.405 11.7478 133.723 11.5515C134.103 11.3227 134.525 11.207 134.991 11.207C135.576 11.207 136.064 11.3916 136.452 11.7621C136.936 12.2158 137.176 12.8931 137.176 13.7927V17.4639Z"
        fill="white"
      />
      <path
        d="M146.582 12.3553H145.043V15.3323C145.043 16.0889 145.317 16.4672 145.858 16.4672C146.109 16.4672 146.317 16.4464 146.481 16.4035L146.517 17.437C146.241 17.5384 145.878 17.5891 145.431 17.5891C144.879 17.5891 144.45 17.4253 144.139 17.0977C143.827 16.7701 143.673 16.2189 143.673 15.4454V12.3553H142.754V11.3348H143.673V10.2116L145.042 9.80859V11.3335H146.581V12.3553H146.582Z"
        fill="white"
      />
      <path
        d="M153.978 17.4635H152.58V13.9795C152.58 12.881 152.158 12.3311 151.314 12.3311C150.666 12.3311 150.224 12.6496 149.981 13.2866C149.94 13.4205 149.916 13.5843 149.916 13.7767V17.4622H148.52V8.52344H149.916V12.2167H149.942C150.382 11.5446 151.013 11.2092 151.83 11.2092C152.409 11.2092 152.888 11.3938 153.268 11.7643C153.741 12.2258 153.978 12.9122 153.978 13.8196V17.4635Z"
        fill="white"
      />
      <path
        d="M161.608 14.1099C161.608 14.3543 161.589 14.5597 161.556 14.7274H157.365C157.384 15.3332 157.584 15.7947 157.972 16.1145C158.326 16.4005 158.784 16.5435 159.344 16.5435C159.964 16.5435 160.529 16.4473 161.038 16.2536L161.257 17.2C160.661 17.4522 159.96 17.5783 159.148 17.5783C158.174 17.5783 157.408 17.2988 156.853 16.7398C156.296 16.1808 156.02 15.4307 156.02 14.4895C156.02 13.5652 156.277 12.7956 156.796 12.182C157.337 11.5268 158.069 11.1992 158.993 11.1992C159.897 11.1992 160.584 11.5268 161.048 12.182C161.422 12.702 161.608 13.3455 161.608 14.1099ZM160.274 13.7576C160.285 13.3533 160.193 13.0049 160.004 12.7111C159.761 12.3328 159.392 12.143 158.892 12.143C158.436 12.143 158.064 12.3276 157.78 12.6981C157.548 12.9932 157.41 13.3455 157.365 13.7576H160.274Z"
        fill="white"
      />
    </svg>
  );
};
