import { createAsyncThunk } from "@reduxjs/toolkit";
import { conctractSliceActions } from "../slice/contractSlice";

export const clientDocumentsConfirm = createAsyncThunk(
  'contractSlice/clientDocumentsConfirm',
  async({body, clientUuid, documentUuid}, thunkApi) => {
    const confirmUrl = process.env.REACT_APP_CLIENT_DOCUMENTS
    const fullUrl = confirmUrl + `physical-persons/${clientUuid}/documents/${documentUuid}/confirm/`
    try {
      const response = await fetch(fullUrl, {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
      })

      if(!response.ok){
        throw new Error('Не удалось отправить запрос')
      }

      const data = await response.json()

      thunkApi.dispatch(conctractSliceActions.changeDocumentStatus(data))

    } catch (error) {
      return thunkApi.rejectWithValue(error.message)
    }
  }
)