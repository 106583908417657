import { gql } from "@apollo/client";

export const UPDATE_PERSONAL_DOCUMENTS = gql`
  mutation UpdatePersonalDocuments($file: Upload!, $personDocumentUuid: UUID!) {
    confirmedUploadPhysicalPersonDocument(
      file: $file
      personDocumentUuid: $personDocumentUuid
    ) {
      physicalPersonDocument {
        actyonType
      }
    }
  }
`;
