import { gql } from "@apollo/client";

export const CREATE_PHYSYCAL_PERSON = gql`
  mutation CreatePhysycalperson(
    $inputAddress: AddressInput
    $inputDocument: IdentityDocumentInput
    $inputPhysicalPerson: CreatePhysicalPersonInput!
    $inputUser: ClientInput
  ) {
    createPhysicalPerson(
      inputAddress: $inputAddress
      inputDocument: $inputDocument
      inputPhysicalPerson: $inputPhysicalPerson
      inputUser: $inputUser
    ) {
      physicalPerson {
        id
      }
    }
  }
`;
