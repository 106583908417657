import { useNavigate } from "react-router-dom";
import styles from "./Contract.module.scss";
import { Sidebar } from "../../../components/Sidebar";
import { Button } from "../../../components/Button";
import { Preloader } from "../../../components/Preloader";
import { DocumentViewer } from "../../../components/DocumentViewer/ui/DocumentViewer";
import { useDispatch, useSelector } from "react-redux";
import { documentsData, documentsStatus } from "../model/selectors/clientDocuments";
import { useEffect, useState } from "react";
import { ContractComment } from "../../../components/ContractComment/ui/ContractComment";
import { useQuery } from "@apollo/client";
import { CLIENT_INFO } from "../../../services/queries/queries/client/clientInfo";
import { clientDocumentsConfirm } from "../model/services/clientDocumentsConfirm";
import { getClientDocuments } from "../../Contract/model/services/getClientDocuments";
import { createPdfFile } from "../../../helpers/createPdfFile";

export const Contract = () => {
  const navigate = useNavigate();
  const id = localStorage.getItem("id");
  const currentUrl = localStorage.getItem('pdf')
  const documentsDataArr = useSelector(documentsData)
  const [documentFile, setDocumentFile] = useState(null)
  const [showCommentField, setShowCommentField] = useState(false)
  const [responseFile, setResponseFile] = useState(null)
  const [loadingFile, setLoadingFile] = useState(false)
  const dispatch = useDispatch()

  // const createPdfBlob = async (pdfUrl) => {
  //   const response = await fetch(pdfUrl);
  //   const blob = await response.blob();
  //   return blob;
  // };
  
  // const createPdfFile = async (pdfUrl, fileName = 'document.pdf') => {
  //   const blob = await createPdfBlob(pdfUrl);
  //   const file = new File([blob], fileName, { type: 'application/pdf' });
  //   setResponseFile(file)
  // };

  const { data } = useQuery(CLIENT_INFO, {
    variables: { id },
    skip: !id,
  });

  useEffect(() => {
    if (data && data?.user?.physicalPerson) {
      dispatch(getClientDocuments(data?.user?.physicalPerson?.uuid));
    }
  }, [data, dispatch]);

  const confirmDocument = () => {
    if(data && data?.user?.physicalPerson){
      dispatch(clientDocumentsConfirm({
        body: {
          is_confirmed: true,
        },
        clientUuid: data?.user?.physicalPerson?.uuid,
        documentUuid: documentsDataArr[0]?.uuid
      })).then(() => navigate(`/client-cabinet/`)).catch(error => console.log(error))
    }
  }

  useEffect(() => {
    if (documentsDataArr.length > 0) {
      localStorage.setItem('pdf', documentsDataArr[0]?.document_file_url)
    }
    
  }, [documentsDataArr]);

  // useEffect(() => {
  //   if(documentFile){
  //     setResponseFile(createPdfFile(documentFile))
  //   }
  // }, [documentFile])

  return (
    <div className={styles.contractWrapper}>
      <Sidebar className={styles.contractSidebar}>
        <Button onClick={() => navigate(`/client-cabinet/`)} className={styles.contractSidebarBtn}>
          <span>{"<"}</span>
          Кабинет
        </Button>
        <h3 className={styles.sidebarTitle}>Требуемые данные</h3>
        <div className={styles.sidebarBox}>
          <h4>Удостоверение личности и СНИЛС</h4>
          <span>Готово</span>
        </div>
      </Sidebar>
      <div className={styles.contractContent}>
        <h3 className={styles.contractContentTitle}>Договор для ознакомления</h3>
        {!loadingFile && documentsDataArr.length > 0 ? <DocumentViewer fileUrl={documentsDataArr[0]?.document_file_url}/> : <Preloader />}
        {!showCommentField
          ? <div className={styles.contractButtons}>
          <div>
            <Button variant='transparent-green' onClick={confirmDocument}>Ознакомлен</Button>
            <h4>Нажимая на кнопку "Ознакомлен" вы подтверждаете, что в договоре все ваши данные верны.</h4>
          </div>
          <div>
            <Button variant='transparent-green' onClick={() => setShowCommentField(true)}>Отправить на доработку</Button>
            <h4>Если нашли ошибку в договоре отправьте на доработку и оставьте комментарий.</h4>
          </div>
        </div>
        : <ContractComment file={responseFile} clientUuid={data?.user?.physicalPerson?.uuid} documentUuid={documentsDataArr[0]?.uuid} setShowComment={setShowCommentField}/>
        }
      </div>
    </div>
  );
};
