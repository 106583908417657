import { ArrowLeft } from "../../../assets/icons/ArrowLeft";
import { ApplicationForm } from "../../../components/Forms";
import { Link, useParams } from "react-router-dom";
import styles from './EditApplication.module.scss'
import { useLazyQuery } from "@apollo/client";
import { APPLICATION_INFO } from "../../../services/queries/queries/applications/applicationInfo";
import { useEffect } from "react";

export const EditApplication = () => {
  const {id} = useParams()
  const [applicationInfo, {data}] = useLazyQuery(APPLICATION_INFO, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if(id){
      applicationInfo({
        variables: {
          id
        }
      })
    }
  }, [id, applicationInfo])


  return(
    <div className={styles.container}>
			<div className={styles.applicationFormContainer}>
				<Link to="/applications/" className={styles.backBtn}>
					<ArrowLeft /> <span>Кабинет</span>
				</Link>
				<ApplicationForm applicationData={data} uuid={data?.application?.uuid} applicationInfo={applicationInfo} id={id}/>
			</div>
		</div>
  )
}