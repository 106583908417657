import { gql } from "@apollo/client";

export const DELETE_REPRESENTATIVES = gql`
  mutation DeleteRepresentatives($representatives: [UUID]!, $uuid: UUID!){
  removeRepresentativesFromLegalPerson(representatives: $representatives, uuid: $uuid){
    legalPerson{
      id
    }
  }
}

`