import styles from "./Pagination.module.scss";
import cn from "classnames";

export const Pagination = ({ page, setPage, totalPages }) => {
  const handlePageClick = (item) => {
    setPage(item);
    console.log("s");
  };

  const generatePageNumbers = () => {
    const pages = [];
    const totalPageCount = totalPages.length;

    if (totalPageCount <= 6) {
      // Если страниц мало, просто показываем все
      for (let i = 0; i < totalPageCount; i++) {
        pages.push(i);
      }
    } else {
      // Всегда показываем первые 3 страницы
      pages.push(0, 1, 2);

      // Если текущая страница больше 3, добавляем троеточие
      if (page > 3) {
        pages.push("...");
      }

      // Добавляем текущую страницу и соседние
      const startPage = Math.max(page - 1, 3);
      const endPage = Math.min(page + 1, totalPageCount - 2);
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      // Если текущая страница далеко от конца, добавляем троеточие
      if (page < totalPageCount - 4) {
        pages.push("...");
      }

      // Всегда показываем последние 2 страницы, если они еще не добавлены
      if (!pages.includes(totalPageCount - 2)) {
        pages.push(totalPageCount - 2);
      }
      if (!pages.includes(totalPageCount - 1)) {
        pages.push(totalPageCount - 1);
      }
    }

    return pages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <div className={styles.paginationBox}>
      {pageNumbers.map((item, index) => (
        <button
          key={index}
          onClick={() => item !== "..." && handlePageClick(item)}
          className={cn({
            [styles.active]: item === page,
            [styles.pagination]: item !== "...",
          })}
          disabled={item === "..."}
        >
          {item !== "..." ? item + 1 : "..."}
        </button>
      ))}
    </div>
  );
};
