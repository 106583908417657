import {useEffect, useState} from "react";
import {Input} from "../../Input";
import {useDebouncedCallback} from "use-debounce";

/* 

Компонент для фильтрации контента на странице Базы. 

При отправке запроса с query, содержащим значение, происходит фильтрация по этому значению, например ФИО. Если значение исчезло, уходит запрос с пустой переменной search и возвращаются все значения (т.е происходит сброс фильтра)

Пропсы:

response - эндпоинт для передачи запроса. Эндпоинт может быть разным, в зависимости от выбранной вкладки на странице Базы

className - передача дополнительных стилей из компонента где вызывается инпут (строка)

placeholder - передача плейсхолдера в инпут (строка)

Функция debounce позволяет не отправлять запрос после каждого введенного символа, а только через 500 мс, после того как символы перестали набираться


*/

export const FilteredSearch = ({ response, className, placeholder, setCurrentPage, setFirst }) => {
  const [query, setQuery] = useState("");

  const debounce = useDebouncedCallback((query) => {
    if (query.length > 0) {
      response({
        variables: {
          search: query,
        },
      });
    } else {
      response({
        variables: {
          search: '',
        },
      });
    }
  }, 500);

  useEffect(() => {
    if (query) {
      setCurrentPage(0)
      setFirst(10)
    }
    debounce(query);
  }, [debounce, query]);

  return (
    <Input
      variant="green"
      placeholder={placeholder}
      className={className}
      value={query}
      setValue={setQuery}
      type="search"
    />
  );
};
