import { gql } from "@apollo/client";

export const ADD_DOC_TO_APP = gql`
  mutation addDocumentsToApplication(
    $applicationUuid: UUID!
    $documentsUuids: [UUID]!
  ){
    addDocumentsToApplication(    
        applicationUuid: $applicationUuid
        documentsUuids: $documentsUuids
    ){
        success
  }
}
`
