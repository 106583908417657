export const Eye = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.99844 9.60039C8.42278 9.60039 8.82975 9.43182 9.12981 9.13176C9.42987 8.8317 9.59844 8.42474 9.59844 8.00039C9.59844 7.57604 9.42987 7.16908 9.12981 6.86902C8.82975 6.56896 8.42278 6.40039 7.99844 6.40039C7.57409 6.40039 7.16713 6.56896 6.86707 6.86902C6.56701 7.16908 6.39844 7.57604 6.39844 8.00039C6.39844 8.42474 6.56701 8.8317 6.86707 9.13176C7.16713 9.43182 7.57409 9.60039 7.99844 9.60039Z"
				fill="#78C142"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.367188 8.00039C1.38639 4.75479 4.41839 2.40039 8.00079 2.40039C11.5832 2.40039 14.6152 4.75479 15.6344 8.00039C14.6152 11.246 11.5832 13.6004 8.00079 13.6004C4.41839 13.6004 1.38639 11.246 0.367188 8.00039ZM11.2008 8.00039C11.2008 8.84908 10.8636 9.66302 10.2635 10.2631C9.66341 10.8632 8.84948 11.2004 8.00079 11.2004C7.15209 11.2004 6.33816 10.8632 5.73805 10.2631C5.13793 9.66302 4.80079 8.84908 4.80079 8.00039C4.80079 7.1517 5.13793 6.33777 5.73805 5.73765C6.33816 5.13753 7.15209 4.80039 8.00079 4.80039C8.84948 4.80039 9.66341 5.13753 10.2635 5.73765C10.8636 6.33777 11.2008 7.1517 11.2008 8.00039Z"
				fill="#78C142"
			/>
		</svg>
	);
};
