export const Edit = () => {
	return (
		<svg
			width="13"
			height="13"
			viewBox="0 0 13 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.9355 1.42741C10.8536 1.39389 10.7658 1.37702 10.6773 1.37779C10.5887 1.37856 10.5013 1.39695 10.4199 1.43189C10.3386 1.46683 10.265 1.51762 10.2035 1.58129L10.1954 1.58956L4.66667 7.11829V8.06082H5.60919L11.1379 2.53208L11.1462 2.52396C11.2099 2.46246 11.2607 2.3889 11.2956 2.30756C11.3305 2.22623 11.3489 2.13875 11.3497 2.05023C11.3505 1.96171 11.3336 1.87392 11.3001 1.79199C11.2666 1.71006 11.217 1.63562 11.1545 1.57303C11.0919 1.51043 11.0174 1.46093 10.9355 1.42741ZM10.6657 0.0445091C10.9312 0.0422015 11.1946 0.0928051 11.4404 0.193367C11.6862 0.293929 11.9095 0.442435 12.0973 0.63022C12.285 0.818005 12.4336 1.04131 12.5341 1.2871C12.6347 1.53289 12.6853 1.79625 12.683 2.06181C12.6807 2.32737 12.6255 2.58981 12.5207 2.83382C12.4167 3.07587 12.2659 3.29496 12.0771 3.47855L6.35674 9.19889C6.23171 9.32391 6.06214 9.39415 5.88533 9.39415H4C3.63181 9.39415 3.33333 9.09567 3.33333 8.72748V6.84215C3.33333 6.66534 3.40357 6.49577 3.5286 6.37075L9.24892 0.650425C9.43251 0.461551 9.65161 0.310785 9.89367 0.206807C10.1377 0.101989 10.4001 0.0468168 10.6657 0.0445091ZM0.585786 1.97994C0.960859 1.60486 1.46957 1.39415 2 1.39415H5.33333C5.70152 1.39415 6 1.69263 6 2.06082C6 2.42901 5.70152 2.72748 5.33333 2.72748H2C1.82319 2.72748 1.65362 2.79772 1.5286 2.92275C1.40357 3.04777 1.33333 3.21734 1.33333 3.39415V10.7275C1.33333 10.9043 1.40357 11.0739 1.5286 11.1989C1.65362 11.3239 1.82319 11.3942 2 11.3942H9.33333C9.51014 11.3942 9.67971 11.3239 9.80474 11.1989C9.92976 11.0739 10 10.9043 10 10.7275V7.39415C10 7.02596 10.2985 6.72748 10.6667 6.72748C11.0349 6.72748 11.3333 7.02596 11.3333 7.39415V10.7275C11.3333 11.2579 11.1226 11.7666 10.7475 12.1417C10.3725 12.5168 9.86377 12.7275 9.33333 12.7275H2C1.46957 12.7275 0.960859 12.5168 0.585786 12.1417C0.210714 11.7666 0 11.2579 0 10.7275V3.39415C0 2.86372 0.210714 2.35501 0.585786 1.97994Z"
				fill="#298D14"
			/>
		</svg>
	);
};
